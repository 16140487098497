import { Box, Grid, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VehicleType } from "../types/vehicle";
import { useTranslation } from "react-i18next";

type VehicleFormProps = {
    values: VehicleType;
    handleChange: any;
    handleBlur: any;
    touched: any,
    errors: any,
    isValid: any
}

const VehicleForm = (props: VehicleFormProps) => {
    const { values, handleChange, handleBlur, touched, errors, isValid } = props;
    // const { setFieldValue } = useFormikContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const handleDateChange = (event: any, field: string) => {
    //     setFieldValue(field, event.toDate());
    // }

    return <Box sx={{ mt: 3 }}>
        <Grid container spacing={2} sx={{ display: 'flex' }}>
            <h3 style={{ width: "100%" }}>{t("EditVehicle.Title")}</h3>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="numberPlate"
                    name="numberPlate"
                    label={t("EditVehicle.NumberPlate")}
                    value={values.numberPlate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.numberPlate && Boolean(errors.numberPlate)}
                    helperText={touched.numberPlate && errors.numberPlate}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="brand"
                    name="brand"
                    label={t("EditVehicle.Brand")}
                    value={values.brand}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.brand && Boolean(errors.brand)}
                    helperText={touched.brand && errors.brand}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="model"
                    name="model"
                    label={t("EditVehicle.Model")}
                    value={values.model}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.model && Boolean(errors.model)}
                    helperText={touched.model && errors.model}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="currentKilometers"
                    name="currentKilometers"
                    label={t("EditVehicle.CurrentKilometers")}
                    value={values.currentKilometers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.currentKilometers && Boolean(errors.currentKilometers)}
                    helperText={touched.currentKilometers && errors.currentKilometers}
                />
            </Grid>
            <Grid item xs={6}>
                <Button style={{ backgroundColor: "#EAFC00", color: "#053d57", fontWeight: "bold" }} variant="contained" fullWidth onClick={() => navigate('/dashboard/vehicles')} disabled={!isValid}>
                    {t("Buttons.Return")}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={!isValid}>
                    {t("Buttons.Save")}
                </Button>
            </Grid>
        </Grid>
    </Box>
}

export default VehicleForm;