import { IconButtonProps, IconButton } from '@mui/material';
import * as React from 'react';

type Appearance = 'blue';

type Props = IconButtonProps & { appearance?: Appearance, width?: 20 | 30 | 40 | 56 };

const cfg = {
  blue: {
    sx: {
      bgcolor: 'primary.main',
      color: 'white',
      '&:hover': {
          bgcolor: 'primary.main',
          boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
      },
      marginLeft: '20px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
    }
  },
}

const CustomIconButton = (props: Props): React.ReactElement<Props> => {
  const { appearance, children, width = 40, ...rest } = props;

  return (
    <IconButton
      sx={{
        width: width,
        height: width,
        ...(appearance && cfg[appearance]?.sx),
      }}
      aria-label="custom-icon-button"
      {...rest}
    >
      {children}
    </IconButton>
  );
}

export default CustomIconButton;