// src/features/booking/bookingSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { BookingType, ReturnInfoType } from '../types/booking';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

const BASE_URL = process.env.REACT_APP_BOOKINGS_API_URL || '';
const PROCESSOR_BASE_URL = process.env.REACT_APP_PROCESSOR_API_URL || '';

export const addCheckoutReturnImages = createAsyncThunk(
    'checkout/addCheckoutReturnImages',
    async ({ id, dashboardPicture, vehicleVideo }: { id: string, dashboardPicture: FormData, vehicleVideo: FormData }, { rejectWithValue }) => {
        try {
            const returnInfo = {
                cockpit: dashboardPicture.get("filename")?.toString(),
                inspection: vehicleVideo.get("filename")?.toString()
            }
            const response = await axios.patch(`${BASE_URL}/${id}/recordings`, returnInfo,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            const sasUri = response.data.sasUri;
            await uploadFiles(dashboardPicture, vehicleVideo, sasUri);
            await moveFiles(id, returnInfo);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


const moveFiles = async (id: string, returnInfo: ReturnInfoType) => {
    const request = {
        accountId: id,
        bookingId: id,
        fileNames: [
            returnInfo.cockpit ?? "",
            returnInfo.inspection ?? ""
        ]
    }
    await axios.post(`${PROCESSOR_BASE_URL}/process`, request,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );

}


export const setCheckoutBooking = createAsyncThunk(
    'checkout/setCheckoutBooking',
    async ({ booking }: { booking: BookingType }) => {
        return booking;
    }
);


export const setCheckoutBookingStatus = createAsyncThunk(
    'checkout/setCheckoutBookingStatus',
    async ({ status }: { status: string }) => {
        return status;
    }
);



export const addCheckoutSignature = createAsyncThunk(
    'checkout/addCheckoutSignature',
    async ({ id, returnInfo, token }: { id: string, returnInfo: ReturnInfoType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BASE_URL}/${id}/return/signature`, returnInfo,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const uploadFiles = async (dashboardPicture: FormData, vehicleVideo: FormData, sasUri: string) => {
    const blobServiceClient = new BlobServiceClient(sasUri);
    const containerClient = blobServiceClient.getContainerClient("uploads");

    await uploadFile(dashboardPicture, containerClient);
    await uploadFile(vehicleVideo, containerClient);
}

const uploadFile = async (fileFormData: FormData, containerClient: ContainerClient) => {

    const fileName = fileFormData.get("filename")?.toString() ?? "";
    const blobClient = containerClient.getBlobClient(fileName);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const file = fileFormData.get("file");
    if (file)
        blockBlobClient.uploadData(file as Blob);
}

const checkoutState = (state: RootState) => state.checkout;

export const selectCheckoutBooking = createSelector(
    checkoutState,
    (bookingState) => bookingState.checkoutBooking
);

export const selectCheckoutStatus = createSelector(
    checkoutState,
    (bookingState) => bookingState.status
);

export const selectCheckoutStep = createSelector(
    checkoutState,
    (bookingState) => bookingState.step
);

export const bookingInitialState: BookingType = {
    id: "",
    accountId: "",
    status: "",
    customer: {
        email: "",
        phone: "",
        name: "",
        surname: "",
    },
    bookingInfo: {
        bookingNumber: "",
        dateFrom: new Date(),
        dateTo: new Date(),
        hasFullInsuranceCoverage: false,
        vehicleId:"",
    },
    lookerInfo: "",
};


const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        checkoutBooking: null as null | BookingType,
        status: 'idle',
        step: 'initial'
    },
    reducers: {
        // Your synchronous reducers here (if needed)
    },
    extraReducers: (builder) => {
        builder
            .addCase(setCheckoutBooking.fulfilled, (state, action) => {
                state.checkoutBooking = action.payload;
            })
            .addCase(addCheckoutReturnImages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addCheckoutReturnImages.fulfilled, (state, action) => {
                state.checkoutBooking = action.payload.booking;
                state.status = 'succeeded';
                state.step = 'signature';
            })

            .addCase(setCheckoutBookingStatus.fulfilled, (state, action) => {
                state.step = action.payload;
            })
            .addCase(addCheckoutSignature.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addCheckoutSignature.fulfilled, (state, action) => {
                state.checkoutBooking = action.payload;
                state.status = 'succeeded';
                state.step = 'locker';
            })
            .addCase(addCheckoutSignature.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export default checkoutSlice.reducer;