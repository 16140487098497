import { Box, Container, Grid, Typography } from "@mui/material"
import MainButton from "components/Button"
import { useTranslation } from "react-i18next";

const LandingMainSection = () => {
    const { t } = useTranslation();
    return <Box mt={"100px"}>
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography
                        sx={{
                            color: "#053D57",
                            fontSize: { md: "56px", xs: "48px" },
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "66px",
                        }}
                    >
                        {t("Landing.Main.Title")}
                    </Typography>
                    <Typography
                        sx={{
                            color: "#666",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "30px",
                        }}
                    >
                        {t("Landing.Main.SubTitle")}
                    </Typography>
                    <Grid item xs={12} paddingTop={"30px"} >
                        <MainButton variantType="secondary" width={"200px"} textTransform="uppercase">{t("Contact")}</MainButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        paddingTop: {
                            xs: "20px",
                            md: "0px"
                        }
                    }}>
                        <img style={{ width: "100%" }} src="./assets/img/landing/main.png" alt="main"></img>
                    </Box>

                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default LandingMainSection