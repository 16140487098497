import { useQuery } from '@tanstack/react-query';

import { getUsers } from 'api/users';
import { useAuth } from 'context/AuthContext';

export const useUsers = () => {
  const { token, user: { accountId } } = useAuth();

  return useQuery({
    queryKey: ['isers'],
    queryFn: async () =>
      await getUsers({ token, accountId }),
    enabled: !!accountId || !!token,
  });
};