import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { type ToolFormProps } from 'components/ToolForm';
import useModal from 'lib/hooks/useModal';
import ToolCalibrationTable from './Table';
import ToolCalibrationAddModal from './AddModal';
import { ToolType } from 'types/tool';

const ToolCalibration = (props: ToolFormProps): React.ReactElement<ToolFormProps> => {
  const { users, values, handleBlur, handleChange, touched, errors, setFieldValue } = props;
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useModal(false);

  const handleCloseAndUpdate = (tool: ToolType) => {
    console.log(tool.history);
    setFieldValue('history', tool.history)
    handleClose();
  }

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>{t("EditTools.ToolCalibration.Title")}</Typography>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="nextCalibration"
          name="nextCalibration"
          label={t("EditTools.ToolCalibration.CalibrationDate")}
          value={values.nextCalibration}
          onChange={handleChange}
          onBlur={handleBlur}
          type="date"
          error={touched.calibrationDate && Boolean(errors.calibrationDate)}
          helperText={touched.calibrationDate && errors.calibrationDate}
        />
      </Grid>
      <ToolCalibrationTable
        tool={values}
        users={users}
      />
      <Grid item xs={4} marginY={2}>
        <Button
          style={{ fontWeight: "bold" }}
          variant="contained"
          onClick={() => handleOpen()}>
            {t("EditTools.ToolCalibration.Buttons.AddCalibration")}
        </Button>
      </Grid>
      <ToolCalibrationAddModal
        handleOpen={handleOpen}
        open={open}
        handleClose={handleCloseAndUpdate}
        tool={values}
      />
    </>
  );
};

export default ToolCalibration;