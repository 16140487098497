import { Typography } from "@mui/material";
import { ReactNode } from "react";

type TitleProp = {
    children: ReactNode
}

const Title = (props: TitleProp) => {
    const { children } = props;
    return <Typography fontSize={24} fontStyle={"normal"} fontWeight={700} lineHeight={"160%"} textTransform={"uppercase"}>{children}</Typography>
}

export default Title;