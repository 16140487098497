
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { ToolType } from '../types/tool';
import { UserType } from '../types/user';
import { AssignToolUseRequestType } from '../types/assignToolUseRequest';
const BASE_URL = process.env.REACT_APP_TOOLS_API_URL || '';

// Async thunks for async operations
export const fetchTools = createAsyncThunk(
    'tools/fetchTools',
    async ({ token, user }: { token: string, user: UserType }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BASE_URL}/account/${user.accountId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchInUseTools = createAsyncThunk(
  'tools/fetchInUseTools',
  async ({ token, user }: { token: string, user: UserType }, { rejectWithValue }) => {
      try {
          const response = await axios.get(`${BASE_URL}/account/${user.accountId}/user/${user.id}`,
              {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  }
              }
          );
          return response.data;
      } catch (error: any) {
          return rejectWithValue(error.response.data);
      }
  }
);

export const requestTool = createAsyncThunk(
    'tools/requestTool',
    async ({ id, userId, token }: { id: string, userId: string | null, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BASE_URL}/${id}/request/user/${userId}`, null,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const asignUserTool = createAsyncThunk(
    'tools/asignUserTool',
    async ({ id, userId, token }: { id: string, userId?: string, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BASE_URL}/${id}/user/${userId}`,null,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteTool = createAsyncThunk(
    'tools/deleteTool',
    async ({ id, token, user }: { id: string, token: string, user: UserType }, { rejectWithValue }) => {
        try {
            await axios.delete(`${BASE_URL}/${id}/account/${user.accountId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const assignLockerTool = createAsyncThunk(
    'bookings/assignLockerTool',
    async ({ toolId, assignLockerRequest, token }: { toolId: string, assignLockerRequest: AssignToolUseRequestType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BASE_URL}/${toolId}/locker`,
                assignLockerRequest,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const assignToolUse = createAsyncThunk(
    'lockers/assignToolUse',
    async ({ id, assignUseRequest, token }: { id: string, assignUseRequest: AssignToolUseRequestType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BASE_URL}/${id}/use`,
                assignUseRequest,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const toolState = (state: RootState) => state.tools;

export const selectToolsWithNoAssignedLocker = createSelector(
    toolState,
    (toolState) => toolState.tools.filter(b => !b.lockerId)
);

export const selectTools = createSelector(
    toolState,
    (toolState) => toolState.tools
);

export const selectToolsStatus = createSelector(
    toolState,
    (toolState) => toolState.status
);

export const selectToolsError = createSelector(
    toolState,
    (toolState) => toolState.error
);
export const selectToolById = createSelector(
    [toolState, (state, id) => id],
    (toolState, id) => {
        return toolState.tools.find((tool: ToolType) => tool.id === id) ?? toolInitialState
    }
);

export const toolInitialState: ToolType = {
    id: undefined,
    accountId: "",
    name: "",
    img: "",
    description: "",
    lockerId: "",
    history: [],
    excludedUsers: []
};


const toolsSlice = createSlice({
    name: 'Tools',
    initialState: {
        tools: [] as Array<ToolType>,
        status: 'idle',
        error: "",
    },
    reducers: {
        // Your synchronous reducers here (if needed)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTools.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTools.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tools = action.payload;
            })
            .addCase(fetchTools.rejected, (state) => {
                state.error = "error";
                state.status = 'failed';
            })
            .addCase(fetchInUseTools.pending, (state) => {
              state.status = 'loading';
            })
            .addCase(fetchInUseTools.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tools = action.payload;
            })
            .addCase(fetchInUseTools.rejected, (state) => {
                state.error = "error";
                state.status = 'failed';
            })
            .addCase(requestTool.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(requestTool.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestTool.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedItem = action.payload;
                const index = state.tools.findIndex(tools => tools.id === updatedItem.id);
                if (index !== -1) {
                    state.tools[index] = updatedItem;
                }
            })
            .addCase(asignUserTool.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(asignUserTool.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asignUserTool.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedItem = action.payload;
                const index = state.tools.findIndex(tools => tools.id === updatedItem.id);
                if (index !== -1) {
                    state.tools[index] = updatedItem;
                }
            })
            .addCase(deleteTool.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteTool.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.tools.findIndex(tools => tools.id === action.payload);
                state.tools.splice(index, 1);
            })
            .addCase(deleteTool.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export default toolsSlice.reducer;