import { Grid, Paper, Card, CardContent, Chip, Typography } from "@mui/material";
import { BookingType } from "../types/booking";
import moment from 'moment';
import { useEffect } from 'react';
import { fetchVehicles, selectVehicles } from "../slices/vehiclesSlice";
import { AppDispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleType } from "../types/vehicle";
import { useAuth } from "../context/AuthContext";

type BookingItemProps = {
    booking: BookingType;
}

const blobBase = 'https://sltransientdev.blob.core.windows.net/files/uploads/';
const sasKey = '?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-06-26T05:38:00Z&st=2024-06-25T21:38:00Z&spr=https&sig=rmLhsXc6UVLGXy%2BNIw0GcnEeLKB%2BHbYjIBx8dJ9p2b4%3D';

const BookingItem = (props: BookingItemProps) => {
    const { booking } = props;
    const dispatch: AppDispatch = useDispatch();
    const vehicles: VehicleType[] = useSelector(selectVehicles);
    const { token, user } = useAuth();

    useEffect(() => {
        dispatch(fetchVehicles({ token, user }));
    }, [dispatch, token, user])

    const getNumberPlate = (vehicleId: string | number): string => {
        const vehicleDetails = vehicles.find(item => item.id === vehicleId);
        return vehicleDetails ? vehicleDetails.numberPlate : '-';
    }


    return <Grid item>
        <Paper elevation={3} style={{ maxWidth: "350px" }}>
            <Card>
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 style={{
                            marginBlockStart: 0,
                            marginBlockEnd: 0,
                            marginTop: "3px"
                        }}>
                            #{booking.bookingInfo.bookingNumber}
                        </h3>
                        <Chip sx={{
                            bgcolor: booking.status === "Reservado" ? 'green' : 'orange',
                            color: 'white',
                            opacity: '0.6',
                            '& .MuiChip-deleteIcon': {
                                color: 'white',
                            }
                        }}
                            label={booking.status}
                        />
                    </div>
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Typography fontWeight={"bold"} >Fecha Inicio:</Typography>
                            <Typography>{moment(booking.bookingInfo.dateFrom).format('DD/MM/YY')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={"bold"}>Fecha Fin:</Typography>
                            <Typography>{moment(booking.bookingInfo.dateTo).format('DD/MM/YY')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontWeight={"bold"}>Cliente</Typography>
                            <Typography>{`${booking.customer.name} ${booking.customer.surname}`}</Typography>
                            <a href={`mailto:${booking.customer.email}`}><Typography sx={{ textDecoration: "none", color: "primary", cursor: "pointer" }} >{booking.customer.email}</Typography></a>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontWeight={"bold"}>Vehiculo:</Typography>
                            <Typography>{getNumberPlate(booking.bookingInfo.vehicleId)}</Typography>
                            {/* <Typography>{booking.bookingInfo.vehicle.numberPlate} - {booking.bookingInfo.vehicle.currentKilometers}km</Typography> */}
                        </Grid>
                        {
                            booking.status === "Pendiente de revision" ?
                                <><Grid item xs={12}>
                                    <Typography fontWeight={"bold"}>Revision Alquiler:</Typography>
                                    <Typography>Combustible y Kilometros: <a target="_blank" rel="noreferrer" href={`${blobBase}${booking.returnInfo?.cockpit}${sasKey}`}>descargar</a></Typography>
                                    <Typography>Video 360: <a target="_blank" rel="noreferrer" href={`${blobBase}${booking.returnInfo?.inspection}${sasKey}`}>descargar</a></Typography>
                                </Grid></>
                                : <></>
                        }
                    </Grid>

                </CardContent>
            </Card>
        </Paper>
    </Grid>;
}

export default BookingItem;