import { Box, Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToolsOverviewReport from "./ToolsOverview";
import Splash from "components/Splash";
import { fetchUsers, selectUsersStatus } from "slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "context/AuthContext";
import { AppDispatch } from "store";
import { fetchTools, selectToolsStatus } from "slices/toolsSlice";
import ToolsLife from "./ToolLife";
import { selectLockerStatus } from "slices/lockerSlice";
import { fetchLockers } from "slices/lockersSlice";

const ReportsPage = () => {
    const { t } = useTranslation();
    const { token, user } = useAuth();
    const [value, setValue] = React.useState(0);

    const dispatch: AppDispatch = useDispatch();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const usersStatus = useSelector(selectUsersStatus);
    const toolsStatus = useSelector(selectToolsStatus);
    const lockersStatus = useSelector(selectLockerStatus);

    useEffect(() => {
        dispatch(fetchUsers({ token, user }));
        dispatch(fetchTools({ token, user }));
        dispatch(fetchLockers({ token, user }));
    }, [dispatch, token, user])

    const a11yProps = (index: number) => {
        return {
            id: `report-tab-${index}`,
            'aria-controls': `report-tabpanel-${index}`,
        };
    }

    if (usersStatus === "loading" || toolsStatus === "loading" || lockersStatus === "loading") {
        return <Splash></Splash>;
    }

    return <Box marginTop={"100px"}>
        <h1>{t("Reports.Title")}</h1>
        <Divider />
        <Box component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t("Reports.ToolsOverview.TabName")} {...a11yProps(0)} />
                    <Tab label={t("Reports.ToolsLife.TabName")} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <Box>
                {value === 0 && <ToolsOverviewReport></ToolsOverviewReport>}
                {value === 1 && <ToolsLife></ToolsLife>}
            </Box>

        </Box>
    </Box>
}

export default ReportsPage;
