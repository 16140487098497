import { useState } from 'react';
import { selectUsersStatus } from '../slices/userSlice';
import { useAuth } from '../context/AuthContext';
import { AppDispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import Splash from '../components/Splash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createUser, selectUserById, updateUser } from '../slices/userSlice';
import { updatePassword } from "../slices/passwordSlice";
import { UserType } from '../types/user';
import { AccessType } from "../types/user";
import UserForm from '../components/UserForm';
import PasswordModal from '../components/Shared/Password';
import { PasswordType } from "../types/password";
import { Box } from '@mui/material';
// Validation Schema
const validationSchema = Yup.object({
    userName: Yup.string().required('Nombre de usuario requerida'),
    name: Yup.string().required('Nombre requerido'),
    surname: Yup.string().required('Apellido requerida'),
    // access: Yup.object({
    //     rentingRoleId: Yup.string(),
    //     toolsRoleId: Yup.string(),
    //     managementRoleId: Yup.string(),
    //     CheckingRoleId: Yup.string(),
    // }).test(
    //     'at-least-one-access',
    //     'Se requiere al menos un rol de acceso',
    //     (access) => {
    //         const isValid = !!(
    //             access?.rentingRoleId ||
    //             access?.toolsRoleId ||
    //             access?.managementRoleId ||
    //             access?.CheckingRoleId
    //         );
    //         return isValid;
    //     }
    // )
});


// Form Component
const UserPage = () => {
    const { code, userId } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { token, user } = useAuth();
    const status = useSelector(selectUsersStatus);
    const selectedUser: UserType | undefined = useSelector((state) => selectUserById(state, userId));
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [createdUser, setCreatedUser] = useState<UserType | null>(null);

    const initialValues = {
        id: selectedUser?.id ?? null,
        accountId: selectedUser?.accountId ?? "",
        userName: selectedUser?.userName ?? "",
        name: selectedUser?.name ?? "",
        surname: selectedUser?.surname ?? "",
        isDeleted: selectedUser?.isDeleted ?? false,
        access: {
            rentingRoleId: selectedUser?.access?.rentingRoleId ?? "",
            toolsRoleId: selectedUser?.access?.toolsRoleId ?? "",
            managementRoleId: selectedUser?.access?.managementRoleId ?? "",
            checkingRoleId: selectedUser?.access?.checkingRoleId ?? "",
        } as AccessType,
    } as UserType;

    const onSubmit = (userToSubmit: UserType) => {
        userToSubmit.accountId = user.accountId;
        userToSubmit.language = 'es-ES';
        if (userToSubmit.id) {
            dispatch(updateUser({ user: userToSubmit, token: token })).then(() => {
                navigate('/dashboard/users');
            });
        }
        else {
            dispatch(createUser({ user: userToSubmit, token: token })).then((resp) => {
                setCreatedUser(resp.payload);
                setIsPasswordModalOpen(true);
            });
        }
    };

    const handlePasswordSave = (passwordToUpdate: PasswordType) => {
        if (createdUser) {
            passwordToUpdate.accountId = createdUser.accountId;
            passwordToUpdate.userId = createdUser.id;
            dispatch(updatePassword({ password: passwordToUpdate, token: token })).then(() => {
                setIsPasswordModalOpen(false);
                navigate('/dashboard/users');
            });
        }

    };

    return <Box marginTop={"100px"}>
        {status === "loading" ? <Splash></Splash> : <></>}
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount={true}
                validateOnChange={true}
                validateOnBlur={true}
            >
                {({ handleChange, handleBlur, values, touched, errors, isValid }) => (
                    <Form noValidate>
                        <UserForm values={values} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} isValid={isValid} screenType={code}></UserForm>
                    </Form>
                )}

            </Formik>
        </LocalizationProvider>
        <PasswordModal
            open={isPasswordModalOpen}
            onClose={() => setIsPasswordModalOpen(false)}
            onSave={handlePasswordSave} screenType={code}
        />
    </Box>
};

export default UserPage;
