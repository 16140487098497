import { Container, Grid, Typography } from "@mui/material";
import LandingSectionContent from "./LandingSectionContent";
import { t } from "i18next";

interface LandingValueItemProps {
    image: string,
    title: string,
    content: string
}

const LandingValueItem = (props: LandingValueItemProps) => {
    return <Grid item xs={12} md={4} >
        <Container sx={{
            maxWidth: "364px",
            textAlign: "center",
            paddingTop: {
                xs: "30px",
                md: "70px"
            },
            paddingBottom: {
                xs: "30px",
                md: "70px"
            }
        }} >
            <img src={`/assets/img/landing/${props.image}`} width={"140px"} alt={props.image}></img>
            <Typography
                sx={{
                    color: "#053D57",
                    fontSize: "28px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "46px",
                    textAlign: "center"
                }}
            >
                {t(props.title)}
            </Typography>
            <LandingSectionContent sx={{textAlign: "center"}}>
                {t(props.content)}
            </LandingSectionContent>
        </Container>
    </Grid>

}

export default LandingValueItem;