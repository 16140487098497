import * as React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { StepFormProps, StepProps } from "./types";
import Buttom from "components/CustomButton";
import MediaUpload from "components/customer-pages/atoms/MediaUpload";

const Step2 = (props: StepProps & {
  setDashboardPicture: (file: FormData) => void;
  dashboardPicture: FormData | null;
  handleSubmit: StepFormProps['handleSubmit'];
}) => {
  const { t } = useTranslation();
  const { dashboardPicture, setDashboardPicture, handleSubmit } = props;

  return (
    <>
      <p>{t('CreateIncidentPage.Step2.Description')}</p>
      <Grid item xs={12} mt={"20px"}>
        <MediaUpload
          inputName="dashboard"
          onChange={setDashboardPicture}
          file={dashboardPicture}
          label={t('CreateIncidentPage.Step2.Input.Add')}
        />
      </Grid>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Buttom
            className="mt-3"
            appearance="blueVariant"
            fullWidth
            disabled={!dashboardPicture}
            sx={{ opacity: !dashboardPicture ? '0.6' : undefined }}
            onClick={() => handleSubmit()}>
              {t('CreateIncidentPage.Step2.Next')}
          </Buttom>
        </Grid>
      </Grid>
    </>
  );
}
export default Step2;