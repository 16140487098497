import { Container, Grid } from "@mui/material";
import Splash from "../components/Splash";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomerBooking, selectCustomerBookingCompany, selectCustomerBookingVehicle } from "../slices/customerBookingSlice";
import CheckIn from "../components/CheckIn";
import { fetchLocker, selectLocker, selectLockerStatus } from "../slices/lockerSlice";
import { useEffect } from "react";
import { AppDispatch } from "../store";
import CheckOut from "../components/Checkout";
import CustomerLogo from "../components/customer-pages/CutomerLogo";

import Footer from "../components/customer-pages/atoms/Footer";
import { setCheckoutBooking } from "../slices/checkoutSlice";

const UserBookingManagement = () => {
    const company = useSelector(selectCustomerBookingCompany);
    const customerBooking = useSelector(selectCustomerBooking);
    const vehicle = useSelector(selectCustomerBookingVehicle);
    const lockerStatus = useSelector(selectLockerStatus);
    const locker = useSelector(selectLocker);
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (customerBooking) {
            dispatch(fetchLocker({ accountId: customerBooking.accountId, useId: customerBooking.id ?? "" }))
        }

    }, [dispatch, customerBooking]);
  
    useEffect(() => {
        if (customerBooking?.status === "En curso") {
            dispatch(setCheckoutBooking({ booking: customerBooking }));
        }
    }, [lockerStatus, customerBooking, dispatch])

    const isMobile = window.innerWidth < 600;

    return <>{
        !company || !customerBooking || !locker || !vehicle ? <Splash></Splash> :
            <>
                <div style={{ width: "100%", backgroundColor: "#E6F2F4", }}>
                    <Container maxWidth={"sm"} sx={{ backgroundColor: "#E6F2F4", minHeight: `calc(100vh - ${isMobile ? "62" : "39"}px)` }}>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CustomerLogo img="/dummy-logo.png" height="73px" ></CustomerLogo>
                            {
                                customerBooking.status === "Reservado" ?
                                    <CheckIn company={company} booking={customerBooking} locker={locker} vehicle={vehicle}></CheckIn> :
                                    customerBooking.status === "En curso" ?
                                        <CheckOut company={company} booking={customerBooking} locker={locker}></CheckOut> : <></>
                            }
                        </Grid>
                    </Container>
                </div>
                <Footer company={company}></Footer>
            </>
    }</>;
}
export default UserBookingManagement;