import { useQuery } from '@tanstack/react-query';

import { getIncidents } from 'api/incidents';
import { useAuth } from 'context/AuthContext';
import { mapIncident } from 'api/mappers/incidents';

export const useIncidents = () => {
  const { token, user } = useAuth();

  return useQuery({
    queryKey: ['incidents'],
    queryFn: async () => {
      const data = await getIncidents({ token, accountId: user.accountId });

      return data.map(mapIncident);
    },
    enabled: !!user.accountId || !!token,
  });
};