import styled from "@emotion/styled";
import { TextField } from "@mui/material";


export const CutomerInput = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        borderColor: "#053D57",
        backgroundColor: "white"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#053D57",
        borderWidth: "2px",
        color: "#828282",
        
    },
    '& .MuiFormLabel-root': {
        color: '#828282',
    },
}));