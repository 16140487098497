import { useEffect, useState } from 'react';
import { createBooking, selectBookingById, selectBookingsStatus, updateBooking } from '../slices/bookingsSlice';
import { AppDispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import Splash from '../components/Splash';
import { useNavigate, useParams } from 'react-router-dom';
import BookingForm from '../components/BookingForm';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { BookingType } from '../types/booking';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { fetchVehicles, selectVehicles } from "../slices/vehiclesSlice";
import { VehicleType } from "../types/vehicle";
import { useAuth } from '../context/AuthContext';

// Validation Schema
const validationSchema = Yup.object({
    customer: Yup.object({
        email: Yup.string().email('Email no valido').required('El email es requerido'),
        name: Yup.string().required('Name is required'),
        phone: Yup.string(),
        surname: Yup.string().required("El apellido es requerido"),
    }),
    bookingInfo: Yup.object({
        bookingNumber: Yup.string().required('El numero de reserva es requerido'),
        dateFrom: Yup.mixed().nullable().required('Fecha de inicio requerida').typeError('Fecha invalida'),
        dateTo: Yup.mixed().nullable().required('Fecha fin requredia').typeError('Fecha invalida'),
        hasFullInsuranceCoverage: Yup.boolean(),
        vehicleId: Yup.string().required('Vehiculo requerida'),
        // vehicle: Yup.object({
        //     brand: Yup.string().required('Marca requerida'),
        //     model: Yup.string().required('Modelo requerido'),
        //     numberPlate: Yup.string().required('Madricula requerida'),
        //     currentKilometers: Yup.string().required('El numero de kilometros actual es requerido'),
        // }),
    })
});


// Form Component
const BookingPage = () => {

    const { bookingId } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const status = useSelector(selectBookingsStatus);
    const [isSearching, setIsSearching] = useState(true);
    const booking = useSelector((state) => selectBookingById(state, bookingId));
    const vehicles: VehicleType[] = useSelector(selectVehicles);
    const { token, user } = useAuth();

    useEffect(() => {
        dispatch(fetchVehicles({ token, user }));
    }, [dispatch, token, user])

    useEffect(() => {
        if (!bookingId)
            setIsSearching(false);
        else {
            if (booking?.id) {
                setIsSearching(false);
            }
        }
    }, [booking, bookingId]);

    const initialValues: BookingType = {
        id: booking?.id ?? "",
        accountId: booking?.accountId ?? "",
        status: booking?.status ?? "",
        customer: {
            email: booking?.customer?.email ?? "",
            phone: booking?.customer?.phone ?? "",
            name: booking?.customer?.name ?? "",
            surname: booking?.customer?.surname ?? "",
        },
        bookingInfo: {
            bookingNumber: booking?.bookingInfo?.bookingNumber ?? "",
            dateFrom: booking?.bookingInfo?.dateFrom ?? new Date(),
            dateTo: booking?.bookingInfo?.dateTo ?? new Date(),
            hasFullInsuranceCoverage: booking?.bookingInfo?.hasFullInsuranceCoverage ?? false,
            vehicleId: booking?.bookingInfo?.vehicleId ?? ""
        },
        lookerInfo: booking?.lookerInfo ?? "",
    };

    const onSubmit = (bookingToSubmit: BookingType) => {
        if (bookingToSubmit.id) {
            dispatch(updateBooking({ booking: bookingToSubmit, token })).then(() => {
                navigate('/dashboard/bookings');
            });
        }
        else {
            bookingToSubmit.status = "Reservado";
            dispatch(createBooking({ booking: bookingToSubmit, token, user })).then(() => {
                navigate('/dashboard/bookings');
            });
        }
    };

    return <>
        {isSearching ? <Splash></Splash> :
            <>
                {status === "loading" ? <Splash></Splash> : <></>}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ handleChange, handleBlur, values, touched, errors, isValid }) => (
                            <Form noValidate>
                                <BookingForm values={values} vehicles={vehicles} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} isValid={isValid}></BookingForm>
                            </Form>
                        )}

                    </Formik>
                </LocalizationProvider>
            </>
        }
    </>
};

export default BookingPage;
