import { Container, Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { fetchLockers, selectLockers, selectLockersStatus } from "../slices/lockersSlice";
import { useEffect } from "react";
import Splash from "../components/Splash";
import LockerItem from "../components/LockerItem";
import { fetchBookings } from "../slices/bookingsSlice";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useAuth } from "../context/AuthContext";
import { fetchTools } from "../slices/toolsSlice";
import { t } from "i18next";
import SectionTitle from "components/SectionTitle";
import SectionContent from "components/SectionContent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LockersPage = () => {
    const dispatch: AppDispatch = useDispatch();
    const status = useSelector(selectLockersStatus);
    const lockers = useSelector(selectLockers);
    const [value, setValue] = React.useState(0);
    const { token, user } = useAuth();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchLockers({ token, user }));
        dispatch(fetchBookings({ token, user }));
        dispatch(fetchTools({ token, user }));
    }, [dispatch, token, user])


    return <>
        {status === "loading" ? <Splash></Splash> : <></>}
        <Box marginTop={"100px"}>
            <SectionTitle>{t("Lockers.Title")}</SectionTitle>
            <SectionContent>{t("Lockers.SubTitle")}</SectionContent>
            <Divider />
            <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {/* <Tab label="Renting" {...a11yProps(0)} /> */}
                            <Tab label={t("Lockers.Tabs.Tools")} {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                    {/* <CustomTabPanel value={value} index={0}>
                    <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: "20px" }}>
                        {
                            lockers?.filter(l => l.useType === 'Renting')
                                .map(l => <LockerItem key={l.id} locker={l} />)
                        }
                    </Grid>
                </CustomTabPanel> */}
                    <CustomTabPanel value={value} index={0}>
                        <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: "20px" }}>
                            {
                                lockers?.filter(l => l.useType === 'Tool')
                                    .map(l => <LockerItem key={l.id} locker={l} />)
                            }
                        </Grid>
                    </CustomTabPanel>
                </Box>

            </Container>
        </Box>
    </>
}

export default LockersPage;