import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import DashboardNavbar from '../components/DashboardNavbar';
import { Container } from '@mui/material';

const PrivateRoute: React.FC = () => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    if (!isAuthenticated()) {
      navigate('/sign-in');
      return <></>;
    }

    return (
      <>
        <DashboardNavbar />
        <Container>
            <Outlet />
        </Container>
      </>
    );
};

export default PrivateRoute;
