import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainButton from 'components/Button';


const LandingNavBar = () => {
  
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const contact = () => {
    navigate('/contact')
  }

  const signIn = () => {
    navigate('/sign-in')
  }

  return (
    <AppBar position="fixed"  sx={{ backgroundColor: '#E6F2F4' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img
              src="./logo_transparent.png"
              alt="Logo"
              style={{ marginRight: 16, height: 40 }}
            />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img
              src="./smartworkshop-logo-simplified.png"
              alt="Logo"
              style={{ marginRight: 16, height: 40 }}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Sign In">
              <MainButton variantType= 'accent_1' onClick={() => signIn()} variant="text">{t('Landing.Nav.Login')}</MainButton>
            </Tooltip>
            <Tooltip title="Sign up">
              <MainButton variantType= 'secondary' margin = '0 0 0 24px' onClick={() => contact()} variant="contained" textTransform="uppercase">{t('Contact')}</MainButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default LandingNavBar;