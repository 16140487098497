import { ReviewType } from "types/review";
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const reviews: Array<ReviewType> = [
    {
        company: "Macman Integral",
        name: "Samuel Blanco (Gerente)",
        comment: "Sergio y su equipo son grandes profesionales. Gracias a sus conocimientos y su herramienta, hemos conseguido tener un control total de todos nuestros centros a tiempo real, que suman un total de 120 personas trabajando. La ocupación y el rendimiento hemos conseguido elevarlos en un 20%. Sin duda, recomendable al 100%.",
        logo: ""
    },
    {
        company: "Talleres Mendez Matélo",
        name: "Cristina Méndez (Gerente)",
        comment: "Muy bien servicio, la aplicación es muy buena y ayuda bastante en las gestiones de taller, la recomiendo 100% es muy útil y facilita el trabajo",
        logo: ""
    },
    {
        company: "JS MOTORSPORT",
        name: "Joan Solé (Gerente)",
        comment: "Sin duda muy recomendable, Sergio y su equipo nos han ayudado a tener un control total de los trabajos y gestión de nuestro taller. Un trato de 10 👏👏",
        logo: ""
    },
    {
        company: "Macman Integral",
        name: "José Ángel  Rodríguez",
        comment: "Más que una opción a tener en cuenta para cualquier taller; es la mejor manera de optimizar las diferentes tareas diarias de un taller. Aporta claridad, dinamismo y una visión real y actualizada del taller en todo momento. Una herramienta más que notable para la gestión de equipos de trabajo sin importar ni las peculiaridades ni las dimensiones de los mismos. Gracias a Sergio Ocete y a su equipo por la implicación desde el primer momento con Macman Integral SL",
        logo: ""
    },
    {
        company: "Talleres Aldosagra",
        name: "Jose Alejandro Merida (Recambios)",
        comment: "Aplicación imprescindible para organizar talleres.Desde que la conocí, vi que era perfecta para la organización diaria del taller. Espero ver cómo evolucionais y mejorais nuestro día a día. Muchas gracias por vuestro trabajo.",
        logo: ""
    },
    {
        company: "Talleres Castisagra",
        name: "Jorge Felix (Recambios)",
        comment: "Una aplicación de gran utilidad para la gestion de talleres en nuestro caso de vehículos industriales de muy sencilla implantación y manejo, Sergio que es la persona que nos ha ayudado estos dias nos lo ha puesto muy facil y ya estamos funcionando con buenos resultados.",
        logo: ""
    },
    
    {
        company: "JC CAR MULTISERVICES",
        name: "José Cañestro (Jefe de taller)",
        comment: "Estamos encantados con el trato, el servicio post venta. Nos han ayudado mucho con la gestión de nuestra empresa. Muchas gracias! 100% recomendable",
        logo: ""
    }
];

const LandingReviewSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Small screens
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium screens
    const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // Large screens

    const itemsToShow = isMobile ? 1 : isTablet ? 2 : isDesktop ? 3 : 1;

    const responsive = {
        all: {
            breakpoint: { max: 4000, min: 0 },
            items: itemsToShow,
        },
    };

    return <Box>
        <Container maxWidth="xl" sx={{
            paddingTop: "40px",
            paddingBottom: "40px",

        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        arrows={false} // Disable default arrow
                    >
                        {reviews.map((review, index) => (
                            <Box
                                key={index}
                                sx={{
                                    padding: 2,
                                    border: "1px solid #E6F2F4",
                                    borderRadius: 2,
                                    boxShadow: 1,
                                    height: "auto",
                                    backgroundColor: "#fff",
                                    margin: "15px 20px",
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                    {review.company}
                                </Typography>
                                <Typography variant="body1" fontStyle="italic" gutterBottom>
                                    {review.comment}
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    - {review.name}
                                </Typography>
                            </Box>
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </Container>
    </Box>



}

export default LandingReviewSection;
