import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Incident } from 'api/types';
import { ToolType } from 'types/tool';
import CustomIconButton from 'components/CustomIconButton';
import { formatTimestamp } from 'lib';
import { useIncidentStatus } from 'lib/hooks/useIncidentStatus';
import SasImage from 'components/SasImage';

interface Props {
  incident: Incident;
  tool?: ToolType
}

const IncidentsItem = (props: Props): React.ReactElement<Props> => {
  const { incident, tool } = props;
  const { t } = useTranslation();
  const { label, color } = useIncidentStatus(incident);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="body2">
              {formatTimestamp(incident.createdDate)} <Chip size="small" sx={{ marginLeft: 1 }} color={color} label={label} variant="outlined" />
              </Typography>
            <Typography component="div" variant="h5">
            {tool?.name || incident.toolId}
                <NavLink to={`/dashboard/incident/${incident.id}`}>
                  <CustomIconButton appearance="blue" width={30}>
                    <EditIcon />
                  </CustomIconButton>
                  </NavLink>
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: 'text.secondary' }}
            >
              <strong>{t('Incidents.Type.Title')}</strong> {incident.incidentType}
            </Typography>
          </CardContent>
        </Box>
        <CardMedia>
          {incident.picture && (
            <SasImage
              fileName={incident.picture[0]}
              alt={incident.incidentType}
              style={{ width: 140, height: 'auto', borderRadius: '8px', marginTop: '8px' }}
            />)}
        </CardMedia>
      </Card>
    </Grid>
  );
};

export default IncidentsItem;