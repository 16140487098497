import { configureStore } from '@reduxjs/toolkit';
import accountReducer from '../src/slices/accountSlice';
import lockersReducer from '../src/slices/lockersSlice';
import lockerReducer from '../src/slices/lockerSlice';
import bookingsReducer from '../src/slices/bookingsSlice';
import customerBookingReducer from '../src/slices/customerBookingSlice';
import checkoutReducer from '../src/slices/checkoutSlice';
import vehiclesReducer from '../src/slices/vehiclesSlice';
import toolsReducer from "./slices/toolsSlice";
import toolReducer from "./slices/toolSlice";
import usersReducer from "../src/slices/userSlice";

const store = configureStore({
  reducer: {
    account: accountReducer,
    lockers: lockersReducer,
    bookings: bookingsReducer,
    customerBooking: customerBookingReducer,
    locker: lockerReducer,
    checkout: checkoutReducer,
    vehicles: vehiclesReducer,
    tool: toolReducer,
    tools: toolsReducer,
    users: usersReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;