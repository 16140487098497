import { Grid, Typography } from "@mui/material";
import IconContainer from "./atoms/IconContainer";
import InformativeText from "./atoms/InformativeTexts";
import Title from "./atoms/title";
import { AccountType } from "../../types/account";
import CustomerLogo from "./CutomerLogo";

type CheckoutFinishedProps = {
    company: AccountType;
}

const CheckoutFinished = (props: CheckoutFinishedProps) => {
    const { company } = props;

    return <>
        <div style={{ display: "none", visibility: "hidden" }}>{company.id}</div>
        <Grid item xs={12} marginTop={"10px"}>
            <IconContainer paddingTop="9px">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                    <path d="M4.19995 22.05C4.19995 20.3103 5.61025 18.9 7.34995 18.9C9.08965 18.9 10.5 20.3103 10.5 22.05V34.65C10.5 36.3897 9.08965 37.8 7.34995 37.8C5.61025 37.8 4.19995 36.3897 4.19995 34.65V22.05Z" fill="#EAFC00" />
                    <path d="M12.6 21.7V33.1043C12.6 34.6951 13.4988 36.1494 14.9217 36.8608L15.0263 36.9132C16.1927 37.4964 17.4789 37.8 18.7829 37.8H30.1568C32.1588 37.8 33.8826 36.3869 34.2752 34.4237L36.7952 21.8237C37.315 19.2248 35.3272 16.8 32.6768 16.8H25.2V8.4C25.2 6.0804 23.3195 4.2 21 4.2C19.8402 4.2 18.9 5.1402 18.9 6.3V7.7C18.9 9.51751 18.3105 11.286 17.2199 12.74L14.28 16.66C13.1894 18.114 12.6 19.8825 12.6 21.7Z" fill="#EAFC00" />
                </svg>
            </IconContainer>
        </Grid>
        <Grid item xs={12}>
            <Title>YA SE HA COMPLETADO EL PROCESO</Title>
            <InformativeText >Esperamos que haya disfrutado de su alquiler.</InformativeText>
        </Grid>
        <Grid item xs={12} marginTop={"50px"}>
            <Typography textAlign={"center"} color="#053D57" fontSize={27} fontStyle={"normal"} fontWeight={500} lineHeight={"160%"} >GRACIAS POR CONFIAR EN</Typography>
            <CustomerLogo img="/dummy-logo.png" height="40px"></CustomerLogo>
        </Grid>
        <Grid item xs={12} marginTop={"50px"} style={{textAlign:"center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71" fill="none">
                <g clipPath="url(#clip0_1_1093)">
                    <path d="M63.2857 0H7.24136C3.27072 0 0.0518799 3.21884 0.0518799 7.18948V63.2338C0.0518799 67.2045 3.27072 70.4233 7.24136 70.4233H63.2857C67.2563 70.4233 70.4752 67.2045 70.4752 63.2338V7.18948C70.4752 3.21884 67.2563 0 63.2857 0Z" fill="#B2D5DC" />
                    <path d="M54.6888 24.6713L61.2265 45.7879C61.8183 47.6993 60.7947 49.2387 58.9433 49.2227L43.9646 49.0988C42.1092 49.0828 40.1259 47.5193 39.5341 45.608L32.9964 24.4914C32.4046 22.5801 33.4283 21.0406 35.2796 21.0566L50.2584 21.1806C52.1137 21.1966 54.097 22.76 54.6888 24.6713Z" fill="#053D57" />
                    <path d="M45.5959 61.2185L59.583 52.5616C60.8506 51.7778 60.3707 51.1221 58.5114 51.0941L43.5046 50.8821C41.6493 50.8542 39.1142 51.4699 37.8506 52.2537L23.8635 60.9106C22.596 61.6944 23.0758 62.3501 24.9352 62.3781L39.9419 62.59C41.7972 62.618 44.3324 62.0022 45.5959 61.2185Z" fill="#053D57" />
                    <path d="M23.8595 8.15314L9.87244 16.8101C8.60489 17.5938 9.08472 18.2496 10.9441 18.2776L25.9508 18.4895C27.8062 18.5175 30.3413 17.9017 31.6048 17.118L45.5919 8.46103C46.8595 7.6773 46.3796 7.02153 44.5203 6.99354L29.5136 6.78162C27.6582 6.75363 25.1231 7.36941 23.8595 8.15314Z" fill="#EAFC00" />
                    <path d="M14.7668 45.1002L8.22907 23.9836C7.63728 22.0723 8.66092 20.5328 10.5123 20.5488L25.491 20.6728C27.3464 20.6888 29.3297 22.2522 29.9215 24.1635L36.4592 45.2801C37.051 47.1915 36.0273 48.7309 34.176 48.7149L19.1972 48.591C17.3419 48.575 15.3586 47.0115 14.7668 45.1002Z" fill="#EAFC00" />
                </g>
                <defs>
                    <clipPath id="clip0_1_1093">
                        <rect width="70.4233" height="70.4233" fill="white" transform="translate(0.0518799)" />
                    </clipPath>
                </defs>
            </svg>
            <Typography textAlign={"center"} color="#828282" fontSize={20} fontStyle={"normal"} fontWeight={400} lineHeight={"160%"} >POWERED BY <br />SMART WORKSHOP</Typography>
        </Grid>
    </>
}
export default CheckoutFinished;