
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { ToolType } from '../types/tool';
import { UserType } from '../types/user';
const BASE_URL = process.env.REACT_APP_TOOLS_API_URL || '';

export const createTool = createAsyncThunk(
    'tool/createTool',
    async ({ tool, token, user }: { tool: ToolType, token: string, user: UserType }, { rejectWithValue }) => {
        try {
            tool.accountId = user.accountId;
            // tool.lookerInfo = null;
            tool.lockerId = null;
            const response = await axios.post(BASE_URL, tool,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateTool = createAsyncThunk(
    'tool/updateTool',
    async ({ tool, token }: { tool: ToolType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${BASE_URL}`, tool,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getTool = createAsyncThunk(
    'tool/requestTool',
    async ({ id, token }: { id: string | undefined, token: string }, { rejectWithValue }) => {
        try {
            if (!id) return toolInitialState;
            const response = await axios.get(`${BASE_URL}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const toolState = (state: RootState) => state.tool;



export const selectToolStatus = createSelector(
    toolState,
    (toolState) => toolState.status
);

export const selectTool = createSelector(
    toolState,
    (toolState) => toolState.tool
);

export const toolInitialState: ToolType = {
    id: undefined,
    accountId: "",
    name: "",
    img: "",
    description: "",
    lockerId: "",
    history: [],
    excludedUsers: [],
    purchaseDate: new Date(),
    nextCalibration: new Date()
};


const toolSlice = createSlice({
    name: 'Tool',
    initialState: {
        tool: toolInitialState,
        status: 'idle',
        error: "",
    },
    reducers: {
        // Your synchronous reducers here (if needed)
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTool.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTool.fulfilled, (state, action) => {
                state.tool = action.payload;
                state.status = 'succeeded';
            })
            .addCase(getTool.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(createTool.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createTool.fulfilled, (state, action) => {
                state.tool = action.payload;
                state.status = 'succeeded';
            })
            .addCase(createTool.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateTool.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateTool.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.tool = action.payload;
            })
            .addCase(updateTool.rejected, (state) => {
                state.status = 'failed';
            })
    }
});

export default toolSlice.reducer;