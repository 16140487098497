import { Box, Button, Grid, styled } from "@mui/material";
import IconContainer from "./atoms/IconContainer";
import { AccountType } from "../../types/account";
import { BookingType } from "../../types/booking";
import { useRef } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { CheckInRequestType } from "../../types/checkInRequest";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { checkIn } from "../../slices/customerBookingSlice";
import Title from "./atoms/title";
import InformativeText from "./atoms/InformativeTexts";
import './CheckInSignature.css';
import { VehicleType } from "../../types/vehicle";

type CheckInSignatureProps = {
    company: AccountType;
    booking: BookingType;
    vehicle: VehicleType;
}
const SignatureContainer = styled(Box)({
    height: '160px',
    width: '100%',
    border: "2px solid #053D57",
    borderRadius: "5px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px"
});



const CheckInSignature = (props: CheckInSignatureProps) => {
    const { booking, vehicle } = props;
    const sigCanvas = useRef<ReactSignatureCanvas>(null);
    const dispatch: AppDispatch = useDispatch();

    const clearSignature = () => {
        sigCanvas.current?.clear();
    }

    const submit = () => {


        const signature = sigCanvas.current?.toDataURL();
        const checkInRequest: CheckInRequestType = {
            signature: signature ?? "",
            accountId: booking.accountId
        };
        dispatch(checkIn({ bookingId: booking.id ?? "", checkInRequest: checkInRequest }));

    }

    const getFormattedDate = (date: any): string => {
        const dateObjet = new Date(date);
        const year = dateObjet.getFullYear();
        const month = (dateObjet.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObjet.getDate().toString().padStart(2, '0');
        return `${day}/${month}/${year}`;
    };

    return <>
        <Grid item xs={12}>
            <IconContainer paddingTop="9px">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.29999 10.5C6.29999 9.3402 7.24019 8.39999 8.39999 8.39999H33.6C34.7598 8.39999 35.7 9.3402 35.7 10.5C35.7 11.6598 34.7598 12.6 33.6 12.6H8.39999C7.24019 12.6 6.29999 11.6598 6.29999 10.5Z" fill="#EAFC00" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.29999 21C6.29999 19.8402 7.24019 18.9 8.39999 18.9H33.6C34.7598 18.9 35.7 19.8402 35.7 21C35.7 22.1598 34.7598 23.1 33.6 23.1H8.39999C7.24019 23.1 6.29999 22.1598 6.29999 21Z" fill="#EAFC00" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.29999 31.5C6.29999 30.3402 7.24019 29.4 8.39999 29.4H21C22.1598 29.4 23.1 30.3402 23.1 31.5C23.1 32.6598 22.1598 33.6 21 33.6H8.39999C7.24019 33.6 6.29999 32.6598 6.29999 31.5Z" fill="#EAFC00" />
                </svg>
            </IconContainer>
        </Grid>
        <Grid item xs={12}>
            <Title>Detalles alquiler</Title>
            <InformativeText>Fechas: <b>{getFormattedDate(booking.bookingInfo.dateFrom)} - {getFormattedDate(booking.bookingInfo.dateTo)}</b></InformativeText>
            <InformativeText >Vehiculo: <b>{vehicle.brand} {vehicle.model}</b></InformativeText>
            <InformativeText >Matricula: <b>{vehicle.numberPlate}</b></InformativeText>
            <InformativeText >Seguro:<b>{booking.bookingInfo.hasFullInsuranceCoverage ? "A todo riesgo sin franquicia" : "No"}</b></InformativeText>
        </Grid>
        <Grid item xs={12}>
            <Title>RECOGIDA DE LLAVES</Title>
            <InformativeText>Una vez firmado, le indicaremos cuál es la taquilla y el código para la recogida de las llaves de su vehículo.</InformativeText>
        </Grid>
        <Grid item xs={12}>
            <Title>Firma</Title>
            <SignatureContainer>
                <ReactSignatureCanvas
                    ref={sigCanvas}
                    clearOnResize={false}
                    canvasProps={{
                        className: 'check-in-signature-canvas',
                    }}
                />
            </SignatureContainer>
        </Grid>
        <Grid item sx={{ width: "100%", display: "flex", paddingTop: "20px", paddingBottom: "20px", justifyContent: "space-between" }}>
            <Grid item xs={5}>
                <Button style={{ backgroundColor: "#B2D5DC", color: "#053D57", fontWeight: "bold", height: "80px" }} variant="contained" fullWidth onClick={clearSignature}>
                    Borrar
                </Button>
            </Grid>
            <Grid item xs={5}>
                <Button color="primary" variant="contained" style={{ height: "80px", color: "#EAFC00" }} fullWidth onClick={submit}>
                    Firmar
                </Button>
            </Grid>
        </Grid>
    </>
};

export default CheckInSignature