import { Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const Input = styled('input')({
    display: 'none',
});

type MediaUploadProps = {
    inputName: string
    file: FormData | null;
    onChange: any;
    disabled?: boolean;
    label?: string;
}

const MediaUpload = (props: MediaUploadProps) => {
    const { t } = useTranslation();
    const { inputName, file, onChange, disabled = false, label } = props;

    const onFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('filename', selectedFile.name);
            formData.append('type', selectedFile.type);
            onChange(formData);
        }
    }

    return <Grid item sx={{ width: "100%", display: "flex", paddingTop: "20px", paddingBottom: "20px", justifyContent: "space-between" }}>
        <Grid item xs={5}>
            <label htmlFor={`file-input-${inputName}`}>
                <Input
                    accept="image/*;capture=camcorder"
                    capture="environment"
                    id={`file-input-${inputName}`}
                    type="file"
                    name="image"
                    onChange={onFileChange}
                />
                <Button
                  style={{ fontWeight: 'bold' }}
                  variant="contained"
                  disabled={disabled}
                  component="span"
                >
                  {label || t('Buttons.Attach')}
              </Button>
            </label>
        </Grid>
        <Grid item xs={5}>
            {
                file ?
                  <div style={{ width: "100%",
                    height: "50px",
                    color: "#EAFC00",
                    backgroundColor: "#B2D5DC",
                    fontSize: "15px",
                    borderRadius: "4px",
                    display: "flex",
                    boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)" }}>
                    <svg
                      style={{ marginTop: " 4px", marginLeft: " 6px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.39995 10.5C6.08036 10.5 4.19995 12.3804 4.19995 14.7V31.5C4.19995 33.8196 6.08036 35.7 8.39995 35.7H33.5999C35.9195 35.7 37.7999 33.8196 37.7999 31.5V14.7C37.7999 12.3804 35.9195 10.5 33.5999 10.5H30.2698C29.7128 10.5 29.1787 10.2787 28.7849 9.88491L26.4301 7.53014C25.6424 6.74249 24.5742 6.29999 23.4603 6.29999H18.5396C17.4257 6.29999 16.3575 6.74249 15.5698 7.53014L13.215 9.88491C12.8212 10.2787 12.2871 10.5 11.7301 10.5H8.39995ZM21 29.4C24.4793 29.4 27.2999 26.5794 27.2999 23.1C27.2999 19.6206 24.4793 16.8 21 16.8C17.5206 16.8 14.7 19.6206 14.7 23.1C14.7 26.5794 17.5206 29.4 21 29.4Z"
                          fill="#EAFC00"
                        />
                    </svg>
                    <label style={{ marginTop: "14px", marginLeft: "4px", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap", width: "100%" }}>
                      {file?.get("filename")?.toString()}
                    </label>
                </div> : <></>
            }

        </Grid>
    </Grid>
};

export default MediaUpload;
