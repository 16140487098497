import {
  Button as MuiButton,
  ButtonProps,
} from '@mui/material';
import * as React from 'react';

type Appearance = 'blue' | 'yellow' | 'blueVariant';

const cfg = {
  blue: {
    color: 'primary' as ButtonProps['color'],
    variant: 'contained' as ButtonProps['variant'],
  },
  blueVariant: {
    style: {
      backgroundColor: '#053D57',
      color: '#EAFC00',
      fontWeight: 'bold',
    },
    variant: 'outlined' as ButtonProps['variant'],
  },
  yellow: {
    style: {
      backgroundColor: '#EAFC00',
      color: '#053d57',
      fontWeight: 'bold',
    },
    variant: 'contained' as ButtonProps['variant'],
  },
};

type Props = ButtonProps & { appearance?: Appearance };

const CustomButton = (props: Props): React.ReactElement<Props> => (
  <MuiButton
    {...(props.appearance && cfg[props.appearance] ? cfg[props.appearance] : {})}
    {...props}
  >
      {props.children}
    </MuiButton>
);

export default CustomButton;