import { useQuery } from "@tanstack/react-query";
import { getDownloadSasUri } from "api/file";
import { useAuth } from "context/AuthContext";

export const useDownloadSasUri = (fileName: string) => {
  const { token } = useAuth();

  const download = async (fileName: string) => {
    const sasUri = await getDownloadSasUri({ token, fileName });
    return sasUri.sasUri;
  };

  return useQuery<string, Error>({
    queryKey: ['download-sas-uri', fileName],
    queryFn: () => download(fileName),
    enabled: fileName !== '',
  });
};