import { useEffect } from 'react';
import { AppDispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import Splash from '../components/Splash';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Divider, Grid, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { fetchTools, selectTools, selectToolsStatus } from "../slices/toolsSlice";
import { useTranslation } from "react-i18next";
import { ToolType } from "../types/tool";
import ToolItem from "../components/ToolItem";
import { useAuth } from '../context/AuthContext';
import { fetchUsers, selectUsersStatus } from 'slices/userSlice';
import SectionTitle from 'components/SectionTitle';

const ToolsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const status = useSelector(selectToolsStatus);
    const usersStatus = useSelector(selectUsersStatus);
    const tools: ToolType[] = useSelector(selectTools);
    const { token, user } = useAuth();

    useEffect(() => {
        dispatch(fetchTools({ token, user }));
        dispatch(fetchUsers({ token, user }));
    }, [dispatch, token, user])

    const addBooking = () => {
        navigate('/dashboard/tool');
    }

    return <>
        {status === "loading" || usersStatus === "loading" ? <Splash></Splash> : <></>}
        <Box marginTop={"100px"}>
            <SectionTitle>{t("Tools.Title")}</SectionTitle>
            <h4>{t("Tools.SubTitle")}</h4>
            <Divider />
            <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        onClick={addBooking}
                        sx={{
                            width: 56,
                            height: 56,
                            bgcolor: 'primary.main',
                            color: 'white',
                            '&:hover': { bgcolor: 'primary.dark' } // Optional: Change color on hover
                        }}
                        style={{ width: 40, height: 40 }}
                        aria-label="add"
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: "20px" }}>
                    {
                        tools.map((tool: ToolType) => {
                            return <ToolItem tool={tool} key={tool.id}/>
                        })
                    }
                </Grid>

            </Container>
        </Box>
    </>
}
export default ToolsPage;