import { Button, Card, CardContent, Chip, Grid, Paper } from "@mui/material";
import { LockerType } from "../types/locker";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { assignLockerTool } from "slices/toolsSlice";
import { AppDispatch } from "store";
import { useAuth } from "context/AuthContext";
import AssignLocker from "./AssignLocker";
import BookingLockerItem from "./BookingLockerItem";
import ToolLockerItem from "./ToolLockerItem";
import { assignUse } from "slices/lockersSlice";

type LockerItemProps = {
    locker: LockerType;
}

const LockerItem = (props: LockerItemProps) => {
    const { t } = useTranslation();
    const [openLocker, setOpenLocker] = useState<boolean>(false);
    const { locker } = props;
    const dispatch: AppDispatch = useDispatch();
    const { token } = useAuth();

    const assignLockerUse = () => {
        setOpenLocker(true);
    }

    const handleCloseBooking = () => {
        setOpenLocker(false);
    }

    const releaseUse = () => {
        dispatch(assignLockerTool({
            toolId: locker.useId ?? "",
            assignLockerRequest: {
                accountId: locker.accountId,
                lockerId: undefined
            },
            token
        }));
        dispatch(assignUse({
            id: locker.id ?? "",
            assignUseRequest: {
                accountId: locker.accountId,
                useId: undefined
            },
            token
        }));
    }

    const calculateLockerSize = () => {
        return `${locker.lockerSize * 40}px`;
    }

    return <>
        <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6} >

                                <Chip sx={{
                                    bgcolor: '#053d57',
                                    color: 'white',
                                    '& .MuiChip-deleteIcon': {
                                        color: 'white',
                                    },
                                    width: calculateLockerSize()
                                }}
                                    label={locker.identifier}
                                />
                            </Grid>
                            <Grid item xs={6} textAlign={"right"}>
                                <Chip sx={{
                                    bgcolor: locker.status === "Available" ? 'green' : 'orange',
                                    color: 'white',
                                    opacity: '0.6',
                                    '& .MuiChip-deleteIcon': {
                                        color: 'white',
                                    }
                                }}
                                    label={t(`Lockers.Status.${locker.status}`)}
                                />

                            </Grid>
                        </Grid>
                        {
                            locker.useType === 'Renting' ? <BookingLockerItem bookingId={locker.useId ?? ""}></BookingLockerItem> : <ToolLockerItem toolId={locker.useId ?? ""}></ToolLockerItem>
                        }
                        <div style={{ display: 'flex', marginTop: "15px", justifyContent: 'flex-end' }}>
                            {
                                locker.status === "Available"
                                    ?
                                    <>
                                        <Button variant="contained" style={{ backgroundColor: "#EAFC00", color: "#053d57", fontWeight: "bold" }} onClick={assignLockerUse}>{t("Lockers.Buttons.Assign")}</Button>
                                    </>
                                    :
                                    <>
                                        <Button variant="contained" style={{ backgroundColor: "#B2D5DC", color: "#053d57", fontWeight: "bold" }} onClick={releaseUse} >{t("Lockers.Buttons.Release")}</Button>
                                    </>
                            }
                        </div>

                    </CardContent>
                </Card>
            </Paper>
        </Grid >
        <AssignLocker open={openLocker} handleClose={handleCloseBooking} locker={locker}></AssignLocker>
    </>
}

export default LockerItem;
