import { Box, Grid, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserType } from "../types/user";
import { useTranslation } from "react-i18next";
import React from "react";
import PasswordModal from "../components/Shared/Password";
import { PasswordType } from "../types/password";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { updatePassword } from "../slices/passwordSlice";
import { useAuth } from '../context/AuthContext';
type UserFormProps = {
    values: UserType;
    handleChange: any;
    handleBlur: any;
    touched: any,
    errors: any,
    isValid: any,
    screenType: any,
}
const UserForm = (props: UserFormProps) => {
    const { values, handleChange, handleBlur, touched, errors, isValid, screenType } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token, user } = useAuth();
    const dispatch: AppDispatch = useDispatch();
    const [isPasswordModalOpen, setPasswordModalOpen] = React.useState(false);

    const handlePasswordModalOpen = (event: any) => {
        event.preventDefault();
        setPasswordModalOpen(true);
    }
    const handlePasswordModalClose = () => {
        setPasswordModalOpen(false);
    }

    const handlePasswordSave = (passwordToUpdate: PasswordType) => {
        passwordToUpdate.accountId = user.accountId;
        passwordToUpdate.userId = user.id;
        dispatch(updatePassword({ password: passwordToUpdate, token: token })).then(() => {
            setPasswordModalOpen(false);
            navigate('/dashboard/users');
        });
    };

    // const roles = [
    //     {
    //         "roleId": "workshopAdmin",
    //         "roleName": "USER_SERVICE_ROLE_WORKSHOP_ADMIN"
    //     },
    //     {
    //         "roleId": "technician",
    //         "roleName": "USER_SERVICE_ROLE_TECHNICIAN"
    //     }
    // ]
    return <Box sx={{ mt: 3 }}>
        <Grid container spacing={2} sx={{ display: 'flex' }}>
            <h3 style={{ width: "100%" }}>{screenType === 'edit' ? t("EditUser.EditTitle") : t("EditUser.AddTitle")}</h3>
            {values.id && (
                <Grid item xs={12} textAlign={"right"}>
                    <Button color="primary" variant="contained" onClick={handlePasswordModalOpen}>
                        {t("EditUser.SetPassword")}
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="userName"
                    name="userName"
                    label={t("EditUser.UserName")}
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.userName && Boolean(errors.userName)}
                    helperText={touched.userName && errors.userName}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="name"
                    name="name"
                    label={t("EditUser.Name")}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="surname"
                    name="surname"
                    label={t("EditUser.SurName")}
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.surname && Boolean(errors.surname)}
                    helperText={touched.surname && errors.surname}
                />
            </Grid>
            {/* <h3 style={{ width: "100%" }}>{t("EditUser.Access")}</h3>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="editUser.management.label">
                        {t("EditUser.Management")}
                    </InputLabel>
                    <Select
                        labelId="editUser.management.label"
                        id="editUser.management"
                        name="access.managementRoleId"
                        label={t("EditUser.Management")}
                        value={values.access?.managementRoleId ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {roles.map((item) => (
                            <MenuItem key={item.roleId} value={item.roleId}>
                                {item.roleName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="editUser.checkin.label">
                        {t("EditUser.Checkin")}
                    </InputLabel>
                    <Select
                        labelId="editUser.checkin.label"
                        id="editUser.checkin"
                        name="access.CheckingRoleId"
                        label={t("EditUser.Checkin")}
                        value={values.access?.CheckingRoleId ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {roles.map((item) => (
                            <MenuItem key={item.roleId} value={item.roleId}>
                                {item.roleName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="editUser.tools.label">
                        {t("EditUser.Tools")}
                    </InputLabel>
                    <Select
                        labelId="editUser.tools.label"
                        id="editUser.tools"
                        name="access.toolsRoleId"
                        label={t("EditUser.Tools")}
                        value={values.access?.toolsRoleId ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {roles.map((item) => (
                            <MenuItem key={item.roleId} value={item.roleId}>
                                {item.roleName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="editUser.renting.label">
                        {t("EditUser.Renting")}
                    </InputLabel>
                    <Select
                        labelId="editUser.renting.label"
                        id="editUser.renting"
                        name="access.rentingRoleId"
                        label={t("EditUser.Renting")}
                        value={values.access?.rentingRoleId ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {roles.map((item) => (
                            <MenuItem key={item.roleId} value={item.roleId}>
                                {item.roleName}
                            </MenuItem>
                        ))}
                    </Select>
                    {touched.access && errors.access && (
                        <FormHelperText error>{errors.access}</FormHelperText>
                    )}
                </FormControl>
            </Grid> */}
            <PasswordModal open={isPasswordModalOpen} onClose={handlePasswordModalClose} onSave={handlePasswordSave} screenType={screenType} />
            <Grid item xs={6}>
                <Button color="secondary"  variant="contained" fullWidth onClick={() => navigate('/dashboard/users')}>
                    {t("Buttons.Return")}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={!isValid}>
                    {t("Buttons.Save")}
                </Button>
            </Grid>
        </Grid>
    </Box>
}

export default UserForm;