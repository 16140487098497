import { CircularProgress, Container } from "@mui/material";

const Splash = () => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure it's on top of other content
          }}>
            <Container maxWidth="lg" style={{ textAlign: 'center' }}>
              <CircularProgress color="inherit" />
            </Container>
          </div>
    );
}

export default Splash;