import axios from 'axios';

import { apiBaseUrls } from 'config';
import {
  AuthenticatedRequest,
  PostIncidentBody,
  IncidentAPIResponse,
  Incident,
} from 'api/types';

export const createIncident = async (params: {
  token: string,
  body: PostIncidentBody,
}) => {
  const { token, body } = params;
  const { data } = await axios.post(apiBaseUrls.incidents, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const updateIncident = async (params: {
  token: string,
  body: Incident,
}) => {
  const { token, body } = params;
  const { data } = await axios.put(apiBaseUrls.incidents, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getIncidents = async (params: { accountId: string; token: string }): Promise<IncidentAPIResponse[]> => {
  const { accountId, token } = params;
  const { data } = await axios.get<IncidentAPIResponse[]>(`${apiBaseUrls.incidents}/account/${accountId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getIncident = async (params: AuthenticatedRequest & { incidentId: string }): Promise<IncidentAPIResponse> => {
  const { token, incidentId } = params;

  const { data } = await axios.get<IncidentAPIResponse>(`${apiBaseUrls.incidents}/${incidentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getIncidentTypes = async (): Promise<string[]> => (
  Promise.resolve(['type0', 'type1', 'type2'])
);

export const createObservation = async (params: AuthenticatedRequest & {
  body: { observations: string, userId: string, incidentId: string },
}) => {
  const { accountId, token, body } = params;
  const { data } = await axios.post(`${apiBaseUrls.incidents}/account/${accountId}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};