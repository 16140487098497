import { useDispatch, useSelector } from "react-redux";
// import { selectToolsWithNoAssignedLocker } from "../slices/lockerSlice";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import { useState } from "react";
import { LockerType } from "../types/locker";
import { AppDispatch } from "../store";
import { assignUse } from "../slices/lockersSlice";
import { selectToolsWithNoAssignedLocker, assignLockerTool } from "../slices/toolsSlice";
import { useAuth } from "../context/AuthContext";
import { ToolType } from "../types/tool";
import { useTranslation } from "react-i18next";
type AssignLockerProps = {
    open: boolean;
    handleClose: any,
    locker: LockerType
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AssignLocker = (props: AssignLockerProps) => {
    const { open, handleClose, locker } = props;
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const tools = useSelector(selectToolsWithNoAssignedLocker);
    const { token } = useAuth();
    const [dropdownValue, setDropdownValue] = useState('');
    const handleDropdownChange = (event: any) => {
        setDropdownValue(event.target.value);
    };

    const assign = () => {
        dispatch(assignLockerTool({
            toolId: dropdownValue,
            assignLockerRequest: {
                accountId: locker.accountId,
                lockerId: locker.id ?? ""
            },
            token
        }));

        dispatch(assignUse({
            id: locker.id ?? "",
            assignUseRequest: {
                accountId: locker.accountId,
                useId: dropdownValue
            },
            token
        }));
        handleClose();

    }

    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
    >
        <Box sx={style}>
            <h2 id="modal-title">Asignar herramienta</h2>
            <FormControl fullWidth>
                <InputLabel id="dropdown-label">Selecciona una opcion</InputLabel>
                <Select
                    labelId="dropdown-label"
                    id="dropdown"
                    value={dropdownValue}
                    label="Selecciona una opcion"
                    onChange={handleDropdownChange}
                >
                    {
                        tools.filter(b => !b.lockerId).map((b: ToolType) => {
                            return <MenuItem value={b.id ?? "tools"} key={b.id ?? "tools"}>{b.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <Button variant="contained" sx={{ mt: 2 }} style={{ backgroundColor: "#EAFC00", color: "#053d57", fontWeight: "bold" }} onClick={assign}>{t("Lockers.Buttons.Assign")}</Button>
        </Box>
    </Modal>
}

export default AssignLocker;