import { Box, Grid, TextField, Button, Typography, Alert, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { ToolType } from "../types/tool";
import { useTranslation } from 'react-i18next';
import { UserType } from '../types/user';
import ToolCalibration from 'components/ToolCalibration/index';
import SectionTitle from "./SectionTitle";
import ToolFiles from "./ToolFiles";
import ToolHistory from "pages/ToolPage/History";

export type ToolFormProps = {
    success: 'Updated' | 'Created' | null;
    values: ToolType;
    users: UserType[],
    handleChange: any;
    handleBlur: any;
    touched: any,
    errors: any,
    isValid: any,
    toolId: string | undefined,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ToolForm = (props: ToolFormProps) => {
    const { values, handleChange, handleBlur, touched, errors, isValid, setFieldValue, success } = props;
    const [fileName, setFileName] = useState<string | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const convertFileToBase64 = async (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0] || null;
        setFileName(file ? file.name : null);
        if (file && typeof file !== 'string') {
            const base64 = await convertFileToBase64(file);
            setFieldValue('img', base64);
            setFieldValue('fileName', file.name);
            convertBase64toImg(base64);
        }
    };
    const convertBase64toImg = (file: any) => {
        if (typeof file === 'string') {
            setFieldValue('imgUrl', file);
        } else if (file instanceof File) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFieldValue('imgUrl', reader.result as string)
            };
            reader.readAsDataURL(file);
        }
    }

    return <Box sx={{ mt: "100px" }}>
        <Grid container spacing={2} sx={{ display: 'flex' }}>
            {success && <Alert severity="success">{t(`EditTools.OnSubmit.Alert.${success}`)}</Alert>}

            <SectionTitle>{t("EditTools.Title")}</SectionTitle>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        name="name"
                        label={t("EditTools.Name")}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        sx={{
                            marginBottom: "20px"
                        }}
                    />
                    <TextField
                        fullWidth
                        id="description"
                        name="description"
                        label={t("EditTools.Description")}
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        sx={{
                            marginBottom: "20px"
                        }}
                    />
                    <TextField
                        fullWidth
                        id="reference"
                        name="reference"
                        label={t("EditTools.Reference")}
                        value={values.reference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.reference && Boolean(errors.reference)}
                        helperText={touched.reference && errors.reference}
                        sx={{
                            marginBottom: "20px"
                        }}
                    />
                    <TextField
                        fullWidth
                        id="purchaseDate"
                        name="purchaseDate"
                        label={t("EditTools.PurchaseDate")}
                        value={values.purchaseDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="date"
                        error={touched.purchaseDate && Boolean(errors.purchaseDate)}
                        helperText={touched.purchaseDate && errors.purchaseDate}
                        sx={{
                            marginBottom: "20px"
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                                {t("Buttons.UploadImage")}
                            </Button>
                        </label>
                        {fileName && (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                Selected file: {fileName}
                            </Typography>
                        )}
                        {touched.img && errors.img ? (
                            <Typography color="error" variant="body2">{errors.img}</Typography>
                        ) : null}
                    </Box>
                    {values.imgUrl && (
                        <img src={values.imgUrl} alt={values.name} style={{ maxHeight: '240px', marginTop: '10px' }} />
                    )}
                </Grid>
            </Grid>

            {
                values.id ? <><Grid item xs={12}>
                    <ToolCalibration {...props} />
                </Grid>
                    <Grid item xs={12}>
                        <ToolFiles {...props}></ToolFiles>
                    </Grid></> : <></>
            }

            <Grid item xs={6}>
                <Button style={{ backgroundColor: "#EAFC00", color: "#053d57", fontWeight: "bold" }} variant="contained" fullWidth onClick={() => navigate('/dashboard/tools')}>
                    {t("Buttons.Return")}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={!isValid}>
                    {t("Buttons.Save")}
                </Button>
            </Grid>
            <ToolHistory toolHistory={values.history} />
        </Grid>
    </Box>
}

export default ToolForm;