import { IncidentAPIResponse, Incident } from 'api/types';
import { TFunction } from 'i18next';

export const mapIncident = (incident: IncidentAPIResponse): Incident => ({
  ...incident,
  createdDate: new Date(incident.createdDate),
  closedDate: incident.closedDate ? new Date(incident.closedDate) : undefined,
});

export const mapIncidentType = (incident: string, t: TFunction<"translation", undefined>): {
  id: string;
  label: string;
} => ({
  id: incident,
  label: t(`IncidentPage.Type.${incident}`),
});