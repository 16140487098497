import axios from 'axios';
import { apiBaseUrls } from 'config';

export type AuthenticatedRequest = {
    accountId?: string;
    token: string;
};

export const getUploadSasUri = async (params: AuthenticatedRequest & {
    fileName: string
}) => {
    const { token, fileName } = params;
    const { data } = await axios.get(`${apiBaseUrls.file}/upload/${fileName}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};

export const getDownloadSasUri = async (params: AuthenticatedRequest & {
    fileName: string
}) => {
    const { token, fileName } = params;
    const { data } = await axios.get(`${apiBaseUrls.file}/download/${fileName}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return data;
};