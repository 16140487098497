import { Button, Grid } from "@mui/material";
import IconContainer from "./atoms/IconContainer";
import { LockerType } from "../../types/locker";
import Title from "./atoms/title";
import InformativeText from "./atoms/InformativeTexts";

type CheckInLockerProps = {
    locker: LockerType;
    setStep: any;
}

const CheckInLocker = (props: CheckInLockerProps) => {
    const { locker, setStep } = props;

    const confirmPickup = () => {
        setStep("Finished");
    }

    return <>
        <Grid item xs={12}>
            <IconContainer paddingTop="12px">
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M33.7999 12.8C33.7999 19.7588 28.1587 25.4 21.2 25.4C19.927 25.4 18.6981 25.2112 17.5398 24.8601L12.8 29.6H8.59995V33.8H0.199951V25.4L9.1398 16.4601C8.78872 15.3018 8.59995 14.073 8.59995 12.8C8.59995 5.84121 14.2412 0.199997 21.2 0.199997C28.1587 0.199997 33.7999 5.84121 33.7999 12.8ZM21.2 4.4C20.0402 4.4 19.1 5.3402 19.1 6.5C19.1 7.65979 20.0402 8.6 21.2 8.6C23.5195 8.6 25.4 10.4804 25.4 12.8C25.4 13.9598 26.3402 14.9 27.5 14.9C28.6597 14.9 29.5999 13.9598 29.5999 12.8C29.5999 8.1608 25.8391 4.4 21.2 4.4Z" fill="#EAFC00" />
                </svg>
            </IconContainer>
        </Grid>

        <Grid item xs={12}>
            <Title>Taquilla</Title>
            <InformativeText >Nº de taquilla: <b>{locker.identifier}</b></InformativeText>
        </Grid>
        <Grid item xs={12} marginTop={"100px"}>
            <InformativeText >Por favor, cuando recoja las llaves confirme la recogida.</InformativeText>
        </Grid>
        <Grid item xs={6} pb={"20px"} pt={"20px"}>
            <Button color="primary" variant="contained" sx={{color:"#EAFC00", height:"80px"}} fullWidth onClick={confirmPickup}>
                CONFIRMAR
            </Button>
        </Grid >
    </>
}

export default CheckInLocker;