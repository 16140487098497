import { Incident } from "api/types";
import { useTranslation } from "react-i18next";

export const useIncidentStatus = (incident: Incident) => {
  const isOpen = incident.closedDate === undefined;
  const status = isOpen ? 'open' : 'closed';
  const color = isOpen ? 'warning' : 'success';
  const { t } = useTranslation();

  return {
    label: t(`Incidents.Status.${status}`),
    status,
    color: color as 'success' | 'warning',
  };
};