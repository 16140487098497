import { Box, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import LandingSectionTitle from "./LandingSectionTitle";
import LandingSectionContent from "./LandingSectionContent";

const TransformationSection = () => {
    const { t } = useTranslation();
    return <Box>
        <Container maxWidth="xl" sx={{
            paddingTop: "40px"
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <img style={{ width: "100%" }} src="/assets/img/landing/transformation.png" alt="transformation_pic"></img>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Container sx={{
                    paddingLeft: {
                        xs: "10px",
                        md: "50px"
                    },
                    paddingRight: {
                        xs: "10px",
                        md: "50px"
                    },
                    paddingTop: {
                        xs: "20px",
                        md: "180px"
                    },
                }}>
                        <LandingSectionTitle>
                            {t("Landing.Transformation.Title")}
                        </LandingSectionTitle>
                        <LandingSectionContent htmlContent={t("Landing.Transformation.Content_1")} />
                        <br/>
                        <LandingSectionContent htmlContent={t("Landing.Transformation.Content_2")} />
                    </Container>
                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default TransformationSection;
