import { IconDictionary } from "types/iconDictionary";
import { BsFillSdCardFill } from "react-icons/bs";
import { GoAlertFill } from "react-icons/go";
import { SiGooglesearchconsole } from "react-icons/si";
import { IoExit } from "react-icons/io5";
import { IoIosExit } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiSolidUserAccount } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import { FaFileCirclePlus } from "react-icons/fa6";
import { FaFileCircleMinus } from "react-icons/fa6";


export const historyActionIcons: IconDictionary = {
  create: <AiFillPlusCircle />,
  update: <BsFillSdCardFill />,
  incident: <GoAlertFill />,
  calibration: <SiGooglesearchconsole />,
  pickup: <IoExit />,
  return: <IoIosExit />,
  // loan: 
  loanReturned: <IoIosExit />,
  reAssigned: <BiSolidUserAccount />,
  retired: <TiDelete />,
  fileAdded: <FaFileCirclePlus />,
  fileRemoved: <FaFileCircleMinus />

  


  // incidentOpen
  //incidentClose
};