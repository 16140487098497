import { useQuery } from '@tanstack/react-query';
import { getTools } from 'api/tools';
import { useAuth } from 'context/AuthContext';

export const useTools = (params?: { accountId: string; token: string; }) => {
  const { token: defaultToken, user } = useAuth();
  const { accountId, token } = params || { accountId: user.accountId, token: defaultToken };

  return useQuery({
    queryKey: ['tools'],
    queryFn: () => getTools({ token, accountId }),
    enabled: !!accountId && !!token,
  });
};