import React from "react";
import { Typography, TypographyProps } from "@mui/material";

interface TextProps extends TypographyProps {
    children: React.ReactNode;
}

const LandingSectionTitle: React.FC<TextProps> = ({ children, sx, ...props }) => {
    return (
        <Typography
            {...props}
            sx={{
                width: "100%",
                color: "#053D57",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "46px",
                marginBottom: "16px",
                ...(sx || {}),
            }}
        >
            {children}
        </Typography>
    );
};

export default LandingSectionTitle;
