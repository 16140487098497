import * as React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';

import { ToolType } from 'types/tool';
import { UseModalReturn } from 'lib/hooks/useModal';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { PostToolCalibrationBody, createCalibration } from 'api/tools';
import { useAuth } from 'context/AuthContext';
import { TFunction } from 'i18next';
import MainButton from 'components/Button';

type Props = UseModalReturn & {
  tool: ToolType;
};

interface FormikValues {
  entity: string;
  observations: string;
};

const cfg = {
  validationSchema: (t: TFunction<'translation', undefined>) => Yup.object().shape({
    entity: Yup.string()
      .required(t('EditTools.AllCalibration.entity.required')),
  }),
  successTimeout:1 * 1000,
};

const ToolCalibrationAddModal = (props: Props): React.ReactElement<Props> => {
  const { tool, ...rest } = props;
  const { user, token } = useAuth();
  const [success] = React.useState<boolean>(false);
  const { mutate: doPost, status, error } = useMutation({
    mutationFn: (body: PostToolCalibrationBody) => createCalibration({
      toolId: tool.id as string,
      body,
      token: token as string,
      accountId: user?.accountId as string,
    }),
    onSuccess: (data) => {
      setTimeout(() => {
        props.handleClose(data);
      }, cfg.successTimeout);

    },
    onError: ({ response }: AxiosError) => {
      
      console.error('Registration error', response?.data);
    },
  });
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('EditTools.AddCalibration.Title')}
      {...rest}
    >
      {success && <Alert severity="success">{t('EditTools.AddCalibration.Alert.Success')}</Alert>}
      {error && <Alert severity="error">{t(`EditTools.AddCalibration.Alert.${error.request.status || error.code}`)}</Alert>}
      <Formik<FormikValues>
        initialValues={{
          entity: '',
          observations: '',
        }}
        validationSchema={cfg.validationSchema(t)}
        onSubmit={({ entity, observations }) => doPost({ entity, observations })}
      >
        {({ values, handleChange, handleSubmit, handleBlur, touched, errors, isValid }) => (
          <>
            <DialogContent>
              <TextField
                required
                fullWidth
                id="entity"
                name="entity"
                label={t("EditTools.AddCalibration.Entity")}
                placeholder='Entity'
                value={values.entity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.entity && Boolean(errors.entity)}
                helperText={touched.entity && errors.entity}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                disabled={status === 'pending'}
              />
              <TextField
                margin="normal"
                autoFocus
                fullWidth
                id="observations"
                name="observations"
                label={t("EditTools.AddCalibration.Observations")}
                value={values.observations}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.observations && Boolean(errors.observations)}
                helperText={touched.observations && errors.observations}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                multiline
                rows={4}
                disabled={status === 'pending'}
              />
            </DialogContent>
            <DialogActions>
              <MainButton variantType='secondary' onClick={props.handleClose}>Cancel</MainButton>
              <Button
                variantType="primary"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={!isValid || status === 'pending'}
              >
                {t('EditTools.AddCalibration.Submit')}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ToolCalibrationAddModal;