import { useEffect, useState } from 'react';
import { fetchBookings, selectBookingsStatus } from '../slices/bookingsSlice';
import { AppDispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import Splash from '../components/Splash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createVehicle, selectVehicleById, updateVehicle } from '../slices/vehiclesSlice';
import { VehicleType } from '../types/vehicle';
import VehicleForm from '../components/VehicleForm';
import { useAuth } from '../context/AuthContext';

// Validation Schema
const validationSchema = Yup.object({
    brand: Yup.string().required('Marca requerida'),
    model: Yup.string().required('Modelo requerido'),
    numberPlate: Yup.string().required('Matricula requerida'),
    currentKilometers: Yup.number().required('El numero de kilometros actual es requerido'),
});


// Form Component
const VehiclePage = () => {
    const { vehicleId } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const status = useSelector(selectBookingsStatus);
    const [isSearching, setIsSearching] = useState(true);
    const vehicle: VehicleType = useSelector((state) => selectVehicleById(state, vehicleId));
    const {token, user} = useAuth();

    useEffect(() => {
        dispatch(fetchBookings({token, user}));
    }, [dispatch, token, user])

    useEffect(() => {
        if (!vehicleId)
            setIsSearching(false);
        else {
            if (vehicle.id) {
                setIsSearching(false);
            }
        }
    }, [vehicle, vehicleId]);


    const initialValues = {
        id: vehicle?.id ?? "",
        accountId: vehicle?.accountId ?? "",
        brand: vehicle?.brand ?? "",
        model: vehicle?.model ?? "",
        numberPlate: vehicle?.numberPlate ?? "",
        currentKilometers: vehicle?.currentKilometers ?? 0,
        // vehicleId: vehicle?.vehicleId ?? "",
    };

    const onSubmit = (vehicleToSubmit: VehicleType) => {
        if (vehicleToSubmit.id) {
            dispatch(updateVehicle({ vehicle: vehicleToSubmit, token, })).then(() => {
                navigate('/dashboard/vehicles');
            });
        }
        else {
            dispatch(createVehicle({ vehicle: vehicleToSubmit, token, user })).then(() => {
                navigate('/dashboard/vehicles');
            });
        }

    };

    return <>
        {isSearching ? <Splash></Splash> :
            <>
                {status === "loading" ? <Splash></Splash> : <></>}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ handleChange, handleBlur, values, touched, errors, isValid }) => (
                            <Form noValidate>
                                <VehicleForm values={values} handleChange={handleChange} handleBlur={handleBlur} touched={touched} errors={errors} isValid={isValid}></VehicleForm>
                            </Form>
                        )}

                    </Formik>
                </LocalizationProvider>
            </>
        }
    </>
};

export default VehiclePage;
