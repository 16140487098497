import { Grid, Container, Typography, useMediaQuery, Theme } from "@mui/material";
import { t } from "i18next";
import { LandingServicesType } from "types/landingServices";
import LandingSectionContent from "./LandingSectionContent";
import LandingSectionTitle from "./LandingSectionTitle";

interface LandingServicesItemProps {
    item: LandingServicesType,
}


const LandingServicesItem = (props: LandingServicesItemProps) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

    const getContent = () => {
        return <Grid item xs={12} md={6}>
            <Container sx={{
                paddingLeft: {
                    xs: "10px",
                    md: "50px"
                },
                paddingRight: {
                    xs: "10px",
                    md: "50px"
                },
                paddingTop: {
                    xs: "20px"
                },
            }}>
                <LandingSectionTitle>
                    {t(props.item.title)}
                </LandingSectionTitle>
                <Typography
                    sx={{
                        color: "#9EC7CF",
                        fontSize: "22px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "28px",
                    }}
                >
                    {t(props.item.subTitle)}
                </Typography>
                <LandingSectionContent htmlContent={t(props.item.content)} />
            </Container>
        </Grid>
    }

    const getImg = () => {
        return <Grid item xs={12} md={6} sx={{
        }} >
            <Container sx={{
                backgroundColor: "#E6F2F4",
                borderRadius: "25px",
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
            }}>
                <img style={{ maxWidth: "100%" }} src={`/assets/img/landing/${props.item.image}`} alt="service_pic"></img>
            </Container>
        </Grid>
    }

    return <Grid container spacing={2} sx={{
        marginTop: "50px"
    }}>
        {
            isMd ?
                props.item.position === "Left" ? <>
                    {getContent()}
                    {getImg()}
                </>
                    :
                    <>
                        {getImg()}
                        {getContent()}
                    </>
                : <> {getContent()}
                    {getImg()}</>
        }

    </Grid>

}

export default LandingServicesItem;