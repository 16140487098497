import axios from "axios";

export const formatTimestamp = (date: Date | string): string => {
  return new Intl.DateTimeFormat('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  }).format(typeof date === 'string'
    ? new Date(date)
    : date).replace(',', '');
};

export const formatFileName = (fileName: string): string => {
  return fileName.split('_-_-_')[1];
}


export const downloadCsv = (csv: any, reportName: string) => {
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${reportName}.csv`;
  link.click();
  URL.revokeObjectURL(url);
}


export const getToolStatusColor = (status: string | undefined): string => {
  switch (status) {
    case "available": return "green";
    case "inUse": return "orange";
    case "incidentOpen": return "red";
    case "retired": return "grey";
  }
  return "";
}


export const downloadQRCode = async (userId: string, token: string, userName:string, wallpaper: boolean

) => {
  const userApi = process.env.REACT_APP_USER_API_URL || '';
  const response = await axios.get(`${userApi}/${userId}/qr/${wallpaper}`,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    }
  );
  downloadBase64Image(`data:image/png;base64,${response.data.qr}`, `${userName}.png`);
}

export const downloadBase64Image = (base64String: string, fileName: string = "qr.png") => {
  const link = document.createElement("a");
  link.href = base64String;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};