import * as React from 'react';
import {
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';
import { UseModalReturn } from 'lib/hooks/useModal';

interface Props {
  title: string;
  children: React.ReactNode;
  open: UseModalReturn['open'];
  handleClose: UseModalReturn['handleClose'];
  actions?: React.ReactNode;
}

const Dialog = (props: Props): React.ReactElement<Props> => {
  const {
    children,
    handleClose,
    open,
    title,
  } = props;

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      {children}
    </MuiDialog>
  );
};

export default Dialog;