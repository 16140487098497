import { Box, Container, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Splash from 'components/Splash';
import { useIncidents, useTools } from 'api/hooks';
import IncidentsItem from 'components/IncidentsItem';

const IncidentsPageLayout = ({ children }: { children: React.ReactElement }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('Incidents.Title')}</h1>
      <h4>{t('Incidents.SubTitle')}</h4>
      <Divider />
      <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
        <Box sx={{ width: '100%' }}>
          {children}
        </Box>
      </Container>
    </>
  );
};

const IncidentsPage = () => {
    const { data: incidents, isLoading: isLoadingIncidents } = useIncidents();
    const { data: tools, isLoading: isLoadingTools } = useTools();
    const isLoading = isLoadingIncidents || isLoadingTools;

    if (isLoading || !incidents || !tools) {
      return (
        <IncidentsPageLayout>
          <Splash />
        </IncidentsPageLayout>
      );
    }


    return (
      <IncidentsPageLayout>
        <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: '20px' }}>
          {incidents.map((incident) => (
            <IncidentsItem
              incident={incident}
              key={incident.id}
              tool={tools.find((tool) => tool.id === incident.toolId)}
            />
          ))}
        </Grid>
      </IncidentsPageLayout>
    );
}

export default IncidentsPage;