import { createBrowserRouter } from 'react-router-dom';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import AccountPage from './pages/AccountPage';
import LockersPage from './pages/LockersPage';
import BookingsPage from './pages/BookingsPage';
import BookingPage from './pages/BookingPage';
import UserBookingAccessPage from './pages/UserBookingAccessPage';
import UserBookingManagement from './pages/UserBookingManagement';
import VehiclesPage from './pages/VehiclesPage';
import VehiclePage from './pages/VehiclePage';
import IncidentsPage from './pages/IncidentsPage';
import ToolsPage from './pages/ToolsPage';
import ToolPage from './pages/ToolPage/ToolPage';
import UserManagementPage from "./pages/UserManagement";
import UserPage from "./pages/UserPage"; import LoginPage from './pages/authentication/LoginPage';
import SignupPage from './pages/authentication/SignupPage';
import PrivateRoute from './routes/PrivateRoutes';
import CreateIncidentPage from 'pages/CreateIncidentPage';
import IncidentPage from 'pages/IncidentPage';
import ReportsPage from 'pages/Reports/ReportsPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/sign-in",
    element: <LoginPage />,
  },
  {
    path: "/sign-up",
    element: <SignupPage />,
  },
  {
    path: "/dashboard",
    element: <PrivateRoute />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/account",
        element: <AccountPage />
      },
      {
        path: "/dashboard/lockers",
        element: <LockersPage />
      },
      {
        path: "/dashboard/bookings",
        element: <BookingsPage />
      },
      {
        path: "/dashboard/booking",
        element: <BookingPage />
      },
      {
        path: "/dashboard/booking/:bookingId",
        element: <BookingPage />,
      },
      {
        path: '/dashboard/vehicles',
        element: <VehiclesPage />
      },
      {
        path: '/dashboard/vehicle',
        element: <VehiclePage />
      },
      {
        path: '/dashboard/incidents',
        element: <IncidentsPage />
      },
      {
        path: '/dashboard/incident/:incidentId',
        element: <IncidentPage />
      },
      {
        path: '/dashboard/vehicle/:vehicleId',
        element: <VehiclePage />
      },
      {
        path: '/dashboard/tools',
        element: <ToolsPage />
      },
      {
        path: '/dashboard/tool',
        element: <ToolPage />
      },
      {
        path: '/dashboard/tool/:toolId',
        element: <ToolPage />
      },
      {
        path: '/dashboard/users',
        element: <UserManagementPage />
      },
      {
        path: '/dashboard/user',
        element: <UserPage />
      },
      {
        path: '/dashboard/user/:code/:userId',
        element: <UserPage />
      },
      {
        path: '/dashboard/reports',
        element: <ReportsPage />
      }
    ]
  },
  {
    path: "/customer/:accountId",
    element: <UserBookingAccessPage />,
  },
  {
    path: "/customer/booking/:accountId",
    element: <UserBookingAccessPage />,
  },
  {
    path: '/customer/booking/management',
    element: <UserBookingManagement />
  },
  {
    path: '/incident',
    element: <CreateIncidentPage />
  }
]);

export default router;