import { UseQueryResult } from '@tanstack/react-query';
import { UserAPIResponse } from 'api/types';
import { useUsers } from './useUsers';

export const useUserById = (
  userId: string,
): UseQueryResult<UserAPIResponse | undefined, Error> => {
  const { data: users, ...rest } = useUsers();
  const user = users?.find((user) => user.id === userId);

  // @ts-expect-error: default refetch type is not compatible with this return type
  return {
    ...rest,
    data: user,
  };
};