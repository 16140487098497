import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StepEnd = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{
        color: '#5C5C5C',
        fontSize: '1.7rem',
        lineHeight: '2.5rem',
        textTransform: 'uppercase',
        textAlign: 'center',
        marginTop: '15rem',
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              dangerouslySetInnerHTML={{ __html: t('CreateIncidentPage.StepEnd.Title') }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default StepEnd;