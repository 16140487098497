import { AccountType } from "../types/account";
import { BookingType } from "../types/booking";
import { LockerType } from "../types/locker";
import CheckOutVehicleReview from "./customer-pages/CheckOutVehicleReview";
import { useSelector } from "react-redux";
import { selectCheckoutBooking, selectCheckoutStatus, selectCheckoutStep } from "../slices/checkoutSlice";
import Splash from "./Splash";
import CheckoutSignature from "./customer-pages/CheckoutSignature";
import CheckoutFinished from "./customer-pages/CheckoutFinished";
import CheckoutLocker from "./customer-pages/CheckoutLocker";

type CheckOutProps = {
    company: AccountType;
    booking: BookingType;
    locker: LockerType;
}



const CheckOut = (props: CheckOutProps) => {
    const { company, locker } = props;
    const status = useSelector(selectCheckoutStatus);
    const step = useSelector(selectCheckoutStep);
    const checkoutBooking= useSelector(selectCheckoutBooking);
    
    return <>
        {status === "loading" || !checkoutBooking ? <Splash></Splash> : <></>}
        {step === "initial" ? <CheckOutVehicleReview></CheckOutVehicleReview>
            : step === "signature" ? <CheckoutSignature></CheckoutSignature> :
            step === "locker" ? <CheckoutLocker locker={locker}></CheckoutLocker> : <CheckoutFinished company={company}></CheckoutFinished>
        }
    </>
}

export default CheckOut;