import { Typography } from "@mui/material";
import { ReactNode } from "react";

type TitleProp = {
    children: ReactNode
}


const InformativeText =  (props: TitleProp) => {
    const { children } = props;
    return <Typography color="#828282" fontSize={24} fontStyle={"normal"} fontWeight={400} lineHeight={"160%"} >{children}</Typography>
}

export default InformativeText;