// src/features/locker/lockerSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { AccountType } from '../types/account';
import { BookingType, ReturnInfoType } from '../types/booking';
import { CheckInRequestType } from '../types/checkInRequest';
import { VehicleType } from '../types/vehicle';

const ACCOUNT_BASE_URL = process.env.REACT_APP_ACCOUNT_API_URL || '';
const BOOKING_BASE_URL = process.env.REACT_APP_BOOKINGS_API_URL || '';

// Async thunks for async operations
export const fetchCompanyAccount = createAsyncThunk(
    'customerBooking/fetchCompanyAccount',
    async ({ accountId }: { accountId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${ACCOUNT_BASE_URL}/booking/${accountId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchCustomerBooking = createAsyncThunk(
    'customerBooking/fetchBooking',
    async ({ accountId, bookingNumber, surname }: { accountId: string, bookingNumber: string, surname: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BOOKING_BASE_URL}/${bookingNumber}/account/${accountId}/customer/${surname}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateReturnInfo = createAsyncThunk(
    'customerBooking/updateReturnInfo',
    async ({ bookingId, returnInfo, code }: { bookingId: string, returnInfo: ReturnInfoType, code: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BOOKING_BASE_URL}/${bookingId}/code/${code}`, returnInfo,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const checkIn = createAsyncThunk(
    'customerBooking/checkIn',
    async ({ bookingId, checkInRequest }: { bookingId: string, checkInRequest: CheckInRequestType }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BOOKING_BASE_URL}/${bookingId}/checkIn`, checkInRequest,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const completeReturn = createAsyncThunk(
    'customerBooking/completeReturn',
    async ({ bookingId, code }: { bookingId: string, code: string }, { rejectWithValue }) => {
        try {
            const response = await axios.patch(`${BOOKING_BASE_URL}/${bookingId}/code/${code}/completed`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const customerBookingState = (state: RootState) => state.customerBooking;

export const selectCustomerBookingCompany = createSelector(
    customerBookingState,
    (customerBookingState) => customerBookingState.companyData
);


export const selectCustomerBookingVehicle = createSelector(
    customerBookingState,
    (customerBookingState) => customerBookingState.vehicle
);


export const selectCustomerBooking = createSelector(
    customerBookingState,
    (customerBookingState) => customerBookingState.booking
);

export const selectCustomerBookingBookingStatus = createSelector(
    customerBookingState,
    (customerBookingState) => customerBookingState.bookingStatus
);

export const selectCustomerBookingStatus = createSelector(
    customerBookingState,
    (customerBookingState) => customerBookingState.status
);


export const selectCheckInStatus = createSelector(
    customerBookingState,
    (customerBookingState) => customerBookingState.checkInStatus
);

const customerBookingSlice = createSlice({
    name: 'customerBooking',
    initialState: {
        booking: null as BookingType | null,
        companyData: null as AccountType| null,
        vehicle: null as VehicleType | null,
        status: 'idle',
        bookingStatus: 'idle',
        checkInStatus: 'idle',
        error: "",
    },
    reducers: {
        // Your synchronous reducers here (if needed)
    },
    extraReducers: (builder) => {
        builder
            //GetCompany
            .addCase(fetchCompanyAccount.fulfilled, (state, action) => {
                state.companyData = action.payload;
            })
            .addCase(fetchCustomerBooking.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomerBooking.fulfilled, (state, action) => {
                state.booking = action.payload.booking;
                state.vehicle = action.payload.vehicle;
                state.status = 'succeeded';
            })
            .addCase(fetchCustomerBooking.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateReturnInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateReturnInfo.fulfilled, (state, action) => {
                if (state.booking) {
                    state.booking.returnInfo = action.payload;
                }
                state.status = 'succeeded';
            })
            .addCase(updateReturnInfo.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(completeReturn.pending, (state) => {
                state.bookingStatus = 'loading';
            })
            .addCase(completeReturn.fulfilled, (state) => {
                state.bookingStatus = 'succeeded';
            })
            .addCase(completeReturn.rejected, (state) => {
                state.bookingStatus = 'failed';
            })
            .addCase(checkIn.pending, (state) => {
                state.checkInStatus = 'loading';
            })
            .addCase(checkIn.fulfilled, (state) => {
                state.checkInStatus = 'succeeded';
            })
            .addCase(checkIn.rejected, (state) => {
                state.checkInStatus = 'failed';
            });
    }
});

export default customerBookingSlice.reducer;