import * as React from "react";
import { FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Select } from "@mui/material";
import { useIncidentTypes } from 'api/hooks';

import { StepProps, StepFormProps } from "./types";
import Buttom from "components/CustomButton";
import { CutomerInput } from "components/customer-pages/atoms/input";

const Step1 = (props: StepProps & StepFormProps) => {
  const {
    advance,
    tool,
    values,
    handleChange,
    handleBlur,
    isNextEnabled,
    touched,
    errors,
  } = props;
  const { t } = useTranslation();
  const incidentTypes = useIncidentTypes().data || []; 
  const handleClick = React.useCallback(async () => {
    if (!isNextEnabled) {
      return;
    }

    const validationErrors = await props.validateForm();
    if (Object.keys(validationErrors).length === 0) {
      advance();
    }
  }, [advance, props, isNextEnabled]);

  return (
    <>
      <p>{tool.description}</p>
      <Grid container spacing={2} >
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="type">
            {t('CreateIncidentPage.Step1.Input.Type')}
          </InputLabel>
          <Select
            fullWidth
            labelId="select-type"
            variant="outlined"
            id="select-type"
            name="type"
            value={values.type}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.type && Boolean(errors.type)}
            label={t('IncidentPage.Input.Type')}
          >
            <MenuItem value="" disabled>
              <em />
            </MenuItem>
            {incidentTypes.map(({ id, label }) => (
              <MenuItem key={id} value={id}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
          <CutomerInput
            fullWidth
            variant="outlined"
            id="description"
            name="description"
            label={t('CreateIncidentPage.Step1.Input.Description')}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.description && errors.description ? errors.description : ''}
            error={touched.description && Boolean(errors.description)}
            rows={4}
            multiline
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Buttom
          className="mt-3"
          appearance="blueVariant"
          fullWidth
          onClick={handleClick}
          disabled={!isNextEnabled}
          sx={{ opacity: !isNextEnabled ? '0.6' : undefined }}
          >
            {t('CreateIncidentPage.Step1.Next')}
          </Buttom>
        </Grid>
      </Grid>
    </>
  );
}
export default Step1;