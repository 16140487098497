import { Box, Grid } from "@mui/material";
import { selectBookingById } from "../slices/bookingsSlice";
import { useSelector } from "react-redux";

type BookingLockerItemProps = {
    bookingId: string;
}

const BookingLockerItem = (props: BookingLockerItemProps) => {
    const { bookingId } = props;

    const booking = useSelector((state) => selectBookingById(state, bookingId));

    return <Box sx={{ mt: 3 }}>

        <Grid item xs={12}>
            {
                booking ? 
                    <h3>Reserva: {booking?.bookingInfo.bookingNumber}</h3>    
                : <>&nbsp;</>
            }
        </Grid>

    </Box>;
}

export default BookingLockerItem;