import { useSelector } from 'react-redux';
import { Container, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectTools } from 'slices/toolsSlice';
import { ToolType } from 'types/tool';
import ToolItem from 'components/ToolItem';
import SectionTitle from 'components/SectionTitle';

const ToolsInUseWidget = () => {
  const { t } = useTranslation();
  const tools: ToolType[] = useSelector(selectTools);
  
  return (
    <>
      <SectionTitle>{t('ToolsInUseWidget.Title')}</SectionTitle>
      <Divider />
      <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
        <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: "20px" }}>
          {tools.map((tool: ToolType) =>
            tool.assignedTo ? (
              <ToolItem
                displayEditButton={false}
                key={tool.id}
                tool={tool}
              />
            ) : <></>
          )}
        </Grid>
      </Container>
    </>
  );
}
export default ToolsInUseWidget;