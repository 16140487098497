import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { defaultLang as fallbackLng } from './config';
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import translationEN from "./locales/en.json";
import translationES from "./locales/es.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  }
};


i18next.use(initReactI18next).use(LanguageDetector).init({
  resources,
  lng: "es", // Force Spanish as the language
  detection: {
    order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
    caches: ["cookie"],
  },
  fallbackLng,
  interpolation: {
    escapeValue: false,
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
