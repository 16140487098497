import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { updateIncident } from 'api/incidents';
import { useAuth } from 'context/AuthContext';
import { Incident } from 'api/types';

interface UpdateIncidentVariables {
  body: Incident;
}

export const useUpdateIncident = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}) => {
  const { token } = useAuth();

  return useMutation<Incident, AxiosError, UpdateIncidentVariables>({
    mutationFn: async ({ body }: UpdateIncidentVariables) => {
      return updateIncident({ token, body });
    },
    onSuccess,
    onError,
  });
};