import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/AuthContext';
import { useEffect } from 'react';


interface LinkMenu {
  displayName: string,
  path: string,
  onlyRoot?: boolean
  roles?: string[]
}

const pages: Array<LinkMenu> = [
  { displayName: "NavBar.Account", path: "account", onlyRoot: true },
  { displayName: "NavBar.Lockers", path: "lockers",onlyRoot: true },
  // { displayName: "NavBar.Bookings", path: "bookings" },
  // { displayName: "NavBar.Vehicles", path: "vehicles" },
  { displayName: "NavBar.Tools", path: "tools",onlyRoot: true },
  { displayName: "NavBar.Users", path: "users",onlyRoot: true },
  { displayName: "NavBar.Reports", path: "reports",onlyRoot: true },
  { displayName: "NavBar.Incidents", path: "incidents",onlyRoot: true },
];
const DashboardNavbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [filterdPages, setFilteredPages] = React.useState<Array<LinkMenu>>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutAndRedirect = () => {
    logout();
    navigate("/");
  }

  useEffect(() => {
    const fPages:LinkMenu[] = []

    pages.forEach(page => {
      if (page.onlyRoot && user.isAdmin)
        fPages.push(page);
      if (!page.onlyRoot && !page.roles)
        fPages.push(page);
    });
    setFilteredPages(fPages);
  },[user]);

  return (
    <AppBar position="fixed">
      <Container maxWidth="lg">
        <Toolbar disableGutters>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <NavLink to={"/dashboard"}>
              <img
                src="/smartworkshop-logo.png"
                alt="Logo"
                style={{ marginRight: 16, height: 40 }}
              />
            </NavLink>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {filterdPages.map((page: LinkMenu) => {
                return page.onlyRoot && !user.isAdmin ? <></> : <NavLink style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }} onClick={handleCloseNavMenu} to={page.path} key={page.displayName}>
                  <MenuItem key={t(page.displayName)} >
                    <Typography textAlign="center">{t(page.displayName)}</Typography>
                  </MenuItem>
                </NavLink>

              })}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <NavLink to={"/dashboard"}>
              <img
                src="/smartworkshop-logo.png"
                alt="Logo"
                style={{ marginRight: 16, height: 40 }}
              />
            </NavLink>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {filterdPages.map((page: LinkMenu) => {
              return <NavLink style={{ textDecoration: "none", }} onClick={handleCloseNavMenu} to={page.path} key={page.displayName}>
                <Button
                  key={t(page.displayName)}
                  sx={{ my: 2, color: 'white', display: 'block', paddingTop: "0px", paddingBottom: "0px" }}
                >
                  {t(page.displayName)}
                </Button>
              </NavLink>
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" sx={{ backgroundColor: "EAFC00" }} src={""} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="logout" onClick={logoutAndRedirect}>
                <Typography textAlign="center">{t("Logout")}</Typography>
              </MenuItem>
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default DashboardNavbar;