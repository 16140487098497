import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { Box, Button, Container, CssBaseline, Divider, Grid, TextField } from "@mui/material";
import { fetchAccount, selectAccount, selectAccountStatus, updateAccount } from "../slices/accountSlice";
import { Formik, Form } from "formik";
import { AccountType } from "../types/account";
import * as Yup from 'yup';
import Splash from "../components/Splash";
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";

const AccountPage = () => {
    const account = useSelector(selectAccount);
    const status = useSelector(selectAccountStatus);
    const dispatch: AppDispatch = useDispatch();
    const { token, user } = useAuth();

    useEffect(() => {
        dispatch(fetchAccount({ token, user }));
    }, [dispatch, token, user])

    const initialValues: AccountType = {
        id: '',
        companyName: account?.companyName || '',
        companyIdentifier: account?.companyIdentifier || '',
        phoneNumber: account?.phoneNumber || '',
        email: account?.email || '',
        address: account?.address || {
            street: '',
            city: '',
            state: '',
            postCode: ''
        },
    };

    const validationSchema = Yup.object({
        companyName: Yup.string().required('Nombre de empresa requerido'),
        companyNumber: Yup.string().required('CIF requerido'),
        phone: Yup.string().required('Telefono requerido'),
        email: Yup.string().email('Invalid email format').nullable(),
        address: Yup.object({
            street: Yup.string().required('Calle requerida'),
            city: Yup.string().required('Ciudad requerida'),
            state: Yup.string().required('Provincia requerida'),
            postCode: Yup.string().required('Codigo postal requerido')
        }).required('Direccion requerida'),
    });

    const onSubmit = (account: AccountType) => {
        dispatch(updateAccount({ account, token }));
    };

    return <>
        {!account || !account.email ? <Splash></Splash> : <>
            {status === "loading" ? <Splash></Splash> : <></>}
            <h1>Cuenta</h1>
            <Divider />
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ handleChange, handleBlur, values, touched, errors }) => (

                            <Form>
                                <Box sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <h3>Información</h3>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ pointerEvents: "none" }}
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="companyName"
                                                name="companyName"
                                                label="Nombre Empresa"
                                                value={values.companyName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.companyName && errors.companyName ? errors.companyName : ''}
                                                error={touched.companyName && Boolean(errors.companyName)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="companyNumber"
                                                name="companyNumber"
                                                label="CIF"
                                                value={values.companyIdentifier}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.companyIdentifier && errors.companyIdentifier ? errors.companyIdentifier : ''}
                                                error={touched.companyIdentifier && Boolean(errors.companyIdentifier)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                label="Telefono"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                                                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                            />
                                        </Grid>
                                        <h3>Dirección</h3>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="address.street"
                                                name="address.street"
                                                label="Calle"
                                                value={values.address?.street}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.address?.street && errors.address?.street ? errors.address.street : ''}
                                                error={touched.address?.street && Boolean(errors.address?.street)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="address.city"
                                                name="address.city"
                                                label="Ciudad"
                                                value={values.address?.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.address?.city && errors.address?.city ? errors.address.city : ''}
                                                error={touched.address?.city && Boolean(errors.address?.city)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="address.postCode"
                                                name="address.postCode"
                                                label="Codigo postal"
                                                value={values.address?.postCode}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.address?.postCode && errors.address?.postCode ? errors.address.postCode : ''}
                                                error={touched.address?.postCode && Boolean(errors.address?.postCode)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                id="address.state"
                                                name="address.state"
                                                label="Provincia"
                                                value={values.address?.state}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.address?.state && errors.address?.state ? errors.address.state : ''}
                                                error={touched.address?.state && Boolean(errors.address?.state)}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <h3>QR Reservas Alquiler</h3>
                                            <a href={`https://nice-sea-00e72e203.5.azurestaticapps.net/customer/${account.id}`}>Futuro QR</a>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6}>
                                            <h3>QR Taller</h3>
                                        </Grid> */}
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        // disabled={!isValid}
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Guardar
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </>
        }
    </>
}

export default AccountPage;