import { AccountType } from "../types/account";
import { BookingType } from "../types/booking";
import { LockerType } from "../types/locker";
import { useEffect, useState } from "react";
import { selectCheckInStatus } from "../slices/customerBookingSlice";
import { useSelector } from "react-redux";
import CheckInSignature from "./customer-pages/CheckInSignature";
import CheckInLocker from "./customer-pages/CheckInLocker";
import CheckInFinished from "./customer-pages/CheckInFinished";
import { VehicleType } from "../types/vehicle";


type CheckInProps = {
    company: AccountType;
    booking: BookingType;
    locker: LockerType;
    vehicle: VehicleType;
}

const CheckIn = (props: CheckInProps) => {

    const { company, booking, locker, vehicle } = props;
    const checkInStatus = useSelector(selectCheckInStatus);
    const [step, setStep] = useState("Initial");


    useEffect(() => {
        if (checkInStatus === "succeeded") {
            setStep("PendingPickup");
        };
    }, [checkInStatus])

    return <>
        {step === "Initial" ? <>
            <CheckInSignature booking={booking} company={company} vehicle={vehicle}></CheckInSignature>
        </> :
            step === "PendingPickup" ?
                <>
                    <CheckInLocker locker={locker} setStep={setStep}></CheckInLocker>
                </> : <>
                    <CheckInFinished company={company}></CheckInFinished>
                </>
        }
    </>
}

export default CheckIn;
