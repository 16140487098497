import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, Typography, TextField } from '@mui/material';
import { CreateAccountRequestType } from '../../types/createAccountRequest';
import Splash from '../../components/Splash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Validation schema using Yup
const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Required'),
  phoneNumber: Yup.string().required('Required'),
  companyName: Yup.string().required('Required'),
  address: Yup.object({
    street: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postCode: Yup.string().required('Required'),
  }),
  companyIdentifier: Yup.string().required('Required'),
  language: Yup.string(),
});

const ACCOUNT_BASE_URL = process.env.REACT_APP_ACCOUNT_API_URL || '';

const SignupPage =  () => {
  const { i18n } = useTranslation();  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();


  const handleSubmit = async (
    values: CreateAccountRequestType,
    { setSubmitting }: FormikHelpers<CreateAccountRequestType>
  ) => {
    
      setIsLoading(true);
      setSubmitting(false);
      await axios.post(`${ACCOUNT_BASE_URL}`, values);
      navigate('/login')
  
  };

  const initialValues: CreateAccountRequestType = {
    email: '',
    password: '',
    phoneNumber: '',
    companyName: '',
    address: {
      street: '',
      city: '',
      state: '',
      postCode: '',
    },
    companyIdentifier: '',
    language: i18n.language
  }

  return (<>
    {isLoading ? <Splash></Splash> : <></>}
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <Grid
            container xs={12} md={4}
            margin="auto"
            alignItems="center"
          >
            <Typography variant="h4" align="center" gutterBottom>
              Sign Up
            </Typography>

            <Grid container spacing={2} >
              <Grid item xs={12}  >
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="password"
                  type="password"
                  label="Password"
                  fullWidth
                  variant="outlined"
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
              </Grid>


              <Grid item xs={12} >
                <Typography variant="subtitle1" gutterBottom>
                  Company info
                </Typography>
              </Grid>

              <Grid item xs={12} >
                <Field
                  as={TextField}
                  name="companyName"
                  label="Company Name"
                  fullWidth
                  variant="outlined"
                  error={touched.companyName && !!errors.companyName}
                  helperText={touched.companyName && errors.companyName}
                />
              </Grid>

              <Grid item xs={12} >
                <Field
                  as={TextField}
                  name="phoneNumber"
                  label="Phone Number"
                  fullWidth
                  variant="outlined"
                  error={touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Grid>

              <Grid item xs={12} >
                <Field
                  as={TextField}
                  name="companyIdentifier"
                  label="Company Identifier"
                  fullWidth
                  variant="outlined"
                  error={touched.companyIdentifier && !!errors.companyIdentifier}
                  helperText={touched.companyIdentifier && errors.companyIdentifier}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Address
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="address.street"
                  label="Street"
                  fullWidth
                  variant="outlined"
                  error={touched.address?.street && !!errors.address?.street}
                  helperText={touched.address?.street && errors.address?.street}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="address.city"
                  label="City"
                  fullWidth
                  variant="outlined"
                  error={touched.address?.city && !!errors.address?.city}
                  helperText={touched.address?.city && errors.address?.city}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="address.state"
                  label="State"
                  fullWidth
                  variant="outlined"
                  error={touched.address?.state && !!errors.address?.state}
                  helperText={touched.address?.state && errors.address?.state}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="address.postCode"
                  label="Postal Code"
                  fullWidth
                  variant="outlined"
                  error={touched.address?.postCode && !!errors.address?.postCode}
                  helperText={touched.address?.postCode && errors.address?.postCode}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </>
  );
};

export default SignupPage;
