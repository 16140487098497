import * as React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';

import { UseModalReturn } from 'lib/hooks/useModal';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import MainButton from 'components/Button';
import { Incident } from 'api/types';
import { useUpdateIncident } from 'api/hooks';
import { useAuth } from 'context/AuthContext';

type Props = UseModalReturn & {
  incident: Incident;
};

interface FormikValues {
  observations: string;
};

const cfg = {
  validationSchema: (t: TFunction<'translation', undefined>) => Yup.object().shape({
    observations: Yup.string()
      .required(t('IncidentPage.Input.Observations.required')),
  }),
  successTimeout: 3 * 1000,
};

const IncidentObservationsAddModal = (props: Props): React.ReactElement<Props> => {
  const { incident, ...rest } = props;
  const [success, setSuccess] = React.useState<boolean>(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const { mutate: doPost, status, error } = useUpdateIncident({
    onSuccess: () => {
      setSuccess(true);
    },
    onError: ({ response }) => {
      console.error('Registration error', response?.data);
    },
  });

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        props.handleClose();
        setSuccess(false);
      }, cfg.successTimeout);
    }
  }, [success, props]);

  return (
    <Dialog
      title={t('IncidentPage.AddObservation.Title')}
      {...rest}
      >
      {success && <Alert severity="success">{t('IncidentPage.AddObservation.Alert.Success')}</Alert>}
      {error && <Alert severity="error">{t(`IncidentPage.AddObservation.Alert.${error.code}`)}</Alert>}
      <Formik<FormikValues>
        initialValues={{
          observations: '',
        }}
        validationSchema={cfg.validationSchema(t)}
        onSubmit={({ observations: detail }) => {
          const body = {
            ...incident,
            observations: [
              ...(incident.observations || []),
              {
                userId: user.id,
                timestamp: new Date().toISOString(),
                detail,
              },
            ],
          } as Incident;
          doPost({ body });
        }}
        >
          {({ values, handleChange, handleSubmit, errors, isValid }) => (
            <>
            <DialogContent>
              <TextField
                margin="normal"
                autoFocus
                fullWidth
                id="observations"
                name="observations"
                label={t("IncidentPage.AddObservation.Observations")}
                value={values.observations}
                onChange={handleChange}
                error={errors?.observations !== undefined}
                helperText={errors?.observations}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                multiline
                rows={4}
                disabled={status === 'pending'}
              />
            </DialogContent>
            <DialogActions>
              <MainButton variantType="secondary" onClick={props.handleClose}>
                {t('IncidentPage.AddObservation.Cancel')}
              </MainButton>
              <Button
                variantType="primary"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={!isValid || status === 'pending'}
              >
                {t('IncidentPage.AddObservation.Submit')}
              </Button>
            </DialogActions>
          </>
          )}
        </Formik>
    </Dialog>
  );
};

export default IncidentObservationsAddModal;