import { ReactNode } from "react";

type TitleProp = {
    children: ReactNode,
    paddingTop: string,

}


const IconContainer = (props: TitleProp) => {
    const { children, paddingTop } = props;

    return <div style={{textAlign:"center", paddingTop: paddingTop,  borderRadius: "10px", width: "60px", height: "60px",  backgroundColor:"#B2D5DC", color:"#EAFC00"}}>
        {children}
    </div>;
}

export default IconContainer