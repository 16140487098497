import LandingNavBar from "pages/Landing/LandingNavbar"
import LandingMainSection from "./LandingMainSection";
import LandingClientSection from "./LandingClientSection";
import LandingTransformationSection from "./LandingTransformationSection";
import LandingValuesSection from "./LandingValuesSection";
import LandingServicesSection from "./LandingServicesSection";
import LandingFooter from "./LandingFooter";
import LandingReviewSection from "./LandingReviewsSection";

const Landing = () => {

    return <>
        <LandingNavBar></LandingNavBar>
        <LandingMainSection></LandingMainSection>
        <LandingClientSection></LandingClientSection>
        <LandingTransformationSection></LandingTransformationSection>
        <LandingValuesSection></LandingValuesSection>
        <LandingServicesSection></LandingServicesSection>
        <LandingReviewSection></LandingReviewSection>
        <LandingFooter></LandingFooter>
    </>
}

export default Landing