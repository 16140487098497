import { useFormikContext } from "formik";
import { BookingType } from "../types/booking";
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { VehicleType } from "../types/vehicle";
type BookingFormProps = {
    values: BookingType;
    vehicles: VehicleType[];
    handleChange: any;
    handleBlur: any;
    touched: any,
    errors: any,
    isValid: any,

}
const BookingForm = (props: BookingFormProps) => {
    const { values, handleChange, handleBlur, touched, errors, isValid, vehicles } = props;
    const { setFieldValue } = useFormikContext();
    const navigate = useNavigate();

    const handleDateChange = (event: any, field: string) => {
        setFieldValue(field, event.toDate());
    }

    return <Box sx={{ mt: 3 }}>
        <Grid container spacing={2} sx={{ display: 'flex' }}>
            <h3 style={{ width: "100%" }}>Información reserva</h3>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="bookingInfo.bookingNumber"
                    name="bookingInfo.bookingNumber"
                    label="Numero de la reserva"
                    value={values.bookingInfo?.bookingNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.bookingInfo?.bookingNumber && Boolean(errors.bookingInfo?.bookingNumber)}
                    helperText={touched.bookingInfo?.bookingNumber && errors.bookingInfo?.bookingNumber}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    label="Fecha desde"
                    name="bookingInfo.dateFrom"
                    value={moment(values.bookingInfo?.dateFrom)}
                    onChange={(e) => handleDateChange(e, "bookingInfo.dateFrom")}
                    slotProps={
                        {
                            textField: {
                                id: "bookingInfo.dateFrom",
                                fullWidth: true,
                                helperText: touched.bookingInfo?.dateFrom && errors.bookingInfo?.dateFrom,
                                error: touched.bookingInfo?.dateFrom && Boolean(errors.bookingInfo?.dateFrom),
                                required: true
                            },
                        }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    label="Fecha devolución"
                    name="bookingInfo.dateTo"
                    value={moment(values.bookingInfo?.dateTo)}
                    onChange={(e) => handleDateChange(e, "bookingInfo.dateTo")}
                    slotProps={
                        {
                            textField: {
                                id: "bookingInfo.dateTo",
                                fullWidth: true,
                                helperText: touched.bookingInfo?.dateTo && errors.bookingInfo?.dateTo,
                                error: touched.bookingInfo?.dateTo && Boolean(errors.bookingInfo?.dateTo),
                                required: true
                            },
                        }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="bookingInfo.hasFullInsuranceCoverage"
                            name="bookingInfo.hasFullInsuranceCoverage"
                            checked={values.bookingInfo?.hasFullInsuranceCoverage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    }
                    label="Seguro a todo riesgo sin franquicia"
                />
            </Grid>
            <h3 style={{ width: "100%" }}>Datos cliente</h3>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="customer.email"
                    name="customer.email"
                    label="Email"
                    value={values.customer?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.customer?.email && Boolean(errors.customer?.email)}
                    helperText={touched.customer?.email && errors.customer?.email ? errors.customer.email : ''}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    id="customer.name"
                    name="customer.name"
                    label="Nombre"
                    value={values.customer?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.customer?.name && Boolean(errors.customer?.name)}
                    helperText={touched.customer?.name && errors.customer?.name}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    id="customer.surname"
                    name="customer.surname"
                    label="Apellido"
                    required
                    value={values.customer?.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.customer?.surname && Boolean(errors.customer?.surname)}
                    helperText={touched.customer?.surname && errors.customer?.surname}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    id="customer.phone"
                    name="customer.phone"
                    label="Telefono"
                    value={values.customer?.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.customer?.phone && Boolean(errors.customer?.phone)}
                    helperText={touched.customer?.phone && errors.customer?.phone}
                />
            </Grid>

            <h3 style={{ width: "100%" }}>Datos vehiculo</h3>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth error={touched.bookingInfo?.vehicleId && Boolean(errors.bookingInfo?.vehicleId)}>
                    <InputLabel id="bookingInfo.vehicleId.label">
                        Vehiculo <span>*</span>
                    </InputLabel>
                    <Select
                        labelId="bookingInfo.vehicleId.label"
                        id="bookingInfo.vehicleId"
                        name="bookingInfo.vehicleId"
                        label="Vehiculo"
                        required
                        value={values.bookingInfo?.vehicleId ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.bookingInfo?.vehicleId && Boolean(errors.bookingInfo?.vehicleId)}
                    >
                        {vehicles.map((vehicle) => (
                            <MenuItem key={vehicle.id} value={vehicle.id ?? 'vehicle'}>
                                {vehicle.numberPlate}
                            </MenuItem>
                        ))}
                    </Select>
                    {touched.bookingInfo?.vehicleId && errors.bookingInfo?.vehicleId && (
                        <FormHelperText>{errors.bookingInfo?.vehicleId}</FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <Button style={{ backgroundColor: "#EAFC00", color: "#053d57", fontWeight: "bold" }} variant="contained" fullWidth onClick={() => navigate('/dashboard/bookings')} disabled={!isValid}>
                    Volver
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={!isValid}>
                    Guardar
                </Button>
            </Grid>
        </Grid>
    </Box>
}



export default BookingForm;