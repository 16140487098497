import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { type ToolFormProps } from 'components/ToolForm';
import MediaUpload from 'components/customer-pages/atoms/MediaUpload';
import { getDownloadSasUri, getUploadSasUri } from 'api/file';
import { useAuth } from 'context/AuthContext';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { addFile, deleteFile } from 'api/tools';
import { ToolHistoryType } from 'types/tool';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { formatFileName } from 'lib';


const ToolFiles = (props: ToolFormProps): React.ReactElement<ToolFormProps> => {
    const { values, setFieldValue } = props;
    const { t } = useTranslation();
    const { token, user: { accountId } } = useAuth();

    const deleteAttachment = async (data: any) => {
        const response = await deleteFile({ token, fileName: data.fileName, toolId: values.id ?? "" })
        const newHistoryItem = response.history[response.history.length - 1] as ToolHistoryType;
        return new Promise((resolve) => {
            resolve({ newHistoryItem: newHistoryItem, fileName: data.fileName });
        });
    }

    const { mutate: deleteFileMutation } = useMutation({
        mutationFn: deleteAttachment,
        onError: (error) => {
            console.log(error)
        },
        onSuccess: (data: any) => {
            setFieldValue("attachments", values.attachments?.filter(
                (item) => item !== data.fileName // Adjust the condition as needed
            ));
            setFieldValue("history", [...values.history as ToolHistoryType[], data.newHistoryItem]);
        }
    })

    const fileAdded = async (file: any) => {
        const blobName = `${Date.now()}_-_-_${file.name}`;
        const type = file.type;
        const res = await getUploadSasUri({ token, fileName: blobName });
        await axios.put(res.sasUri, file, {
            headers: {
                'Content-Type': type, // Ensure the correct content type
                'x-ms-blob-type': 'BlockBlob'
            },
        });

        const response = await addFile({ accountId, token, fileName: blobName, toolId: values.id as string })
        const newHistoryItem = response.history[response.history.length - 1] as ToolHistoryType;
        return new Promise((resolve) => {
            resolve({ blobName: blobName, newHistoryItem: newHistoryItem });
        });
    }

    const { mutate: doPost, status } = useMutation({
        mutationFn: fileAdded,
        onError: (error) => {
            console.log(error)
        },
        onSuccess: (data: any) => {
            setFieldValue("attachments", [...values.attachments ?? [], data.blobName]);
            setFieldValue("history", [...values.history as ToolHistoryType[], data.newHistoryItem]);
        }
    })



    const download = async (fileName: string) => {
        const sasUri = await getDownloadSasUri({ token, fileName });
        return new Promise((resolve) => {
            resolve({ fileName: fileName, sasUri: sasUri.sasUri });
        });
    }

    const { mutate: downloadFile } = useMutation({
        mutationFn: download,
        onError: (error) => {
            console.log(error)
        },
        onSuccess: (data: any) => {
            const link = document.createElement("a");
            link.href = data.sasUri;
            link.download = data.fileName;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    })

    const onChange = (file: any) => {
        doPost(file)
    }


    return (
        <>
            <Typography variant="h6" sx={{ mb: 1 }}>{t("EditTools.ToolFiles.Title")}</Typography>
            <Grid item xs={12} marginY={2}>
                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.attachments?.map((file: string) => (
                                <TableRow
                                    key={file}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {formatFileName(file)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => downloadFile(file)}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                bgcolor: 'primary.main',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'secondary.dark' }
                                            }}
                                            style={{ width: 30, height: 30, marginRight: 5 }}
                                            aria-label="edit"
                                        >
                                            <DownloadIcon style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => deleteFileMutation({ fileName: file, toolId: values.id as string })}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                bgcolor: 'primary.main',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'secondary.dark' }
                                            }}
                                            style={{ width: 30, height: 30 }}
                                            aria-label="delete"
                                        >
                                            <DeleteIcon style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <MediaUpload inputName='Add document' file={null} onChange={onChange} disabled={status === "pending"}></MediaUpload>
        </>
    );
};

export default ToolFiles;