
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { UserType } from '../types/user';
const BASE_URL = process.env.REACT_APP_USER_API_URL || '';

// Async thunks for async operations
export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async ({ token, user }: { token: string, user: UserType }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BASE_URL}/account/${user.accountId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createUser = createAsyncThunk(
    'users/createUser',
    async ({ user, token }: { user: UserType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(BASE_URL, user,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async ({ user, token }: { user: UserType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${BASE_URL}`, user,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async ({ id, accountId, token }: { id: any, accountId: string, token: string }, { rejectWithValue }) => {
        try {
            await axios.delete(`${BASE_URL}/${id}/account/${accountId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userState = (state: RootState) => state.users;

export const selectUsers = createSelector(
    userState,
    (userState) => userState.users
);

export const selectUsersStatus = createSelector(
    userState,
    (userState) => userState.status
);

export const selectUsersError = createSelector(
    userState,
    (userState) => userState.error
);

export const selectUserById = createSelector(
    [userState, (state, id) => id],
    (userState, id) => {
        return userState.users.find((user: UserType) => user.id === id)
    }
);

export const selectRootUsers = createSelector(
    [userState],
    (userState) => {
        return userState.users.filter((user: UserType) => user.isAdmin)
    }
);

export const selectNonRootUsers = createSelector(
    [userState],
    (userState) => {
        return userState.users.filter((user: UserType) => !user.isAdmin)
    }
);
// export const selectUserById = createSelector(
//     [userState, (state: RootState, id: string) => id],
//     (userState, id) => {
//         return userState.users.find((user: UserType) => user.id === id) ?? userInitialState;
//     }
// );

export const userInitialState: UserType = {
    id: null,
    accountId: "",
    access: undefined,
    name: "",
    userName: "",
    surname: "",
    isDeleted: false,
    isAdmin: false
};


const userSlice = createSlice({
    name: 'Users',
    initialState: {
        users: [] as Array<UserType>,
        status: 'idle',
        error: "",
    },
    reducers: {
        // Your synchronous reducers here (if needed)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state) => {
                state.error = "error";
                state.status = 'failed';
            })
            .addCase(createUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
                state.status = 'succeeded';
            })
            .addCase(createUser.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedItem = action.payload;
                const index = state.users.findIndex(users => users.id === updatedItem.id);
                if (index !== -1) {
                    state.users[index] = updatedItem;
                }
            })
            .addCase(updateUser.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.users.findIndex(users => users.id === action.payload);
                state.users.splice(index, 1);
            })
            .addCase(deleteUser.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export default userSlice.reducer;