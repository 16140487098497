import { Grid, Typography, Box } from "@mui/material";
import { historyActionIcons } from "data/HistoryIcons";
import { t } from "i18next";
import { formatFileName, formatTimestamp } from "lib";
import { useSelector } from "react-redux";
import { selectUsers } from "slices/userSlice";
import { ToolHistoryType } from "types/tool";

interface ToolHistoryProps {
    toolHistory?: Array<ToolHistoryType>
}

const ToolHistory = (props: ToolHistoryProps) => {
    const { toolHistory } = props;
    const users = useSelector(selectUsers)

    const getHistoryCustomItem = (item: ToolHistoryType) => {
        switch (item.action) {
            case 'loan':
                return `${t('Tools.History.LoanTo')} ${item.toolLoan?.name ?? ""} - ${item.toolLoan?.email ?? ""}`;
            case 'fileAdded':
            case 'fileRemoved':
                return `${t('Tools.History.File')} ${formatFileName(item.fileAction?.fileName as string)}`;
        }
        return ''
    }

    const getUserName = (id: string | null | undefined): string => {
        if (!id)
            return ""
        const user = users.filter(u => u.id === id)[0]
        return `${user?.name} ${user?.surname ?? ""}`
    }

    return toolHistory && toolHistory.length > 0 ? <Grid item xs={12}>
        <Typography variant="h6">{t("EditTools.History")}</Typography>
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                {toolHistory.map((historyItem, index) => {
                    const userName = getUserName(historyItem.userId);
                    const formattedDate = formatTimestamp(historyItem.timeStamp);
                    const action = t(`Tools.HistoryStatus.${historyItem.action}`)
                    return (
                        <Grid item xs={12} key={index} sx={{ display: "flex" }}>
                            <div style={{ marginRight: "10px" }}>{historyActionIcons[historyItem.action]}</div>
                            <Typography variant="body2" color="textSecondary">{`${formattedDate}, ${action}, ${userName} ${getHistoryCustomItem(historyItem)}`}  </Typography>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    </Grid>
        : <></>
}

export default ToolHistory;