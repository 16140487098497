import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { StepProps } from "./types";
import Buttom from "components/CustomButton";

const Step0 = ({ advance, tool, isNextEnabled }: StepProps) => {
  const { t } = useTranslation();

  return (
    <>
      <p>{tool.description}</p>
      <Box sx={{
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
        border: 3,
        borderColor: '#053D57',
        p: 2,
        minWidth: 300,
        }}>
        <Grid container spacing={2} >
          <Grid item xs={6}>
            <img src={tool.img as string} alt={tool.name} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">{tool.name}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Buttom
            className="mt-3"
            appearance="blueVariant"
            fullWidth
            disabled={!isNextEnabled}
            sx={{ opacity: !isNextEnabled ? '0.6' : undefined }}
            onClick={advance}>
              {t('CreateIncidentPage.Step0.Next')}
          </Buttom>
        </Grid>
      </Grid>
    </>
  );
}
export default Step0;