import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { fetchBookings, selectBookings, selectBookingsStatus } from "../slices/bookingsSlice";
import Splash from "../components/Splash";
import { Container, Divider, Grid, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import BookingItem from "../components/BookingItem";
import { useAuth } from "../context/AuthContext";

const BookingsPage = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const status = useSelector(selectBookingsStatus);
    const bookings = useSelector(selectBookings);
    const { token, user } = useAuth();

    useEffect(() => {
        dispatch(fetchBookings({ token, user }));
    }, [dispatch, token, user])

    const addBooking = () => {
        navigate('/dashboard/booking');
    }

    // const editBooking = (booking: Booking) => {
    //     navigate(`/dashboard/booking/${booking.id}`);
    // }

    return <>
        {status === "loading" ? <Splash></Splash> : <></>}
        <h1>Reservas de alquiler</h1>
        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam in vestibulum enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vitae nulla pharetra, aliquam turpis et, dictum eros.</h4>
        <Divider />
        <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={addBooking}
                    sx={{
                        width: 56,
                        height: 56,
                        bgcolor: 'primary.main',
                        color: 'white',
                        '&:hover': { bgcolor: 'primary.dark' } // Optional: Change color on hover
                    }}
                    style={{ width: 40, height: 40 }}
                    aria-label="add"
                >
                    <AddIcon />
                </IconButton>
            </div>


            <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: "20px" }}>
                {
                    bookings.map(b => {
                        return <BookingItem booking={b} key={b.id}/>
                        // return <LockerItem key={l.id} locker={l}></LockerItem>
                        // return <IconButton
                        //     onClick={() => editBooking(b)}
                        //     sx={{
                        //         width: 56,
                        //         height: 56,
                        //         bgcolor: 'primary.main',
                        //         color: 'white',
                        //         '&:hover': { bgcolor: 'primary.dark' } // Optional: Change color on hover
                        //     }}
                        //     style={{ width: 40, height: 40 }}
                        //     aria-label="add"
                        // >
                        //     <EditIcon />
                        // </IconButton>
                    })
                }
            </Grid>

        </Container>
    </>
}

export default BookingsPage;