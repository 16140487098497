import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { BookingType } from '../types/booking';
import { AssignLockerRequestType } from '../types/assignLockerRequest';
import { UserType } from '../types/user';

const BASE_URL = process.env.REACT_APP_BOOKINGS_API_URL || '';

// Async thunks for async operations
export const fetchBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async ({ token, user }: { token: string, user: UserType }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/account/${user.accountId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignLocker = createAsyncThunk(
  'bookings/assignLocker',
  async ({ bookingId, assignLockerRequest, token }: { bookingId: string, assignLockerRequest: AssignLockerRequestType, token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/${bookingId}/locker`,
        assignLockerRequest,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBooking = createAsyncThunk(
  'bookings/createbooking',
  async ({ booking,  token, user  }: { booking: BookingType, token: string, user: UserType }, { rejectWithValue }) => {
    booking.accountId = user.accountId;
    booking.id = null;
    booking.lookerInfo = null;
    try {
      const response = await axios.post(BASE_URL, booking,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBooking = createAsyncThunk(
  'bookings/updatebooking',
  async ({ booking,  token }: { booking: BookingType, token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}`, booking,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBooking = createAsyncThunk(
  'bookings/deletebooking',
  async ({ id, token }: { id: string, token: string }, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const bookingState = (state: RootState) => state.bookings;

export const selectBookings = createSelector(
  bookingState,
  (bookingState) => bookingState.bookings
);


export const selectBookingsWithNoAssignedLocker = createSelector(
  bookingState,
  (bookingState) => bookingState.bookings.filter(b => !b.lookerInfo)
);

export const selectBookingsStatus = createSelector(
  bookingState,
  (bookingState) => bookingState.status
);

export const selectBookingsError = createSelector(
  bookingState,
  (bookingState) => bookingState.error
);
export const selectBookingById = createSelector(
  [bookingState, (state, id) => id],
  (bookingsState, id) => {
    return bookingsState.bookings.find((booking) => booking.id === id);
  }
);




export const bookingInitialState: BookingType = {
  id: "",
  accountId: "",
  status: "",
  customer: {
    email: "",
    phone: "",
    name: "",
    surname: "",
  },
  bookingInfo: {
    bookingNumber: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    hasFullInsuranceCoverage: false,
    vehicleId: ""
  },
  lookerInfo: "",
};


const bookingSlice = createSlice({
  name: 'bookings',
  initialState: {
    bookings: [] as Array<BookingType>,
    status: 'idle',
    error: "",
  },
  reducers: {
    // Your synchronous reducers here (if needed)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings = action.payload;
      })
      .addCase(fetchBookings.rejected, (state) => {
        state.error = "error";
        state.status = 'failed';
      })
      .addCase(createBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.bookings.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createBooking.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedItem = action.payload;
        const index = state.bookings.findIndex(booking => booking.id === updatedItem.id);
        if (index !== -1) {
          state.bookings[index] = updatedItem;
        }
      })
      .addCase(assignLocker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedItem = action.payload;
        const index = state.bookings.findIndex(booking => booking.id === updatedItem.id);
        if (index !== -1) {
          state.bookings[index] = updatedItem;
        }
      })

      .addCase(updateBooking.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.bookings.findIndex(booking => booking.id === action.payload);
        state.bookings.splice(index, 1);
      })
      .addCase(deleteBooking.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export default bookingSlice.reducer;