import { Grid, Paper, Card, CardContent, Chip, Typography, IconButton } from "@mui/material";
import { VehicleType } from "../types/vehicle";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type VehicleItemProps = {
    vehicle: VehicleType;
}

const VehicleItem = (props: VehicleItemProps) => {
    const { vehicle } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const editVehicle = (vehicleId: string) => {
        navigate(`/dashboard/vehicle/${vehicleId}`);
    }

    return <Grid item>
        <Paper elevation={3} style={{ maxWidth: "350px" }}>
            <Card>
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 style={{
                            marginBlockStart: 0,
                            marginBlockEnd: 0,
                            marginTop: "3px"
                        }}>
                            #{vehicle.numberPlate}
                        </h3>
                        <Chip sx={{
                            bgcolor: 'green',
                            color: 'white',
                            opacity: '0.6',
                            '& .MuiChip-deleteIcon': {
                                color: 'white',
                            }
                        }}
                            label={"TBD"}
                        />
                    </div>
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Typography fontWeight={"bold"} >{t("Vehicles.Brand")}</Typography>
                            <Typography>{vehicle.brand}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={"bold"} >{t("Vehicles.Model")}</Typography>
                            <Typography>{vehicle.model}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={"bold"} >{t("Vehicles.CurrentKilometers")}</Typography>
                            <Typography>{vehicle.currentKilometers} KM</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <IconButton
                                onClick={() => editVehicle(vehicle.id ?? "")}
                                sx={{
                                    width: 56,
                                    height: 56,
                                    bgcolor: 'primary.main',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: 'primary.main',
                                        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
                                    },
                                    marginLeft: "20px",
                                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                                }}
                                style={{ width: 40, height: 40 }}
                                aria-label="add"
                            >
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
    </Grid>;
}

export default VehicleItem;