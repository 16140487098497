import { useQuery } from '@tanstack/react-query';

import { getIncident } from 'api/incidents';
import { useAuth } from 'context/AuthContext';
import { mapIncident } from 'api/mappers/incidents';

export const useIncident = (incidentId?: string) => {
  const { token } = useAuth();

  return useQuery({
    queryKey: ['incident', incidentId],
    queryFn: async () => {
      const data = await getIncident({ token, incidentId: incidentId || '' });

      return mapIncident(data);
    },
    enabled: !!incidentId || !!token,
  });
};