import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Chip, Box, Grid, IconButton } from "@mui/material";
import { downloadCsv, getToolStatusColor } from "lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileCsv } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { selectLockers } from "slices/lockersSlice";
import { selectTools } from "slices/toolsSlice";
import { selectUsers } from "slices/userSlice";
import { LockerType } from "types/locker";
import { ToolHistoryType, ToolType } from "types/tool";
import { ToolsOverviewReportType } from "types/toolOverviewReportType";
import { UserType } from "types/user";

const ToolsOverviewReport = () => {
    const { t } = useTranslation();
    const tools: ToolType[] = useSelector(selectTools);
    const users: UserType[] = useSelector(selectUsers);
    const lockers: LockerType[] = useSelector(selectLockers);
    const [reportData, setReportData] = useState<ToolsOverviewReportType[]>([]);
    
    useEffect(() => {
        const data: ToolsOverviewReportType[] = tools.map((tool: ToolType) => {
            return {
                name: tool.name,
                reference: tool.reference ?? "",
                status: t(`Tools.Status.${tool.status}`),
                currentUser: getUserName(tool.assignedTo),
                lastCalibration: getLatestCalibration(tool.history),
                locker: getLocker(tool.lockerId),
                nextCalibration: formatDate(tool.nextCalibration),
                purchaseDate: formatDate(tool.purchaseDate),
                lastFix: formatDate(tool.lastFix),
                statusColor: getToolStatusColor(tool.status)
            };
        });
        setReportData(data);
    // eslint-disable-next-line
    }, [])

    const getLatestCalibration = (history: ToolHistoryType[] | undefined): string => {
        // Filter the items with action "calibration"
        const calibrationItems = history?.filter(item => item.action === "calibration");

        // If no items match, return an empty string
        if (!calibrationItems || calibrationItems.length === 0) {
            return "";
        }

        // Find the latest item based on the timeStamp
        const latestItem = calibrationItems.reduce((latest, current) =>
            new Date(current.timeStamp) > new Date(latest.timeStamp) ? current : latest
        );

        // Return the timeStamp as a string
        return new Date(latestItem.timeStamp).toLocaleDateString();
    }

    const getUserName = (id: string | null | undefined): string => {
        if (!id)
            return ""
        const user = users.find(u => u.id === id)
        if (!user) return "";
        return `${user.name} ${user.surname ?? ""}`
    }

    const getLocker = (id: string | null | undefined): string => {
        console.log(id)
        console.log(lockers);
        if (!id)
            return ""
        const locker = lockers.find(u => u.id === id)
        return locker?.identifier ??""; 
    }

    const formatDate = (value?: Date | string) => {
        if (!value) return ""
        return new Date(value).toLocaleDateString()
    }

    const downloadToCsv = () => {
        const headers = [
            t("Reports.ToolsOverview.Fields.Name"),
            t("Reports.ToolsOverview.Fields.Reference"),
            t("Reports.ToolsOverview.Fields.Status"),
            t("Reports.ToolsOverview.Fields.Locker"),
            t("Reports.ToolsOverview.Fields.CurrentUser"),
            t("Reports.ToolsOverview.Fields.PurchaseDate"),
            t("Reports.ToolsOverview.Fields.LastCalibration"),
            t("Reports.ToolsOverview.Fields.NextCalibration"),
            t("Reports.ToolsOverview.Fields.LastFix")
        ];
        const fields = [
            "name",
            "reference",
            "status",
            "locker",
            "currentUser",
            "purchaseDate",
            "lastCalibration",
            "nextCalibration",
            "lastFix"
        ]
        const csvRows = [
            headers.join(","), // Add headers
            ...reportData.map((row: any) => fields.map((field) => row[field]).join(",")) // Add data rows
        ];
        downloadCsv(csvRows.join("\n"), t("Reports.ToolsOverview.ExportFileName"));
    }

    return <Box component="main" pt={"20px"}>
        <Grid container xs={12}>
            <Grid item xs={12} sm={12} pt={"7px"} textAlign={"right"}>
                <IconButton
                    onClick={downloadToCsv}
                    sx={{
                        width: 56,
                        height: 56,
                        bgcolor: 'primary.main',
                        color: 'white',
                        '&:hover': { bgcolor: 'primary.dark' } // Optional: Change color on hover
                    }}
                    style={{ width: 40, height: 40 }}
                    aria-label="add"
                >
                    <FaFileCsv style={{
                        width: "18px",
                        marginLeft: "5px",
                        color: "#EAFC00"
                    }} />
                </IconButton>
            </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 850 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("Reports.ToolsOverview.Fields.Name")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.Reference")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.Status")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.Locker")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.CurrentUser")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.PurchaseDate")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.LastCalibration")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.NextCalibration")}</TableCell>
                        <TableCell align="right">{t("Reports.ToolsOverview.Fields.LastFix")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportData.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.reference}</TableCell>
                            <TableCell align="right"> <Chip sx={{
                                bgcolor: row.statusColor,
                                color: 'white',
                                '& .MuiChip-deleteIcon': {
                                    color: 'white',
                                },
                                width: {}
                            }}
                                label={row.status}
                            /></TableCell>
                            <TableCell align="right">{row.locker}</TableCell>
                            <TableCell align="right">{row.currentUser}</TableCell>
                            <TableCell align="right">{row.purchaseDate}</TableCell>
                            <TableCell align="right">{row.lastCalibration}
                            </TableCell>
                            <TableCell align="right">{row.nextCalibration}</TableCell>

                            <TableCell align="right">{row.lastFix}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
}

export default ToolsOverviewReport;
