import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectToolById } from "../slices/toolsSlice";

type ToolLockerItemProps = {
    toolId: string;
}

const BookingLockerItem = (props: ToolLockerItemProps) => {
    const { toolId } = props;

    const tool = useSelector((state) => selectToolById(state, toolId));

    return <Box sx={{ mt: 3 }}>

        <Grid item xs={12}>
            {
                tool ?
                    <>
                        <Grid item xs={12} container direction="column" alignItems="flex-start">
                            <Typography sx={{
                                fontWeight: 700
                            }}>
                                {tool.name}
                            </Typography>
                        </Grid>
                        {tool?.imgUrl && (
                            <img src={tool.imgUrl} alt={tool.name} style={{ maxWidth: '100%', marginTop: '10px' }} />
                        )}
                    </>

                    : <>&nbsp;</>
            }
        </Grid>

    </Box>;
}

export default BookingLockerItem;