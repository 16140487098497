import { useContext, createContext, useState, ReactNode } from "react";
import { UserType } from "../types/user";
import axios from 'axios';
import { AuthenticationRequestType } from "../types/authenticationRequest";
import { jwtDecode } from "jwt-decode";

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL || '';

interface AuthContextType {
    token: string;
    user: UserType;
    isLoading: boolean;
    authError: boolean;
    loginAction: (authenticationRequest: AuthenticationRequestType) => Promise<boolean>;
    logout: () => void;
    isAuthenticated: () => boolean;
    getAuth: () => { token: string; user: UserType };
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {

    const [user, setUser] = useState<UserType>(localStorage.getItem('sm_user') ? JSON.parse(localStorage.getItem('sm_user') ?? "") as UserType : {} as UserType);
    const [token, setToken] = useState<string>(localStorage.getItem("sm_token") || "");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [authError, setAuthError] = useState<boolean>(false);

    const isAuthenticated = (): boolean => {
        if (!token)
            return false
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken && decodedToken?.exp && decodedToken.exp > currentTime) {
            return true;
        }
        return false;
    }

    const loginAction = async (authenticationRequest: AuthenticationRequestType): Promise<boolean> => {
        try {
            setIsLoading(true);
            const response = await axios.post(`${BASE_URL}/authenticate`, authenticationRequest);
            if (response.data) {
                setUser(response.data.user);
                setToken(response.data.token);
                localStorage.setItem("sm_user", JSON.stringify(response.data.user));
                localStorage.setItem("sm_token", response.data.token);
                return true;
            }
            setIsLoading(false);
            setAuthError(true)
            return false;
        } catch {
            setIsLoading(false);
            setAuthError(true)
            return false;
        }
    };


    const getAuth = () => ({ token, user });

    const logout = () => {
        setUser({} as UserType);
        setToken("");
        setIsLoading(false);
        localStorage.removeItem("sm_user");
        localStorage.removeItem("sm_token");
    };

    return (
        <AuthContext.Provider value={{ token, user, isLoading, authError, loginAction, logout, isAuthenticated, getAuth  }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};