import { useEffect, useState } from 'react';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Splash from '../../components/Splash';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ToolType } from '../../types/tool';
import ToolForm from '../../components/ToolForm';
import { useAuth } from '../../context/AuthContext';
import { fetchUsers, selectUsers, selectUsersStatus } from '../../slices/userSlice';
import { UserType } from '../../types/user';
import { createTool, getTool, selectTool, selectToolStatus, updateTool } from 'slices/toolSlice';
const validationSchema = Yup.object({
  name: Yup.string().required('Nombre requerida'),
  description: Yup.string(),
  imgUrl: Yup.mixed().required('Imagen requerida'),

});

const cfg = {
  successAlertTimeout: 3 * 1000,
};

const ToolPage = () => {
  const { toolId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [success, setSuccess] = useState<'Updated' | 'Created' | null>(null);
  const statusTool = useSelector(selectToolStatus);

  const statusUsers = useSelector(selectUsersStatus);
  const tool: ToolType = useSelector(selectTool);
  const { token, user } = useAuth();
  const users: UserType[] = useSelector(selectUsers);


  useEffect(() => {
    dispatch(fetchUsers({ token, user }));
  }, [tool, toolId, dispatch, token, user]);

  useEffect(() => {
    dispatch(getTool({ id: toolId as string, token }))
  }, [toolId, dispatch, token]);

  const onSubmit = async (toolToSubmit: ToolType) => {
    console.log(tool.id);
    if (tool.id) {
      dispatch(updateTool({ tool: toolToSubmit, token })).then(() => {
        setSuccess('Updated');
        setTimeout(() => {
        }, cfg.successAlertTimeout);
      });
    }
    else {

      dispatch(createTool({ tool: toolToSubmit, token, user })).then(() => {
        setSuccess('Created');
        setTimeout(() => {
        }, cfg.successAlertTimeout);
      });
    }
  };


  return <>
    {statusTool === "loading" || statusUsers === "loading" ? <Splash></Splash> :
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Formik<ToolType>
          initialValues={tool}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur, values, touched, errors, isValid, setFieldValue }) => (
            <Form noValidate>
              <ToolForm
                success={success}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={isValid}
                setFieldValue={setFieldValue}
                touched={touched}
                users={users}
                values={values}
                toolId={toolId}
              />
            </Form>
          )}

        </Formik>
      </LocalizationProvider>}
    </>
}

export default ToolPage;