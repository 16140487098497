import React from "react";
import { SxProps, Typography } from "@mui/material";


interface TextProps {
  htmlContent?: string; // Optional HTML content
  children?: React.ReactNode; // Regular children (optional if using `htmlContent`)
  sx?: SxProps; // MUI styles
  [key: string]: any; // Additional props for Typography
}

const SectionContent: React.FC<TextProps> = ({ htmlContent, children, sx, ...props }) => {
  // Avoid passing children when htmlContent is present
  const contentProps = htmlContent
    ? { dangerouslySetInnerHTML: { __html: htmlContent } }
    : { children };

  return (
    <Typography
      {...props}
      sx={{
        color: "#666",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "30px",
        marginBottom: "20px",
        ...(sx || {}),
      }}
      {...contentProps}
    />
  );
};


export default SectionContent;
