import { SelectChangeEvent, Grid, FormControl, InputLabel, Select, MenuItem, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Box } from "@mui/material";
import { downloadCsv, formatTimestamp } from "lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTools } from "slices/toolsSlice";
import { selectUsers } from "slices/userSlice";
import { ToolType, ToolHistoryType } from "types/tool";
import { ToolLifeReportType } from "types/toolLifeReport";
import { UserType } from "types/user";
import { FaFileCsv } from "react-icons/fa6";


const ToolsLife = () => {
    const { t } = useTranslation();
    const tools: ToolType[] = useSelector(selectTools);
    const [toolLifeReport, setToolLifeReport] = useState<ToolLifeReportType[]>([]);
    const users: UserType[] = useSelector(selectUsers);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const filterTool = tools.find(tool => tool.id === event.target.value)
        let data: ToolLifeReportType[] = [];
        if (filterTool?.history) {
            data = [...filterTool.history]
                .sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime())
                .map((h: ToolHistoryType) => {
                    const user = users.find((u) => u.id === h.userId);
                    const userName = user ? `${user.name} ${user.surname ?? ""}` : "";
                    const formattedDate = formatTimestamp(h.timeStamp);
                    const action = t(`Tools.HistoryStatus.${h.action}`)
                    
                    console.log(new Date(h.timeStamp).getTime())
                    return {
                        userName: userName,
                        action: action,
                        timestamp: h.timeStamp.toString(),
                        formatedTimestamp: formattedDate
                    };
            })
        }
        setToolLifeReport(data)
    };

    const downloadToCsv = () => {
        const headers = [
            t("Reports.ToolsLife.Fields.Name"),
            t("Reports.ToolsLife.Fields.Date"),
            t("Reports.ToolsLife.Fields.Action")
        ];
        const fields = [
            "userName",
            "formatedTimestamp",
            "action"
        ]
        const csvRows = [
            headers.join(","), // Add headers
            ...toolLifeReport.map((row: any) => fields.map((field) => row[field]).join(",")) // Add data rows
        ];
        downloadCsv(csvRows.join("\n"), t("Reports.ToolsLife.ExportFileName"));
    }

    useEffect(() => {

    }, [])

    return <>
        <Box component="main" style={{ paddingTop: "20px" }}>
            <Grid container xs={12}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="tool.label">
                            {t("Reports.ToolsLife.SelectTool")}
                        </InputLabel>
                        <Select
                            labelId="tool.label"
                            id="tool.report"
                            name="report.toolId"
                            label={t("Reports.ToolsLife.SelectTool")}
                            onChange={handleChange}
                        >
                            {tools.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} pt={"7px"} textAlign={"right"}>
                    <IconButton
                        onClick={downloadToCsv}
                        sx={{
                            width: 56,
                            height: 56,
                            bgcolor: 'primary.main',
                            color: 'white',
                            '&:hover': { bgcolor: 'primary.dark' } // Optional: Change color on hover
                        }}
                        style={{ width: 40, height: 40 }}
                        aria-label="add"
                    >
                        <FaFileCsv style={{
                            width: "18px",
                            marginLeft: "5px",
                            color: "#EAFC00"
                        }} />
                    </IconButton>
                </Grid>
            </Grid>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("Reports.ToolsLife.Fields.Name")}</TableCell>
                            <TableCell align="right">{t("Reports.ToolsLife.Fields.Date")}</TableCell>
                            <TableCell align="right">{t("Reports.ToolsLife.Fields.Action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {toolLifeReport.map((row) => {
                            return (
                                <TableRow
                                    key={row.timestamp}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.userName}</TableCell>
                                    <TableCell align="right">{row.formatedTimestamp}</TableCell>
                                    <TableCell align="right">{row.action}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    </>
}

export default ToolsLife;