// src/features/locker/lockerSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { LockerType } from '../types/locker';
import { AssignUseRequestType } from '../types/assignUseRequest';
import { UserType } from '../types/user';

const BASE_URL = process.env.REACT_APP_LOCKER_API_URL || '';



// Async thunks for async operations
export const fetchLockers = createAsyncThunk(
  'lockers/fetchLockers',
  async ({ token, user }: { token: string, user: UserType }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/account/${user.accountId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createLocker = createAsyncThunk(
  'lockers/createlocker',
  async ({ locker, token, user }: { locker: LockerType, token: string, user: UserType }, { rejectWithValue }) => {
    try {
      locker.accountId = user.accountId
      const response = await axios.post(BASE_URL, locker,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLocker = createAsyncThunk(
  'lockers/updatelocker',
  async ({ locker, token }: { locker: LockerType, token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}`, locker,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLocker = createAsyncThunk(
  'lockers/deletelocker',
  async ({ id, token }: { id: string, token: string }, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const assignUse = createAsyncThunk(
  'lockers/assignUse',
  async ({ id, assignUseRequest, token }: { id: string, assignUseRequest: AssignUseRequestType, token: string }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/${id}/use`,
        assignUseRequest,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const lockerState = (state: RootState) => state.lockers;
export const selectLockers = createSelector(
  lockerState,
  (lockerState) => lockerState.lockers
);


export const selectLockersStatus = createSelector(
  lockerState,
  (lockerState) => lockerState.status
);


export const selectLockersError = createSelector(
  lockerState,
  (lockerState) => lockerState.error
);


export const lockerInitialState: LockerType = {
  id: "",
  accountId: "",
  status: "",
  lockerInfo: "",
  identifier: "",
  lockerSize: 0,
};
const lockerSlice = createSlice({
  name: 'lockers',
  initialState: {
    lockers: [] as Array<LockerType>,
    status: 'idle',
    error: "",
  },
  reducers: {
    // Your synchronous reducers here (if needed)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLockers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLockers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.lockers = action.payload;
      })
      .addCase(fetchLockers.rejected, (state) => {
        state.error = "error";
        state.status = 'failed';
      })
      .addCase(createLocker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createLocker.fulfilled, (state, action) => {
        state.lockers.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createLocker.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateLocker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateLocker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedItem = action.payload;
        const index = state.lockers.findIndex(locker => locker.id === updatedItem.id);
        if (index !== -1) {
          state.lockers[index] = updatedItem;
        }
      })
      .addCase(assignUse.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedItem = action.payload;
        const index = state.lockers.findIndex(locker => locker.id === updatedItem.id);
        if (index !== -1) {
          state.lockers[index] = updatedItem;
        }
      })
      .addCase(updateLocker.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteLocker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteLocker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.lockers.findIndex(locker => locker.id === action.payload);
        state.lockers.splice(index, 1);
      })
      .addCase(deleteLocker.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export default lockerSlice.reducer;