import { useQuery } from '@tanstack/react-query';

import { getIncidentTypes } from 'api/incidents';
import { mapIncidentType } from 'api/mappers/incidents';
import { useTranslation } from 'react-i18next';

export const useIncidentTypes = () => {
  const { t } = useTranslation();

  return useQuery({
    queryKey: ['incident-types'],
    queryFn: async () => {
      const data = await getIncidentTypes();

      return data.map((incident) => mapIncidentType(incident, t));
    },
    enabled: true,
  });
};