import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import Splash from "../components/Splash";
import { fetchCompanyAccount, fetchCustomerBooking, selectCustomerBooking, selectCustomerBookingCompany, selectCustomerBookingStatus } from "../slices/customerBookingSlice";
import { Button, Container, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Title from "../components/customer-pages/atoms/title";
import InformativeText from "../components/customer-pages/atoms/InformativeTexts";
import IconContainer from "../components/customer-pages/atoms/IconContainer";
import { CutomerInput } from "../components/customer-pages/atoms/input";
import Footer from "../components/customer-pages/atoms/Footer";
import CustomerLogo from "../components/customer-pages/CutomerLogo";

type BookingForm = {
    bookingNumber: string;
    surname: string;
}

const UserBookingAccessPage = () => {
    const { accountId } = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const company = useSelector(selectCustomerBookingCompany);
    const status = useSelector(selectCustomerBookingStatus);
    const customerBooking = useSelector(selectCustomerBooking);

    useEffect(() => {
        if (accountId) {
            dispatch(fetchCompanyAccount({ accountId }));
        }
    }, [accountId, dispatch]);

    useEffect(() => {
        if (status === "succeeded" && customerBooking) {
            navigate(`/customer/booking/management/`);
        }
    }, [accountId, status, customerBooking, navigate]);

    const initialValues: BookingForm = {
        bookingNumber: '',
        surname: ''
    };
    const validationSchema = Yup.object({
        bookingNumber: Yup.string().required('El numero de reserva es requerido'),
        surname: Yup.string().required('El apellido es requerido'),
    });


    const onSubmit = (bookingForm: BookingForm) => {
        dispatch(fetchCustomerBooking(
            {
                accountId: accountId ?? "",
                bookingNumber: bookingForm.bookingNumber,
                surname: bookingForm.surname
            }
        ));
    };

    const isMobile = window.innerWidth < 600;

    return <>
        {
            !company ? <Splash></Splash> :
                <>
                    {status === "loading" ? <Splash></Splash> : <></>}
                    <div style={{ width: "100%", backgroundColor: "#E6F2F4", }}>
                        <Container maxWidth={"sm"} sx={{ backgroundColor: "#E6F2F4", minHeight: `calc(100vh - ${isMobile ? "62" : "39"}px)` }}>
                            <Grid container spacing={2} pl={3} pr={3}>
                                <CustomerLogo img="/dummy-logo.png" height="73px" ></CustomerLogo>
                                <Grid item xs={12}>
                                    <IconContainer paddingTop="13px">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="35" viewBox="0 0 42 35" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M35.0849 9.26256C35.905 9.94598 35.905 11.054 35.0849 11.7374L18.2849 25.7374C17.4648 26.4209 16.1352 26.4209 15.3151 25.7374L6.91506 18.7374C6.09496 18.054 6.09496 16.946 6.91506 16.2626C7.73516 15.5791 9.06481 15.5791 9.88491 16.2626L16.8 22.0251L32.1151 9.26256C32.9352 8.57915 34.2648 8.57915 35.0849 9.26256Z" fill="#EAFC00" />
                                        </svg>
                                    </IconContainer>
                                </Grid>

                                <Grid item xs={12}>
                                    <Title>Lectura de QR correcta</Title>
                                    <InformativeText>Bienvenido a nuestro sistema SmartLock. <br />Siga los pasos a continuación para poder completar la reserva de su alquiler.</InformativeText>
                                </Grid>
                                <Grid item xs={12} mt="20px" spacing={2}>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={onSubmit}
                                    >
                                        {({ handleChange, handleBlur, values, touched, errors, isValid }) => (

                                            <Form>
                                                <Title>Información de la reserva</Title>
                                                <Grid mt="10px" item xs={12}>
                                                    <CutomerInput
                                                        fullWidth
                                                        variant="outlined"
                                                        id="bookingNumber"
                                                        name="bookingNumber"
                                                        label="Número de la reserva"
                                                        value={values.bookingNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={touched.bookingNumber && errors.bookingNumber ? errors.bookingNumber : ''}
                                                        error={touched.bookingNumber && Boolean(errors.bookingNumber)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} mt={2}>
                                                    <CutomerInput
                                                        fullWidth
                                                        variant="outlined"
                                                        id="surname"
                                                        name="surname"
                                                        label="Apellido"
                                                        value={values.surname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={touched.surname && errors.surname ? errors.surname : ''}
                                                        error={touched.surname && Boolean(errors.surname)}
                                                    />
                                                </Grid>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    disabled={!isValid}
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2, height:"80px", color:"#EAFC00", fontWeight:"700" }}
                                                >
                                                    Buscar reserva
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>

                        </Container>
                    </div>
                    <Footer company={company}></Footer>
                </>
        }
    </>
}


export default UserBookingAccessPage;