import * as React from "react";
import {
  Alert,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { useTools } from 'api/hooks';
import CustomerLogo from "components/customer-pages/CutomerLogo";
import params from "./params.json";
import Splash from "components/Splash";
import { useTranslation } from "react-i18next";
import Step0 from "components/CreateIncident/Step0";
import Step1 from "components/CreateIncident/Step1";
import Step2 from "components/CreateIncident/Step2";
import StepEnd from "components/CreateIncident/StepEnd";
import { Formik, FormikProps } from "formik";
import { FormikParams } from "components/CreateIncident/types";
import { useCreateIncident } from "api/hooks";
import { PostIncidentBody } from "api/types";
import useFileUpload from "lib/hooks/useFileUpload";

interface LayoutProps {
  children: React.ReactNode;
  isTitleDisplayed?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, isTitleDisplayed = true }) => {
  const { t } = useTranslation();
  
  return (
    <div style={{ width: "100%", backgroundColor: "#E6F2F4", }}>
      <Container maxWidth="sm" sx={{ backgroundColor: "#fff", minHeight: `100vh` }}>
        <Grid container spacing={2} pl={3} pr={2}>
          <CustomerLogo img="/smartworkshop-logo-white.png" style={{ height: 'auto', width: '90%' }} />
          <Grid item xs={12}>
            {isTitleDisplayed && (
              <Typography
                variant="h5"
                sx={{ textTransform: "uppercase", marginTop: "1rem", fontWeight: 500 }}
              >
                {t('CreateIncidentPage.Title')}
              </Typography>
            )}
            {children}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const CreateIncidentPage = () => {
  const { data: tools, isLoading: isLoadingTool, error: useToolsError } = useTools(params);
  const [step, setStep] = React.useState<number>(0);
  const { t } = useTranslation();
  const [dashboardPicture, setDashboardPicture] = React.useState<FormData | null>(null);
  const advance = React.useCallback(() => setStep((prev) => prev + 1), []);
  const tool = (tools || []).find((tool) => tool.id === params.toolId);
  const validationSchema = React.useMemo(() =>
    Yup.object({
      type: Yup.string().required(t('CreateIncidentPage.Validation.Type.Required')),
      description: Yup.string(),
    }), [t]);
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);
  const { mutate, status: creatingIncidentStatus, error: createIncidentError } = useCreateIncident(({
    onSuccess: () => advance(),
  }));
  
  const { uploadFile, status: useFileUploadStatus, error: useFileUploadError } = useFileUpload({
    token: params.token,
    accountId: params.accountId,
    toolId: params.toolId,
  });

  const onSubmit = React.useCallback(async (values: FormikParams) => {
    if (!dashboardPicture) {
      return;
    }

    const fileExtension = (dashboardPicture.get('filename') as string || '').split('.').pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    try {
      const result = await uploadFile({
        file: dashboardPicture.get('file') as File,
        fileName,
      });
      console.log('Upload successful:', result);
    } catch (err) {
      console.error('Upload failed:', err);
    } finally {
      // create incident
      const body = {
        accountId: params.accountId,
        incidentType: values.type,
        description: values.description,
        toolId: params.toolId,
        createdDate: new Date().toISOString(),
        createdBy: params.userId,
        picture: [fileName],
      } as PostIncidentBody;
      mutate({ token: params.token, body });
    }
    
  }, [dashboardPicture, mutate, uploadFile]);
  const isLoading = isLoadingTool || creatingIncidentStatus === 'pending' || useFileUploadStatus === 'pending';
  const error = useToolsError || createIncidentError || useFileUploadError;

  if (isLoading) {
    return (
      <Layout>
        <Splash />
      </Layout>
    );
  }

  if (error || !tool) {
    return (
      <Layout>
        <Alert severity="error">
          <Typography variant="h5">
            {error && error.message
              ? error.message
              : t('CreateIncidentPage.Error.ToolNotFound')}
          </Typography>
        </Alert>
      </Layout>
    );
  }

  const steps = [
    () => (
        <Step0
          tool={tool}
          advance={advance}
          isNextEnabled={true}
        />
      ),
    (formik: FormikProps<FormikParams>) => (
      <Step1
        tool={tool}
        advance={advance}
        {...formik}
        isNextEnabled={formik.values.type !== ''}
        />
      ),
    (formik: FormikProps<FormikParams>) => (
      <Step2
        tool={tool}
        advance={advance}
        dashboardPicture={dashboardPicture}
        setDashboardPicture={setDashboardPicture}
        handleSubmit={formik.handleSubmit}
        isNextEnabled={dashboardPicture !== null}
        />
    ),
  ];

  return (
    <Layout isTitleDisplayed={steps[step] !== undefined}>
      <Formik<FormikParams>
        initialValues={{
          type: '',
          description: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(params) => (
          steps[step] ? steps[step](params) : <StepEnd />
        )}
      </Formik>
    </Layout>
  );
}
export default CreateIncidentPage;