import { Box, } from "@mui/material";
import { AccountType } from "../../../types/account";

type FooterProps = {
    company: AccountType | null;
};

const Footer = (props: FooterProps) => {
    const { company } = props;
    return <>{company ?
        <Box sx={{
            width: '100%',
            position: 'abolute',
            bottom: 0,
            backgroundColor: '#E6F2F4',
            color: 'black',
            textAlign: "center",
            padding: 2,
            fontSize: "15px",
        }}><b>{company.companyName}</b> | {company.email} | <span style={{textWrap:"wrap"}} >{company.address.street}</span> | <span style={{textWrap:"nowrap"}}>{company.address.city}</span>
        </Box > : <></>
    }
    </>
}
export default Footer;