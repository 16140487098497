import * as React from 'react';

export interface  UseModalReturn {
  open: boolean;
  handleOpen: () => void;
  handleClose: (data?: any) => void;
}

export default function useModal(defaultOpen: boolean = false) {
  const [open, setOpen] = React.useState(defaultOpen);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    handleOpen,
    handleClose
  };
}