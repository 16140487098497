import { Grid, Paper, Card, CardContent, Typography, IconButton, Chip, Button } from "@mui/material";
import { ToolType } from "../types/tool";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUsers } from "slices/userSlice";
import { UserType } from "types/user";
import { getToolStatusColor } from "lib";
import { useAuth } from "context/AuthContext";
import { AppDispatch } from "store";
import { asignUserTool, requestTool } from "slices/toolsSlice";

type ToolItemProps = {
    tool: ToolType;
    displayEditButton?: boolean;
}

const ToolItem = (props: ToolItemProps) => {
    const { displayEditButton = true, tool } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const users: UserType[] = useSelector(selectUsers);
    const { user, token } = useAuth();

    const editTool = (toolId: string) => {
        navigate(`/dashboard/tool/${toolId}`);
    }



    const getCurrentToolUserName = () => {
        if (!tool.assignedTo) return "";
        if (tool.assignedTo === user.id) return "";
        const toolUser = users.find(u => u.id === tool.assignedTo)
        if (toolUser)
            return `${t("Tools.Item.User")}${toolUser.name} ${toolUser.surname ?? ""}`;
        return "";
    }
    const getRequestorName = () => {
        const toolUser = users.find(u => u.id === tool.requestedBy)
        if (toolUser)
            return `${toolUser.name} ${toolUser.surname ?? ""}`
        return ""
    }

    const rejectRequest = () => {
        dispatch(requestTool({ id: tool.id ?? "", userId: null, token: token }));
    }

    const acceptRequest = () => {
        dispatch(asignUserTool({ id: tool.id ?? "", userId: tool.requestedBy ?? "", token: token }));
    }

    const sendRequest = () => {
        dispatch(requestTool({ id: tool.id ?? "", userId: user.id ?? "", token: token }));
    }

    const editButton = () => {
        return <Grid item xs={12} style={{ textAlign: "right" }}>
            <IconButton
                onClick={() => editTool(tool.id ?? "")}
                sx={{
                    width: 56,
                    height: 56,
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                        bgcolor: 'primary.main',
                        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
                    },
                    marginLeft: "20px",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                }}
                style={{ width: 40, height: 40 }}
                aria-label="add"
            >
                <EditIcon />
            </IconButton>
        </Grid>
    }

    const requestToolButton = () => {
        return <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button color="primary" variant="contained" fullWidth onClick={sendRequest}>
                {t("Buttons.Request")}
            </Button>
        </Grid>
    }

    const requestSent = () => {
        return <Grid container spacing={2}>
            <Grid xs={12} item textAlign="center" marginTop="10px">
                <Chip sx={{
                    bgcolor: "#EAFC00",
                    color: 'primary',
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%",
                    height: "36px",
                    textTransform: "uppercase"
                }}
                    label={t("Tools.Item.RequestSent")}
                />
            </Grid>
        </Grid>
    }

    const confirmRequest = () => {
        return <Grid container spacing={2}>
            <Grid xs={12} item marginTop="10px">
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    textAlign: "center"
                }}>{t("Tools.Item.IncomingRequest")}{getRequestorName()}</Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    textAlign: "center"
                }}>{t("Tools.Item.AcceptRequest")}</Typography>
            </Grid>
            <Grid xs={6} item marginTop="10px">
                <Button color="secondary" variant="contained" fullWidth onClick={rejectRequest}>
                    {t("Buttons.No")}
                </Button>
            </Grid>
            <Grid xs={6} item marginTop="10px">
                <Button color="primary" variant="contained" fullWidth onClick={acceptRequest}>
                    {t("Buttons.Yes")}
                </Button>
            </Grid>
        </Grid>
    }

    const getRequestSection = () => {
        if (!displayEditButton && tool.requestedBy) {
            if (tool.requestedBy === user.id) {
                return requestSent();
            }
            if (tool.assignedTo === user.id) {
                return confirmRequest();
            }
        }
        return <></>
    }

    return (
        <Grid item>
            <Paper elevation={3} sx={{ maxWidth: "350px" }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography sx={{
                                    fontWeight: 700,
                                    fontSize: "24px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "200px",
                                    display: "block",
                                }}>{tool.name}</Typography>
                            </Grid>
                            <Grid item xs={3} textAlign={"right"}>
                                <Chip sx={{
                                    bgcolor: getToolStatusColor(tool.status),
                                    color: 'white',
                                    '& .MuiChip-deleteIcon': {
                                        color: 'white',
                                    },
                                    width: {}
                                }}
                                    label={t(`Tools.Status.${tool.status}`)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <img src={tool.imgUrl} alt={tool.name} style={{ maxWidth: '100%', marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ textAlign: "left" }}>
                                <Typography sx={{
                                    fontWeight: 700,
                                    marginTop: "10px",
                                    fontSize: "18px"
                                }}>{getCurrentToolUserName()}
                                </Typography>
                            </Grid>
                            {
                                displayEditButton === true ? editButton() : <></>
                            }
                            {
                                !displayEditButton && !tool.requestedBy && user.id !== tool.assignedTo ? requestToolButton() : <></>
                            }
                        </Grid>
                        {getRequestSection()}
                    </CardContent>
                </Card>
            </Paper>
        </Grid >
    );
}
export default ToolItem;