// src/features/locker/lockerSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { LockerType } from '../types/locker';

const BASE_URL = process.env.REACT_APP_LOCKER_API_URL || '';

// Async thunks for async operations
export const fetchLocker = createAsyncThunk(
  'lockers/fetchLocker',
  async ({ accountId, useId }: { accountId: string, useId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/account/${accountId}/use/${useId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const lockerState = (state: RootState) => state.locker;

export const selectLocker = createSelector(
  lockerState,
  (lockerState) => lockerState.locker
);

export const selectLockerStatus = createSelector(
  lockerState,
  (lockerState) => lockerState.status
);

const lockerSlice = createSlice({
  name: 'locker',
  initialState: {
    locker: null as LockerType | null,
    status: 'idle',
    error: "",
  },
  reducers: {
    // Your synchronous reducers here (if needed)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocker.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocker.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.locker = action.payload;
      })
      .addCase(fetchLocker.rejected, (state) => {
        state.error = "error";
        state.status = 'failed';
      })
  }
});

export default lockerSlice.reducer;