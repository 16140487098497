import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import Splash from "../components/Splash";
import { Box, Container, Divider, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext';
import { fetchUsers, selectNonRootUsers, selectUsersStatus } from "../slices/userSlice";
import { UserType } from "../types/user";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import { deleteUser } from '../slices/userSlice';
import SectionTitle from "components/SectionTitle";
import { BsQrCode } from "react-icons/bs";
import { downloadQRCode } from "lib";
import { IoPhonePortraitOutline } from "react-icons/io5";



const UserManagementPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token, user } = useAuth();
    const dispatch: AppDispatch = useDispatch();
    const status = useSelector(selectUsersStatus);
    const users: UserType[] = useSelector(selectNonRootUsers);
    useEffect(() => {
        dispatch(fetchUsers({ token, user }));
    }, [dispatch, token, user])

    const addUser = () => {
        navigate('/dashboard/user');
    }
    const editUser = (code: string, userId: string) => {
        navigate(`/dashboard/user/${code}/${userId}`);
    }
    const userDelete = (userToDelete: UserType) => {
        dispatch(deleteUser({ id: userToDelete.id, accountId: userToDelete.accountId, token: token })).then(() => {
            navigate('/dashboard/users');
        });
    }

    const downloadQR = async (userId: string, userName: string, wallpaper: boolean) => {
        await downloadQRCode(userId, token, userName, wallpaper);
    }

    return <>
        {status === "loading" ? <Splash></Splash> : <></>}
        <Box marginTop={"100px"}>
            <SectionTitle>{t("User.Title")}</SectionTitle>
            <Divider />
            <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        onClick={addUser}
                        sx={{
                            width: 56,
                            height: 56,
                            bgcolor: 'primary.main',
                            color: 'white',
                            '&:hover': { bgcolor: 'secondary.dark' }
                        }}
                        style={{ width: 40, height: 40 }}
                        aria-label="add"
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Usuario</TableCell>
                                <TableCell align="right">Nombre</TableCell>
                                <TableCell align="right">Apellido</TableCell>
                                {/* <TableCell align="right">Tools</TableCell>
                                <TableCell align="right">Renting</TableCell>
                                <TableCell align="right">Management</TableCell> */}
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.userName}
                                    </TableCell>
                                    <TableCell align="right">{row.name}</TableCell>
                                    <TableCell align="right">{row.surname}</TableCell>
                                    {/* <TableCell align="right">{row.access?.toolsRoleId}</TableCell>
                                    <TableCell align="right">{row.access?.rentingRoleId}</TableCell>
                                    <TableCell align="right">{row.access?.managementRoleId}</TableCell> */}
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => downloadQR(row.id ?? "", row.userName ?? "", true)}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                bgcolor: 'primary.main',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'secondary.dark' }
                                            }}
                                            style={{ width: 30, height: 30, marginRight: 5 }}
                                            aria-label="edit"
                                        >
                                            <IoPhonePortraitOutline style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => downloadQR(row.id ?? "", row.userName ?? "", false)}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                bgcolor: 'primary.main',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'secondary.dark' }
                                            }}
                                            style={{ width: 30, height: 30, marginRight: 5 }}
                                            aria-label="edit"
                                        >
                                            <BsQrCode style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => editUser('edit', row.id ?? "")}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                bgcolor: 'primary.main',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'secondary.dark' }
                                            }}
                                            style={{ width: 30, height: 30, marginRight: 5 }}
                                            aria-label="edit"
                                        >
                                            <EditIcon style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => userDelete(row)}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                bgcolor: 'primary.main',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'secondary.dark' }
                                            }}
                                            style={{ width: 30, height: 30 }}
                                            aria-label="delete"
                                        >
                                            <DeleteIcon style={{ width: 20, height: 20 }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>
        </Box>
    </>
}

export default UserManagementPage;