import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, TextField, Typography } from '@mui/material';

import useModal from 'lib/hooks/useModal';

import IncidentObservationsAddModal from './AddModal';
import { Incident, IncidentObservation } from 'api/types';
import { formatTimestamp } from 'lib';
import { useUserById } from 'api/hooks';

interface Props {
  incident: Incident;
  onObservationAdd?: () => void;
}

const Observation = (props: { observation: IncidentObservation }): React.ReactElement => {
  const { observation } = props;
  const { isLoading, data: user } = useUserById(observation.userId);
  const displayedName = isLoading 
    ? '...' 
    : user 
    ? user.email 
    : observation.userId;

  return (
    <Grid item xs={12} marginTop={2}>
      <TextField
        fullWidth
        label={`${displayedName}: ${formatTimestamp(observation.timestamp)}`}
        value={observation.detail}
        disabled
        rows={2}
        multiline
      />
    </Grid>
  );
}

const IncidentObservations = (props: Props): React.ReactElement<Props> => {
  const { incident, onObservationAdd = () => null } = props;
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useModal(false);

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>{t('IncidentPage.Input.Observations')}</Typography>
      {(incident.observations || []).map((observation) => (
        <Observation key={observation.timestamp} observation={observation} />
      ))}
      <Grid item xs={4} marginY={2}>
        <Button
          style={{ fontWeight: "bold" }}
          variant="contained"
          onClick={() => handleOpen()}>
            {t("IncidentPage.Observations.Buttons.Add")}
        </Button>
      </Grid>
      <IncidentObservationsAddModal
        handleOpen={handleOpen}
        open={open}
        handleClose={() => {
          handleClose();
          onObservationAdd();
        }}
        incident={incident}
      />
    </>
  );
};

export default IncidentObservations;