import * as React from 'react';
import { useDownloadSasUri } from 'api/hooks';
import { Skeleton } from '@mui/material';

interface Props {
  fileName: string;
  alt?: string;
  height?: number;
  style?: React.CSSProperties;
}

const SasImage = (props: Props): React.ReactElement<Props> => {
  const { alt, fileName, height = 250, style = {}  } = props;
  const { data: picture } = useDownloadSasUri(fileName);
    
  if (!picture) {
    return (
      <Skeleton
        variant="rectangular"
        width={210}
        height={height}
      />
    );
  }

  return (
    <img
      onError={(e) => {
        e.currentTarget.src = `https://fakeimg.pl/${height}x210/cccccc/cccccc`;
      }}
      src={picture}
      alt={alt}
      style={{
        height,
        width: 'auto',
        ...style,
      }}
    />
  );
};

export default SasImage;