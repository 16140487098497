import { Grid } from "@mui/material";

type CustomerLogoProps = {
  img: string,
  height?: string,
  style?: Record<string, string>
}

const CustomerLogo = (props: CustomerLogoProps) => {
    const { img, height, style } = props;
    return <Grid item xs={12} textAlign={"center"} mt={3}>
        <img height={height} src={img} alt="logo" style={style} />
    </Grid>
}

export default CustomerLogo;