
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PasswordType } from '../types/password';
const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL || '';

export const updatePassword = createAsyncThunk(
    'password/updatePassword',
    async ({ password, token }: { password: PasswordType, token: string }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${BASE_URL}/user/password`, password,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const userInitialState: PasswordType = {
    userId: "",
    accountId: "",
    password: ""
};


const passwordSlice = createSlice({
    name: 'Password',
    initialState: {
        password: [] as Array<PasswordType>,
        status: 'idle',
        error: "",
    },
    reducers: {
        // Your synchronous reducers here (if needed)
    },
    extraReducers: (builder) => {
        builder
            .addCase(updatePassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedItem = action.payload;
                const index = state.password.findIndex(password => password.userId === updatedItem.id);
                if (index !== -1) {
                    state.password[index] = updatedItem;
                }
            })
            .addCase(updatePassword.rejected, (state) => {
                state.status = 'failed';
            })
    }
});

export default passwordSlice.reducer;