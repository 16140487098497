// src/features/account/accountSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { AccountType } from '../types/account';
import { RootState } from '../store';
import { UserType } from '../types/user';

const BASE_URL = process.env.REACT_APP_ACCOUNT_API_URL || '';

// Async thunks for async operations
export const fetchAccount = createAsyncThunk(
  'account/fetchAccount',
  async ({token, user}: {token: string, user:UserType}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/${user.accountId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  'account/updateAccount',
  async ({account, token}: {account: AccountType, token: string}, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}`, account,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const accountState = (state: RootState) => state.account;

export const selectAccount = createSelector(
  accountState,
  (accountState) => accountState.account
);

export const selectAccountStatus = createSelector(
  accountState,
  (accountState) => accountState.status
);


export const selectAccountError = createSelector(
  accountState,
  (accountState) => accountState.error
);


const accountSlice = createSlice({
  name: 'account',
  initialState: {
    account: {} as AccountType | null,
    status: 'idle',
    error: "",
  },
  reducers: {
    // Your synchronous reducers here (if needed)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.account = action.payload;
      })
      .addCase(fetchAccount.rejected, (state) => {
        state.error = "error";
        state.status = 'failed';
      })
      .addCase(updateAccount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.account = action.payload;
      })
      .addCase(updateAccount.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export default accountSlice.reducer;