import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    accent_1: Palette['primary'];
  }
  interface PaletteOptions {
    accent_1?: PaletteOptions['primary'];
  }
}


const theme = createTheme({
  palette: {
    primary: {
      main: '#053d57',
    },
    secondary: {
      main: '#EAFC00',
    },
    accent_1: {
      main: '#9EC7CF'
    }
  },
});

export default theme;