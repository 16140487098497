import { Box, Container, Grid } from "@mui/material";
import { t } from "i18next";
import LandingSectionContent from "./LandingSectionContent";
import LandingSectionTitle from "./LandingSectionTitle";
import LandingValueItem from "./LandingValueItem";


const values = [
    {image: "expirience.png", title: "Landing.Values.Expirience.Title", content: "Landing.Values.Expirience.Content"},
    {image: "compromise.png", title: "Landing.Values.Compromise.Title", content: "Landing.Values.Compromise.Content"},
    {image: "transparency.png", title: "Landing.Values.Transparency.Title", content: "Landing.Values.Transparency.Content"},
]

const LandingValuesSection = () => {
    return <Box>
        <Container maxWidth="xl">
            <Grid container spacing={2} sx= {{
                textAlign: "center",
                paddingTop: {
                    xs: "50px",
                    md: "100px"
                },
                paddingLeft: {
                       xs: "10px",
                    md: "20%"
                },
                paddingRight: {
                    xs: "10px",
                 md: "20%"
             }

            }}>
                <Grid item xs={12}>
                    <h3 style={{
                        color: "#666",
                        fontSize: "20px",
                        lineHeight: "22px",
                        textTransform: "uppercase",
                        fontWeight: "700"
                    }}>{t("Landing.Values.OurValues")}</h3>
                    <LandingSectionTitle>{t("Landing.Values.Title")}</LandingSectionTitle>
                    <LandingSectionContent>{t("Landing.Values.Content")}</LandingSectionContent>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {
                    values.map(v => {
                        return <LandingValueItem key={v.title} title={v.title} image={v.image} content={v.content}></LandingValueItem>
                    })
                }
            </Grid>
        </Container>
    </Box>
}

export default LandingValuesSection;