import * as React from 'react';

import Table from 'components/Table';
import { ToolHistoryType, ToolType } from 'types/tool';
import { UserType } from 'types/user';
import { formatTimestamp } from 'lib';

interface Props {
  tool: ToolType;
  users: UserType[],
}

const ToolCalibrationTable = (props: Props): React.ReactElement<Props> => {
  const { tool, users } = props;
  const toolHistory = React.useMemo(
    () => tool.history?.filter((history) => history.calibration) || [],
    [tool.history]
  );

  return (
    <Table<ToolHistoryType>
      data={toolHistory}
      isTitleDisplayed={false}
      i18nKey="EditTools.CalibrationTable"
      columns={[
        {
          key: 'Date',
          value: ({ timeStamp }) => formatTimestamp(timeStamp),
        },
        {
          key: 'User',
          value: ({ userId }) => {
            const user = users.find((u) => u.id === userId);

            return user?.name || 'Unknown User';
          },
        },
        {
          key: 'Entity',
          value: ({ calibration }) => calibration?.entity || '-',
        },
        {
          key: 'Observations',
          value: ({ calibration }) => calibration?.observations || '-',
        },
      ]}
    />
  );
};

export default ToolCalibrationTable;