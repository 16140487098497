import axios from 'axios';

import { apiBaseUrls } from 'config';
import { ToolType } from 'types/tool';

import { AuthenticatedRequest } from './types';

export type PostToolCalibrationBody = {
  entity?: string;
  observations: string;
}

export const createCalibration = async (params: AuthenticatedRequest & {
  body: PostToolCalibrationBody,
  toolId: string,
}) => {
  const { accountId, token, body, toolId } = params;
  const { data } = await axios.patch(`${apiBaseUrls.tools}/${toolId}/account/${accountId}/calibration`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const addFile = async (params: AuthenticatedRequest & {
  fileName: string,
  toolId: string,
}) => {
  const { fileName, token, toolId } = params;
  const { data } = await axios.post(`${apiBaseUrls.tools}/${toolId}/files/${fileName}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const deleteFile = async (params: AuthenticatedRequest & {
  fileName: string,
  toolId: string,
}) => {
  const { fileName, token, toolId } = params;
  const { data } = await axios.delete(`${apiBaseUrls.tools}/${toolId}/files/${fileName}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getTools = async (params: AuthenticatedRequest) => {
  const { accountId, token } = params;
  const { data } = await axios.get<ToolType[]>(`${apiBaseUrls.tools}/account/${accountId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export const getToolById = async (params: AuthenticatedRequest & {
  toolId: string;
}) => {
  const { accountId, token, toolId } = params;
  const { data } = await axios.get<ToolType>(`${apiBaseUrls.tools}/account/${accountId}/${toolId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}