import { Box, Container, Grid } from "@mui/material";
import { t } from "i18next";
import { LandingServicesType } from "types/landingServices";
import LandingSectionTitle from "./LandingSectionTitle";
import LandingServicesItem from "./LandingServicesItem";

const services: Array<LandingServicesType> = [
    {
        image: "landing_tools.png",
        title: "Landing.Services.Tools.Title",
        subTitle: "Landing.Services.Tools.SubTitle",
        content: "Landing.Services.Tools.Content",
        position: "Left"
    },
    {
        image: "landing_fleets.png",
        title: "Landing.Services.Fleets.Title",
        subTitle: "Landing.Services.Fleets.SubTitle",
        content: "Landing.Services.Fleets.Content",
        position: "Right"
    },
    {
        image: "landing_work.png",
        title: "Landing.Services.Work.Title",
        subTitle: "Landing.Services.Work.SubTitle",
        content: "Landing.Services.Work.Content",
        position: "Left"
    },
    // {
    //     image: "landing_checking.png",
    //     title: "Landing.Services.CheckIn.Title",
    //     subTitle: "Landing.Services.CheckIn.SubTitle",
    //     content: "Landing.Services.CheckIn.Content",
    //     position: "Left"
    // },
    // {
    //     image: "landing_rental.png",
    //     title: "Landing.Services.Rental.Title",
    //     subTitle: "Landing.Services.Rental.SubTitle",
    //     content: "Landing.Services.Rental.Content",
    // },
  
    // {
    //     image: "landing_bot.png",
    //     title: "Landing.Services.Bot.Title",
    //     subTitle: "Landing.Services.Bot.SubTitle",
    //     content: "Landing.Services.Bot.Content",
    // }
]


const LandingServicesSection = () => {
    return <>
        <Box sx={{
            backgroundColor: "#9EC7CF",
        }}>
            <Container maxWidth="xl" sx={{
                paddingTop: "40px",
                paddingBottom: "20px",
                textAlign: "center"
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LandingSectionTitle>{t("Landing.Services.Title")}</LandingSectionTitle>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box>
            <Container maxWidth="xl" sx={{
                paddingBottom: "40px"
            }}>
                {
                    services.map(s => {
                        return <LandingServicesItem item={s} key={s.title}></LandingServicesItem>
                    })
                }
            </Container>
        </Box>
    </>
}


export default LandingServicesSection;