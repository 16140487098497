import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist";
import { PasswordType } from "../../types/password";
import "../../style.css";

interface PasswordModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (password: PasswordType) => void;
    screenType: any;
}

const PasswordModal: React.FC<PasswordModalProps> = ({ open, onClose, onSave, screenType }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);
    const { t } = useTranslation();
    const accountId = "";
    const userId = "";

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleSave = () => {
        const passwordToUpdate: PasswordType = {
            password,
            accountId,
            userId
        };
        if (isValidPassword) {
            onSave(passwordToUpdate);
            handleReset();
            onClose();
        }
    };
    const handleReset = () => {
        setPassword('');
        setConfirmPassword('');
        setIsValidPassword(false); 
    };
    const handleClose = (event: React.MouseEvent, reason: string) => {
        if (reason !== "backdropClick") {
            handleReset();
            onClose();            
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{t("EditUser.SetPassword")}</DialogTitle>
                <DialogContent style={{ overflow: 'hidden', paddingTop: '5px' }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                label={t("EditUser.Password")}
                                type="password"
                                variant="outlined"
                                value={password}
                                onChange={handlePasswordChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: !!password,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={t("EditUser.ConfirmPassword")}
                                type="password"
                                variant="outlined"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: !!confirmPassword,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={8}
                                value={password}
                                valueAgain={confirmPassword}
                                onChange={(isValid) => setIsValidPassword(isValid)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {screenType === 'edit' && (
                        <Grid item xs={2}>
                            <Button
                                style={{ backgroundColor: "#EAFC00", color: "#053d57", fontWeight: "bold" }}
                                variant="contained"
                                fullWidth
                                onClick={onClose}
                            >
                                {t("Buttons.Return")}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={handleSave}
                            disabled={!isValidPassword}
                        >
                            {t("Buttons.Save")}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PasswordModal;
