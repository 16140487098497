import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography } from '@mui/material';
import Splash from '../../components/Splash';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface LoginFormValues {
  identifier: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  identifier: Yup.string()
    .required('Required')
    .test('is-email-or-username', 'Invalid email or username', value => {
      // Check if the value contains an "@" symbol for email validation
      if (value.includes('@')) {
        // Validate as email
        return Yup.string().email().isValidSync(value);
      }
      // Validate as username
      return Yup.string().min(3, 'Too short!').max(50, 'Too long!').isValidSync(value);
    }),
  password: Yup.string().min(6, 'Too short!').required('Required'),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { isLoading, authError, loginAction, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated()){
      navigate('/dashboard')
    }
  }, [isAuthenticated, navigate])

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>,
  ) => {
    setSubmitting(false);
    const response = await loginAction({ email: values.identifier, password: values.password, accountCode: null, userId: null });
    if (response) {
      navigate('/dashboard');
    }
  };



  return (
    <>
      {isLoading ? <Splash></Splash> : <></>}
      <Formik
        initialValues={{ identifier: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="100vh"
            >
              <Typography variant="h4" gutterBottom>
                Login
              </Typography>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="identifier"
                  type="text"
                  label="Email or Username"
                  variant="outlined"
                  fullWidth
                  error={touched.identifier && !!errors.identifier}
                  helperText={touched.identifier && errors.identifier}
                />
              </Box>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
              </Box>
              {authError && (
                <Typography color="error" gutterBottom>
                  ERROR
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Login
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
