import {
  Alert,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface TableColumn<T> {
  align?: 'right' | 'left' | 'center';
  key: string,
  label?: string,
  value: (row: T) => string | number | undefined;
}

interface Props<T> {
  columns: TableColumn<T>[];
  data: T[];
  i18nKey: string;
  title?: string;
  isTitleDisplayed?: boolean;
}

const cfg = {
  getColumnAlign: function <T>(columnIndex: number, column: TableColumn<T>): 'right' | 'left' | 'center' | undefined {
    return column.align ?? (columnIndex !== 0 ? 'center' : undefined);
  },
};

const Table = <T,>(props: Props<T>): React.ReactElement => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    i18nKey,
    isTitleDisplayed = true,
    title = t(`${i18nKey}.Title`),
  } = props;

  if (data.length === 0) {
    return (
      <>
        {isTitleDisplayed && <Typography variant="h6" sx={{ mb: 1 }}>{title}</Typography>}
        <Alert severity="warning" sx={{ mt: isTitleDisplayed ? 0 : 2 }}>{t(`${i18nKey}.NoData`)}</Alert>
      </>
    );
  }

  return (
    <>
      {isTitleDisplayed && <Typography variant="h6" sx={{ mb: 1 }}>{title}</Typography>}
      <TableContainer component={Paper} sx={{ m2: isTitleDisplayed ? 0 : 2 }}>
        <MuiTable sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  align={cfg.getColumnAlign(i, column)}
                  key={column.key}>
                  {column.label || t(`${i18nKey}.Th.${column.key}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((element, index) => (
              <TableRow key={index}>
                {props.columns.map((column, i) => (
                  <TableCell
                    align={cfg.getColumnAlign(i, column)}
                    key={column.key}>
                    {column.value(element) || '-'}
                  </TableCell>
                ))}
              </TableRow>
            )
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
};

export default Table;