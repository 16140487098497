import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';


const MainButton = styled(Button)<{ variantType: 'primary' | 'secondary' | 'accent_1', margin?: string, textTransform?: any, width?: string| number}>(
  ({ theme, variantType, margin, textTransform, width }) => ({
    backgroundColor: theme.palette[variantType].main || theme.palette[variantType].dark,
    color: variantType === 'primary' ? theme.palette.common.white : theme.palette.primary.main, 
    padding: theme.spacing(1, 3),
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: textTransform,
    borderRadius: '50px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    margin: margin,
    width: width ?? 'auto',
    '&:hover': {
      backgroundColor: variantType === theme.palette[variantType].main || theme.palette[variantType].dark,
      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  })
);
export default MainButton;