import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createIncident } from 'api/incidents';
import { PostIncidentBody } from 'api/types';

export const useCreateIncident = ({
  onSuccess,
  onError,
} : {
  onSuccess?: (data: PostIncidentBody) => void,
  onError?: (error: Error) => void,
}): UseMutationResult<PostIncidentBody, Error, { body: PostIncidentBody; token: string }, unknown> => {
  return useMutation<PostIncidentBody, Error, { body: PostIncidentBody; token: string }>({
    mutationFn: createIncident,
    onSuccess,
    onError,
  });
};