import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { getUploadSasUri } from 'api/file';
import { addFile } from 'api/tools';
import { ToolHistoryType } from 'types/tool';
import { useAuth } from 'context/AuthContext';

interface FileUploadParams {
  file: File;
  fileName: string;
};

interface UploadResponse {
  blobName: string;
  newHistoryItem: ToolHistoryType;
};

interface UseFileUploadOptions {
  token?: string;
  accountId?: string;
  toolId: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

const useFileUpload = (params: UseFileUploadOptions) => {
  const {
    token: defaultToken,
    user: { accountId: defaultAccountId },
  } = useAuth();
  const {
    token = defaultToken,
    accountId = defaultAccountId,
    toolId,
    onSuccess,
    onError
  } = params;

  const fileAdded = async ({ file, fileName }: FileUploadParams) => {
    const type = file.type;

    // Step 1: Get the SAS URI
    const res = await getUploadSasUri({ token, fileName });

    // Step 2: Upload file to the SAS URI
    await axios.put(res.sasUri, file, {
      headers: {
        'Content-Type': type, // Ensure the correct content type
        'x-ms-blob-type': 'BlockBlob',
      },
    });

    // Step 3: Register the uploaded file in your backend
    const response = await addFile({
      accountId,
      token,
      fileName,
      toolId: toolId || '',
    });

    return response;
  };

  // Using react-query's useMutation to handle the mutation logic
  const { mutateAsync: uploadFile, status, error } = useMutation<UploadResponse, Error, FileUploadParams>({
    mutationFn: fileAdded,
    onError,
    onSuccess,
  });

  return { uploadFile, status, error };
};

export default useFileUpload;