import { Box, Container, Grid } from "@mui/material";
import { t } from "i18next";
import LandingSectionContent from "./LandingSectionContent";

const LandingFooter = () => {
    return <Box sx={{
        backgroundColor: "#E6F2F4",
    }}>
        <Container maxWidth="xl" sx={{
            paddingTop: "60px",
            paddingBottom: "60px",
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <img height="46px" src="/logo_transparent.png" alt="logo"></img>
                </Grid>
                <Grid item xs={12}>
                    <LandingSectionContent htmlContent={t("Landing.Footer.Rights")}/>
                </Grid>
            </Grid>
        </Container>
    </Box>
}


export default LandingFooter;