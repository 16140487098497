import * as React from 'react';
import {
  Alert,
  Button as MuiButton,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import Button from 'components/Button';
import {
  useIncident,
  useIncidentTypes,
  useTools,
  useUpdateIncident,
} from "api/hooks";
import Splash from 'components/Splash';
import type { Incident } from 'api/types';
import { CutomerInput } from 'components/customer-pages/atoms/input';
import IncidentObservations from 'components/Incident/Observations';
import SasImage from 'components/SasImage';

const Layout = ({ children }: { children: React.ReactElement }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ display: 'flex', marginTop: 8 }}>
      <h3 style={{ width: "100%" }}>{t('IncidentPage.Title')}</h3>
      {children}
    </Grid>
  );
};

const IncidentPage = () => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const { incidentId } = useParams<{ incidentId: string }>();
  const { isLoading: isLoadingIncident, data: incident, refetch: refetchIncident } = useIncident(incidentId);
  const { isLoading: isLoadingTools, data: tools } = useTools();
  const { mutate: doPut, status, error } = useUpdateIncident({ onSuccess: () => {
    setSuccess(true);
    refetchIncident();
  }});
  const incidentTypes = useIncidentTypes().data || [];
  const isLoading = isLoadingIncident || isLoadingTools;
  const { t } = useTranslation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success, navigate]);
  const validationSchema = React.useMemo(() =>
    Yup.object({
      incidentType: Yup.string().required(t('IncidentPage.Validation.Type.Required')),
    }), [t]);
  
  if (isLoading || !incident || !tools) {
    return (
      <Layout>
        <Splash />
      </Layout>
      );
    }

    const tool = tools.find(tool => tool.id === incident.toolId);

    return (
      <Layout>
        <Formik<Incident>
          initialValues={incident}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            doPut({ body: values });
          }}
          >
          {({ values, handleChange, handleBlur, handleSubmit, touched, errors, isValid }) => (
            <>
              <Grid item xs={12}>
                {success && <Alert severity="success">{t('IncidentPage.AddObservation.Alert.Success')}</Alert>}
                {error && <Alert severity="error">{t(`IncidentPage.AddObservation.Alert.${error.code}`)}</Alert>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('IncidentPage.ToolName')}
                  value={tool?.name || incident.toolId}
                  disabled
                />
              </Grid>
              {error && <Alert severity="error">{t(`IncidentPage.AddObservation.Alert.${error.code}`)}</Alert>}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-type">
                    {t('IncidentPage.Input.Type')}
                  </InputLabel> 
                  <Select
                    fullWidth
                    labelId="select-type"
                    variant="outlined"
                    id="select-type"
                    name="incidentType"
                    value={values.incidentType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.incidentType && Boolean(errors.incidentType)}
                    label={t('IncidentPage.Input.Type')}
                  >
                    <MenuItem value="" disabled>
                      <em />
                    </MenuItem>
                    {incidentTypes.map(({ id, label }) => (
                      <MenuItem key={id} value={id}>{label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CutomerInput
                  fullWidth
                  variant="outlined"
                  id="description"
                  name="description"
                  label={t('IncidentPage.Input.Description')}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.description && errors.description ? errors.description : ''}
                  error={touched.description && Boolean(errors.description)}
                  rows={4}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <IncidentObservations
                  incident={incident}
                  onObservationAdd={refetchIncident}
                />
              </Grid>
              {incident.picture && (
                <SasImage
                  fileName={incident.picture[0]}
                  alt={t('IncidentPage.Image.Title')}
                  style={{ borderRadius: '8px' }}
                  />
                )}
              <Grid item xs={12}>
                <MuiButton
                  variant="text"
                  onClick={() => navigate(-1)}
                >
                  {t('IncidentPage.Button.Back')}
                </MuiButton>
                <Button
                  style={{ marginLeft: 8 }}
                  variantType="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={!isValid || status === 'pending'}
                >
                  {t('IncidentPage.Button.Submit')}
                </Button>
              </Grid>
            </>
          )}
        </Formik>
      </Layout>
    );

}

export default IncidentPage;