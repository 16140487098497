import { Box, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const LandingClientSection = () => {
    const { t } = useTranslation();
    return <Box sx = {{
        marginTop: "100px",
        paddingTop: "80px",
        paddingBottom: "80px",
        backgroundColor: "#E6F2F4",
    }}>
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3 style={{ fontSize: "20px", color: "#666", fontWeight: "700", textAlign: "center" }}>{t("Landing.Client.Title")}</h3>
                </Grid>
                <Grid item xs={12} sx={{
                    textAlign:"center",
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap"
                }}>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/macman.png" alt="macmanlogo"></img>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/onrent.png" alt="onrentlogo"></img>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/todocamion.png" alt="todocamionlogo"></img>
                    {/* <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/todocamion.png" alt="todocamionlogo"></img>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/todocamion.png" alt="todocamionlogo"></img>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/todocamion.png" alt="todocamionlogo"></img>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/todocamion.png" alt="todocamionlogo"></img>
                    <img style={{height:"55px", marginTop: "20px"}} src="/assets/img/landing/todocamion.png" alt="todocamionlogo"></img> */}
                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default LandingClientSection;