// src/features/Vehicle/VehicleSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { VehicleType } from '../types/vehicle';
import { UserType } from '../types/user';
const BASE_URL = process.env.REACT_APP_VEHICLES_API_URL || '';

// Async thunks for async operations
export const fetchVehicles = createAsyncThunk(
  'vehicles/fetchVehicles',
  async ({ token, user }: { token: string, user: UserType }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/account/${user.accountId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createVehicle = createAsyncThunk(
  'vehicles/createVehicle',
  async ({ vehicle,  token, user }: { vehicle: VehicleType,  token: string, user: UserType }, { rejectWithValue }) => {
    try {
      vehicle.id = null;
      vehicle.accountId = user.accountId;
      vehicle.currentKilometers = Number(vehicle.currentKilometers);
      const response = await axios.post(BASE_URL, vehicle,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVehicle = createAsyncThunk(
  'vehicles/updateVehicle',
  async ({ vehicle, token }: { vehicle: VehicleType, token: string, }, { rejectWithValue, }) => {
    try {
      const response = await axios.put(`${BASE_URL}`, vehicle,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVehicle = createAsyncThunk(
  'vehicles/deleteVehicle',
  async ({ id, token, user }: { id: string, token: string, user: UserType }, { rejectWithValue }) => {
    try {      
      await axios.delete(`${BASE_URL}/${id}/account/${user.accountId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const vehicleState = (state: RootState) => state.vehicles;

export const selectVehicles = createSelector(
  vehicleState,
  (vehicleState) => vehicleState.vehicles
);

export const selectVehiclesStatus = createSelector(
  vehicleState,
  (vehicleState) => vehicleState.status
);

export const selectVehiclesError = createSelector(
  vehicleState,
  (vehicleState) => vehicleState.error
);
export const selectVehicleById = createSelector(
  [vehicleState, (state, id) => id],
  (vehicleState, id) => {
    return vehicleState.vehicles.find((vehicle: VehicleType) => vehicle.id === id) ?? vehicleInitialState
  }
);

export const vehicleInitialState: VehicleType = {
  id: undefined,
  accountId: "",
  brand: "",
  model: "",
  numberPlate: "",
  currentKilometers: 0,
};


const vehiclesSlice = createSlice({
  name: 'Vehicles',
  initialState: {
    vehicles: [] as Array<VehicleType>,
    status: 'idle',
    error: "",
  },
  reducers: {
    // Your synchronous reducers here (if needed)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vehicles = action.payload;
      })
      .addCase(fetchVehicles.rejected, (state) => {
        state.error = "error";
        state.status = 'failed';
      })
      .addCase(createVehicle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createVehicle.fulfilled, (state, action) => {
        state.vehicles.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createVehicle.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateVehicle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedItem = action.payload;
        const index = state.vehicles.findIndex(vehicles => vehicles.id === updatedItem.id);
        if (index !== -1) {
          state.vehicles[index] = updatedItem;
        }
      })
      .addCase(updateVehicle.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteVehicle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.vehicles.findIndex(vehicles => vehicles.id === action.payload);
        state.vehicles.splice(index, 1);
      })
      .addCase(deleteVehicle.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export default vehiclesSlice.reducer;