import { Box } from '@mui/material';
import ToolsInUseWidget from './ToolsInUseWidget';
import React from 'react';
import Splash from 'components/Splash';
import { useAuth } from 'context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectToolsStatus, fetchTools } from 'slices/toolsSlice';
import { AppDispatch } from 'store';
import { fetchUsers, selectUsersStatus } from 'slices/userSlice';

const Dasboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const toolsStatus = useSelector(selectToolsStatus);
  const userStatus = useSelector(selectUsersStatus);
  const { token, user } = useAuth();

  React.useEffect(() => {
    dispatch(fetchTools({ token, user }));
    dispatch(fetchUsers({ token, user }));
  }, [dispatch, token, user])

  if (toolsStatus === 'loading' || userStatus === "loading") {
    return <Splash />;
  }

  return (

    <Box marginTop={"100px"}>
      <ToolsInUseWidget />
    </Box>
  );
}

export default Dasboard;