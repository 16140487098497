import axios from 'axios';

import { apiBaseUrls } from 'config';
import {
  AuthenticatedRequest,
  UserAPIResponse,
} from 'api/types';

export const getUsers = async (params: AuthenticatedRequest): Promise<UserAPIResponse[]> => {
  const { accountId, token } = params;
  const { data } = await axios.get<UserAPIResponse[]>(`${apiBaseUrls.user}/account/${accountId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};