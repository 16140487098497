import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import Splash from "../components/Splash";
import { Container, Divider, Grid, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { fetchVehicles, selectVehicles, selectVehiclesStatus } from "../slices/vehiclesSlice";
import { VehicleType } from "../types/vehicle";
import VehicleItem from "../components/VehicleItem";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";
import SectionTitle from "components/SectionTitle";

const VehiclesPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const status = useSelector(selectVehiclesStatus);
    const vehicles: VehicleType[] = useSelector(selectVehicles);
    const {token, user} = useAuth();

    useEffect(() => {
        dispatch(fetchVehicles({token, user}));
    }, [dispatch, token, user])

    const addBooking = () => {
        navigate('/dashboard/vehicle');
    }

    return <>
        {status === "loading" ? <Splash></Splash> : <></>}
        <SectionTitle>{t("Vehicles.Title")}</SectionTitle>
        <h4>{t("Vehicles.SubTitle")}</h4>
        <Divider />
        <Container component="main" maxWidth="lg" style={{ paddingTop: "20px" }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={addBooking}
                    sx={{
                        width: 56,
                        height: 56,
                        bgcolor: 'primary.main',
                        color: 'white',
                        '&:hover': { bgcolor: 'primary.dark' } // Optional: Change color on hover
                    }}
                    style={{ width: 40, height: 40 }}
                    aria-label="add"
                >
                    <AddIcon />
                </IconButton>
            </div>
            <Grid container justifyContent="space-between" spacing={2} style={{ marginTop: "20px" }}>
                {
                    vehicles.map((v: VehicleType) => {
                        return <VehicleItem vehicle={v} key={v.id}/>
                    })
                }
            </Grid>

        </Container>
    </>
}

export default VehiclesPage;